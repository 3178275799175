import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./MuMiseEnLigne.scss";
import {
  Box,
  Tab
} from "@mui/material";
import {
  TabContext,
  TabList,
  TabPanel
} from '@mui/lab';
import MuHeader from "../../../components/mu-header/MuHeader"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";

import "moment/locale/fr";
import MuMiseEnLigneList from "./Mu-MiseEnLigne-List/MuMiseEnLigneList";
import MuMiseEnLigneAddNew from "./Mu-MiseEnLigne-AddNew/MuMiseEnLigneAddNew";

export default function MuMiseEnLigne() {
  const { cityCode } = useParams();
  const currentCity = JSON.parse(localStorage.getItem("currentMunicipality"));

  const navigate = useNavigate();

  // Tableau des rôles autorisés pour la gestion des utilisateurs
  // ['ROLE_ADMIN', 'ROLE_CONSULT', 'ROLE_GESTION_UTILISATEURS', 'ROLE_MISE_EN_LIGNE', 'ROLE_GESTION_UTILISATEURS_ET_MISE_EN_LIGNE'];
  // const allowedRolesForUserManagement = ['ROLE_ADMIN', 'ROLE_MISE_EN_LIGNE', 'ROLE_GESTION_UTILISATEURS_ET_MISE_EN_LIGNE'];
  const allowedRolesForUserManagement = ['ROLE_ADMIN', 'ROLE_MISE_EN_LIGNE', 'ROLE_GESTION_UTILISATEURS_ET_MISE_EN_LIGNE'];

  const { roleName } = JSON.parse(localStorage.getItem('currentUserInfo'));
  const allowedManagement = allowedRolesForUserManagement.includes(roleName)
  const { page } = useParams();
  const [value, setValue] = useState(allowedManagement ? page : 'mu-mel-list');

  useEffect(() => {
    if (!allowedManagement) {
      navigate(`/dashboard/municipalites/${cityCode}/mu-mise-en-ligne/mu-mel-list`);
      setValue('mu-mel-list')
    } else {
      setValue(page)
    }
  }, [allowedManagement, cityCode, navigate, page])

  const handleChange = (event, newValue) => {
    navigate(`/dashboard/municipalites/${cityCode}/mu-mise-en-ligne/${newValue}`)
    setValue(newValue);
  };

  return (
    currentCity && (
      <div className="mu-miseEnLigne">

        <MuHeader currentCity={currentCity} />

        <div className="topPage">
          <div className="topTitle">
            <CloudUploadRoundedIcon />
            <div>Mises en Ligne {currentCity.description} </div>
            <span>
              <em>{currentCity.cityName}</em>
            </span>
          </div>
          <div className="topNav">
            <Link
              className="topNav-close"
              to={`/dashboard/municipalites/${cityCode}/mu-statistiques`}
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              <CloseRoundedIcon />
            </Link>
          </div>
        </div>

        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} >
                <Tab label="Mises en ligne" value='mu-mel-list' />

                {allowedManagement ? (
                  <Tab label="Nouveau dépôt de données" value='add-new-data' />
                ) : null}

              </TabList>
            </Box>

            <TabPanel value="mu-mel-list">
              <MuMiseEnLigneList allowedManagement={allowedManagement} />
            </TabPanel>

            {allowedManagement ? (
              <TabPanel value="add-new-data">
                <MuMiseEnLigneAddNew allowedManagement={allowedManagement} />
              </TabPanel>
            ) : null}

          </TabContext>
        </Box>

      </div>
    )
  );
}
