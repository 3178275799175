import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import './EditUserForm.scss'
import {
  getUserToEdit
} from '../httpQuerys';
import {
  validatePassword,
  validateField,
  validateEmail,
} from '../FunctionsUtils';
import DialogConfirm from './DialogConfirm';
import Paper from '@mui/material/Paper';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import UserInfoForm from './User-Info-Form/UserInfoForm';
import UserProfilesServicesForm from './User-Profiles-Services-Form/UserProfilesServicesForm';

export default function EditUserForm(params) {

  const { idUser, idGroup, currentCity } = params;

  const { cityCode } = currentCity
  const editUserFormRef = useRef(null);

  const [userToEdit, setUserToEdit] = useState();
  const [lastUserValues, setLastUserValues] = useState();
  const [fullNameError, setFullNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [emailError, setEmailError] = useState('');
  const [telephoneError, setTelephoneError] = useState('');

  const [profileError, setProfileError] = useState('');
  const [profileServicesError, setProfileServicesError] = useState('');
  const [defaultServiceError, setDefaultServiceError] = useState('');

  const [isFormValidated, setIsFormValidated] = useState(false);
  const [open, setOpen] = useState(false);

  // Configuration de la locale pour Day.js
  dayjs.locale("fr-ca");

  //  valeurs a récupérer de la BD concernant un utilisateur à éditer
  useEffect(() => {
    getUserToEdit(idUser, idGroup, cityCode).then(
      data => {
        setUserToEdit(data);
        setLastUserValues(data);
      });
  }, [cityCode, idGroup, idUser]);

  //! -------------------------------------------------------------------------------------

  // console.log({ userToEdit })
  // console.log({ lastUserValues })

  // console.log(userToEdit?.servicesProfile)
  // console.log(lastUserValues?.servicesProfile)

  // if (!_.isEqual(userToEdit, lastUserValues)) {
  //   console.log('Objects are different');
  // } else {
  //   console.log('Objects are the same');
  // }


  const attributDefaultService = (userToEdit, setUserToEdit) => {
    const updatedServicesProfile = userToEdit.servicesProfile.map((item) => ({
      ...item,
      defaultService: item.aliasName === userToEdit.defaultServiceProfile.aliasName ? true : false
    }));
    const updatedUser = {
      ...userToEdit,
      servicesProfile: updatedServicesProfile
    };

    setUserToEdit(updatedUser);
    // setLastUserValues(updatedUser);
    return updatedUser;
  };

  //!----------------------------------------------------------------
  //!----------------------------------------------------------------
  const handleReset = () => {
    console.log('reset user information')
    setUserToEdit(lastUserValues);
    setFullNameError('')
    setPasswordError('')
    setPasswordStrength('')
    setEmailError('')
    setTelephoneError('')
  }
  //!----------------------------------------------------------------
  //!----------------------------------------------------------------

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log({ userToEdit })

    validateField(userToEdit.fullName, 'Prénom et Nom', setFullNameError);
    validateEmail(userToEdit.email, setEmailError)
    validatePassword(userToEdit.password, setPasswordError, setPasswordStrength)

    if (userToEdit.profile === null) setProfileError('Un profil est obligatoire.')
    if (userToEdit.servicesProfile.length === 0) setProfileServicesError('Au minimum un service est obligatoire.')
    if (userToEdit.defaultServiceProfile === null) setDefaultServiceError('Le champ service par défaut est obligatoire.')
    // if (Object.keys(userToEdit.defaultServiceProfile).length === 0) setDefaultServiceError('Le champ service par défaut est obligatoire.')

    // const updatedUser = userToEdit.defaultServiceProfile !== null ? attributDefaultService(userToEdit, setUserToEdit) : userToEdit;
    const updatedUser = {
      ...userToEdit,
      defaultServiceProfile: userToEdit.defaultServiceProfile !== null ? attributDefaultService(userToEdit, setUserToEdit) : userToEdit.defaultServiceProfile,
      // Si la date d'expiration n'est pas valide, assigner null
      expiration: dayjs(userToEdit.expiration).isValid() ? userToEdit.expiration : null
    }

    //! ----------------------------------------
    //  il faut vérifier la validité à ce niveau
    //! ----------------------------------------
    let validateForm =
      (updatedUser.fullName !== '' && !fullNameError)
      && (updatedUser.password !== '')
      && (updatedUser.email !== '' && !emailError)
      // && (updatedUser.telephone !== '' && !telephoneError)
      && (updatedUser.profile !== null)
      && (updatedUser.servicesProfile.length > 0)
      && (updatedUser.defaultServiceProfile !== null)

    if (validateForm) {
      console.log('Soumission du Formulaire');
      setIsFormValidated(true);
    }
    else {
      console.log('Un des champs est manquant ou mal saisi');
      setIsFormValidated(false);
    }
    // permet d'afficher la boite de dialogue à la soumission du formulaire
    setOpen(true);
  }


  return (
    userToEdit &&
    <div className='muEditUser'>

      {open && (
        <DialogConfirm
          userToEdit={userToEdit}
          lastUserValues={lastUserValues}
          open={open}
          setOpen={setOpen}
          isFormValidated={isFormValidated}
        />
      )}

      <div className="topPage">
        <div className="topTitle">
          <ManageAccountsRoundedIcon />
          <div>Édition de l'utilisateur </div>
          <span><em>{idUser}</em></span>
        </div>
        <div className="topNav">
          <Link className='topNav-close' to={`/dashboard/municipalites/${cityCode}/mu-utilisateurs`} style={{ textDecoration: "none", cursor: 'pointer' }}>
            <CloseRoundedIcon />
          </Link>
          <Button className='btnSubmit' type="submit" variant="contained" sx={{ width: '200px' }} onClick={handleSubmit}>
            Enregistrer
          </Button>
        </div>
      </div>

      <Paper elevation={5} className='muEditUser-paper' sx={{ height: 600, overflowY: 'auto' }}>
        <form ref={editUserFormRef} className='muEditUserForm' onSubmit={handleSubmit}>
          <UserInfoForm
            userToEdit={userToEdit}
            setUserToEdit={setUserToEdit}
            fullNameError={fullNameError}
            setFullNameError={setFullNameError}
            emailError={emailError}
            setEmailError={setEmailError}
            telephoneError={telephoneError}
            setTelephoneError={setTelephoneError}
            passwordError={passwordError}
            setPasswordError={setPasswordError}
            passwordStrength={passwordStrength}
            setPasswordStrength={setPasswordStrength}
          />
          <hr />
          <UserProfilesServicesForm
            userToEdit={userToEdit}
            setUserToEdit={setUserToEdit}
            profileError={profileError}
            setProfileError={setProfileError}
            profileServicesError={profileServicesError}
            setProfileServicesError={setProfileServicesError}
            defaultServiceError={defaultServiceError}
            setDefaultServiceError={setDefaultServiceError}
          />
          <hr />
          <div className='footerEditUser'>

            <Link to={`/dashboard/municipalites/${cityCode}/mu-utilisateurs`} style={{ textDecoration: "none" }}>
              <Button className='btnSubmit' variant="contained" sx={{ width: '200px', cursor: 'pointer' }}>
                Annuler
              </Button>
            </Link>

            <Button
              className='btnSubmit'
              variant="contained"
              sx={{ width: '200px', cursor: 'pointer' }}
              disabled={userToEdit === lastUserValues}
              onClick={handleReset}
            >
              Réinitialiser
            </Button>

            <Button className='btnSubmit' type="submit" variant="contained" sx={{ width: '200px' }} onClick={handleSubmit}>
              Enregistrer
            </Button>

          </div>
        </form>
      </Paper>
    </div>
  )
}

// telephoneError={telephoneError}
// setTelephoneError={setTelephoneError}
// passwordError={passwordError}
// setPasswordError={setPasswordError}
// passwordStrength={passwordStrength}
// setPasswordStrength={setPasswordStrength}

// setProfileError={setProfileError}
// profileServicesError={profileServicesError}
// setProfileServicesError={setProfileServicesError}
// defaultServiceError={defaultServiceError}
// setDefaultServiceError={setDefaultServiceError}