import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import {
  deleteSelectedOneMiseEnLigneOfAll,
  cancelSelectedOneMiseEnLigneOfAll,
  deleteMELFolderById,
} from "../httpQuerys";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from '@mui/material';


export default function DialogConfirm({ open, setOpen, action, rows, selectedRows }) {
  const navigate = useNavigate();
  const { cityCode } = JSON.parse(localStorage.getItem('currentMunicipality'));

  const [openDialog, setOpenDialog] = useState(open);
  const [secondDialogOpen, setSecondDialogOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [loading, setLoading] = useState(false); // État pour le chargement

  const actionsConfig = {
    Cancel: {
      title: "Confirmation de l'annulation",
      successMessage: "Annulation effectuée avec succès.",
      errorMessage: "Erreur lors de l'annulation.",
      actionText: "annuler",
    },
    Delete: {
      title: "Confirmation de la suppression",
      successMessage: "Suppression effectuée avec succès.",
      errorMessage: "Erreur lors de la suppression.",
      actionText: "supprimer",
    },
  };
  const currentAction = actionsConfig[action];

  const handleClose = () => {
    setOpen(false);
    setOpenDialog(false);
    setSecondDialogOpen(false);
    navigate(`/dashboard/municipalites/${cityCode}/mu-mise-en-ligne`);
  };

  const handleCancel = () => {
    setOpen(false);
    setOpenDialog(false);
    setSecondDialogOpen(false);
  };

  const handleConfirm = async () => {
    const filteredRows = rows.filter(row => selectedRows.includes(row.idTracking));
    const validate = validateStatuses(action, filteredRows);

    if (!validate) {
      setConfirmationMessage("Action non autorisée : certains statuts ne sont pas valides.");
      setSecondDialogOpen(true);
      return;
    }

    setLoading(true);

    try {
      let actionPromise;

      if (action === 'Delete') {
        console.log("Début de la suppression...");

        // Suppression des données danx la base de données et des dossiers associés
        await Promise.all(
          selectedRows.map(async (row) => {
            try {
              // Suppression dans la BD et du dossier
              // const [dbResponse, folderResponse] = await Promise.all([
              const [, folderResponse] = await Promise.all([
                deleteSelectedOneMiseEnLigneOfAll(row),
                deleteMELFolderById(row)
              ]);

              if (folderResponse?.message === 'Dossier inexistant') {
                console.warn(`Dossier introuvable pour l'ID ${row}`);
              }
            }
            catch (error) {
              // En cas d'erreur spécifique à une ligne, loguer et continuer
              console.error(`Erreur pour la ligne avec idTracking ${row}:`, error);
            }
          })
        );

        console.log('Suppression réussie des données et des dossiers.');
        actionPromise = Promise.resolve(true);

      } else if (action === 'Cancel') {
        console.log("Début de l'annulation...");

        // Annulation des actions sur les lignes sélectionnées
        actionPromise = await Promise.all(selectedRows.map(row => cancelSelectedOneMiseEnLigneOfAll(row)));

        console.log('Annulation réussie.');
      }

      // Vérification du succès de l'opération
      const isSuccess = actionPromise;

      setConfirmationMessage(isSuccess ? currentAction.successMessage : currentAction.errorMessage);
      setSecondDialogOpen(true);

    } catch (error) {
      console.error("Erreur lors du traitement de l'action :", error);
      setConfirmationMessage(currentAction.errorMessage);
    } finally {
      setLoading(false);
      setSecondDialogOpen(true);
    }
  };

  //! =================================================================

  // Règles de validation des statuts en fonction de l'action
  const validateStatuses = (action, filteredRows) => {
    const rules = {
      Cancel: [1, 2, 6, 7, 8], // Statuts valides pour l'action "Cancel"
      Delete: [1, 2, 3, 7, 8, 9], // Statuts valides pour l'action "Delete"
    };

    // Récupérer la liste des statuts valides pour l'action spécifiée
    const validStatuses = rules[action];

    // Vérifie si TOUS les éléments de filteredRows ont un idStatus inclus dans la liste validStatuses
    // La méthode every est utilisée pour vérifier que toutes les lignes de filteredRows possèdent un idStatus qui est inclus dans la liste validStatuses.
    // Si toutes les lignes respectent cette condition, la fonction retourne true, sinon false
    return filteredRows.every(row => validStatuses.includes(row.idStatus));
  };

  const dialogContent = secondDialogOpen
    ? {
      title: currentAction.title,
      message: confirmationMessage,
      actions: <Button onClick={handleClose} color="error">Fermer</Button>,
    }
    : {
      title: `${currentAction.title} ${selectedRows.length > 1
        ? `des ${selectedRows.length} mises en ligne sélectionnées`
        : 'de la mise en ligne sélectionnée'
        }`,
      message: `Êtes-vous sûr de vouloir ${currentAction.actionText} ${selectedRows.length > 1
        ? `ces ${selectedRows.length} mises en ligne`
        : 'cette mise en ligne'
        } ? ${action === 'Delete' ? 'Cette action est irréversible.' : ''}`,
      actions: (
        <>
          <Button onClick={handleCancel} color="error" disabled={loading}>Annuler</Button>
          <Button onClick={handleConfirm} color="primary" disabled={loading}>Confirmer</Button>
        </>
      ),
    };

  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <DialogTitle>{dialogContent.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogContent.message}</DialogContentText>
        {/* Progression linéaire en option */}
        {loading &&
          <>
            <LinearProgress sx={{ mt: 2 }} />
            <Box sx={{ color: '#999', fontSize: '0.875rem' }}>
              Requête en cours, veuillez patienter...
            </Box>
          </>
        }
      </DialogContent>
      <DialogActions>{dialogContent.actions}</DialogActions>
    </Dialog>
  );
}

