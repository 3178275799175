import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import {
  updateMiseEnLigne,
  deleteExtraFilesMEL,
  sendUpdatedFilesMEL
} from '../httpQuerys';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  LinearProgress,
  Box,
} from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';

export default function DialogConfirm({ formDataMEL, formErrors, open, setOpen, isFormValidated }) {

  const navigate = useNavigate();
  const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));
  const toDay = dayjs().isValid() ? dayjs().locale('fr-ca').format('YYYY-MM-DD HH:mm:ss') : null;

  const [openDialog, setOpenDialog] = useState(open);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [messageConfirmationDialog, setMessageConfirmationDialog] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false)
    setOpenDialog(false)
    setOpenSecondDialog(false)
    navigate(`/dashboard/municipalites/${formDataMEL.cityCode}/mu-mise-en-ligne/mu-mel-list`);
  };

  const handleCancel = () => {
    setOpen(false)
    setOpenDialog(false)
    setOpenSecondDialog(false)
  };

  // Fonction pour la confirmation de l'édition d'une mise en ligne
  const handleConfirm = async () => {
    console.log('Edition d\'une mise enligne ');
    setLoading(true); // Démarre le chargement

    try {
      // Mettre à jour les données dans une nouvelle variable
      const updatedFormDataMEL = {
        ...formDataMEL, // Copier l'état actuel
        dateMaj: toDay  // Mettre à jour la date
      };

      // Étape 1 : Envoi des données mises à jour sans le stockage des fichiers
      const response = await updateMiseEnLigne(updatedFormDataMEL);

      if (response) {
        // Ancienne liste complète des fichiers en BD avant mise à jour
        // S'assurer que c'est un tableau
        const fichiersEnBD = Array.isArray(updatedFormDataMEL.dBfiles) ? updatedFormDataMEL.dBfiles : [];

        // Séparer les anciens fichiers (à garder) et les nouveaux fichiers (à ajouter)
        const fichiers = Array.isArray(updatedFormDataMEL.fichiers) ? updatedFormDataMEL.fichiers : [];
        const anciensFichiersAGarder = fichiers.filter(f => f.nomFichier); // Ceux qu'on garde
        const nouveauxFichiers = fichiers.filter(f => f instanceof File); // Nouveaux fichiers

        // Détecter les fichiers à supprimer (ceux en BD mais pas dans la liste à garder)
        const fichiersASupprimer = fichiersEnBD.filter(f =>
          !anciensFichiersAGarder.some(af => af.nomFichier === f.nomFichier)
        );

        // 🔹 Logs pour vérification
        // console.log("📌 Anciens fichiers à garder :", anciensFichiersAGarder);
        // console.log("❌ Anciens fichiers à supprimer :", fichiersASupprimer);
        // console.log("📌 Nouveaux fichiers ajoutés :", nouveauxFichiers);

        // Étape 2 : supression des fichiers en extra dans dossier correspondant
        if (fichiersASupprimer) {
          await deleteExtraFilesMEL({ idTracking: updatedFormDataMEL.idTracking, fichiersASupprimer });
        } else {
          console.log("Aucun fichier à supprimer, pas d'action nécessaire.");
        }

        // Étape 3 : Envoi des fichiers vers le dossier correspondant
        // Création de FormData
        const formData = new FormData();
        if (updatedFormDataMEL.idTracking) {
          formData.append('idTracking', updatedFormDataMEL.idTracking);// Associe l'ID tracking
        }

        // Ajouter les nouveaux fichiers
        nouveauxFichiers.forEach(file => formData.append('fichiers', file));

        // 🔹 Logs pour vérification
        // console.log('-----------------✅ Contenu du formData -----------------');
        // for (let [key, value] of formData.entries()) {
        //   console.log(key, value);
        // }

        if (formData.has('fichiers')) {
          const responseAddFiles = await sendUpdatedFilesMEL(formData);
          console.log('Réponse fichiers :', responseAddFiles?.response?.success);
        } else {
          console.log("ℹ️ Aucun fichier à ajouter, pas d'envoi nécessaire.");
        }

        setMessageConfirmationDialog('La requête de mise à jour a été réalisée avec succès.');
        setOpenSecondDialog(true); // Ouvrir la boîte de confirmation après succès
      } else {
        setMessageConfirmationDialog('Erreur lors de la mise à jour de la mise en ligne.');
        setOpenSecondDialog(true); // Ouvrir la boîte en cas d'erreur
      }
    }
    catch (error) {
      console.error('Erreur lors de la requête de mise à jour.', error);
      setMessageConfirmationDialog('Erreur lors de la requête de mise à jour.');
      setOpenSecondDialog(true);
    }
    finally {
      setLoading(false); // Arrête le chargement après la fin de la requête
    }
  }

  return (
    openSecondDialog ? (

      // <Dialog open={openSecondDialog} onClose={handleClose} onKeyDown={handleEscape}>
      <Dialog open={openSecondDialog} onClose={handleClose} >
        <DialogTitle>Confirmation de mise à jour d'une mise en ligne</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Une requête de mise à jour de la mise en ligne de type {formDataMEL.typeMaj} est lancée.<br /><br />
            Un temps de traitement de votre demande est requis, vous serez avisé de toute mise à jour en temps et en heure ! <br /><br />
            <strong>{messageConfirmationDialog}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">Fermer</Button>
        </DialogActions>
      </Dialog>

    ) : (
      isFormValidated ? (
        <div>
          {/* <Dialog open={openDialog} onClose={handleClose} onKeyDown={handleKeyDown}> */}
          <Dialog open={openDialog} onClose={handleClose} >
            <DialogTitle>Requête de mise à jour d'une mise en ligne</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Vous êtes sur le point de soumettre une requête de mise à jour de la mise en ligne de type <strong>{formDataMEL.typeMaj}</strong> pour la ville de <em>{currentCity.cityName}</em>.
                <br /><br />
                Veuillez confirmer cette requête !
              </DialogContentText>
              {messageConfirmationDialog}
              {/* Indicateur de chargement */}
              {/* {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <CircularProgress />
                </Box>
              )} */}

              {/* Progression linéaire en option */}
              {loading &&
                <>
                  <LinearProgress sx={{ mt: 2 }} />
                  <Box sx={{ color: '#999', fontSize: '0.875rem' }}>
                    Requête en cours, veuillez patienter...
                  </Box>
                </>
              }
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleCancel} variant="contained" color="error">Annuler</Button>
              <Button onClick={handleConfirm} variant="contained" color="primary">Confirmer</Button> */}
              <Button
                onClick={handleCancel}
                color="error"
                disabled={loading} // Désactive le bouton pendant le chargement
              >
                Annuler
              </Button>
              <Button
                onClick={handleConfirm}
                color="primary"
                disabled={loading} // Désactive le bouton pendant le chargement
              >
                Confirmer
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div>
          <Dialog open={open} onClose={handleCancel}>
            <DialogTitle color="error">Erreur dans le formulaire</DialogTitle>
            <DialogContent>

              <DialogContentText>
                Un ou plusieurs champs du formulaire sont incorrects ou manquants ! <br /><br />
                {
                  Object.entries(formErrors).length > 0
                    ? Object.entries(formErrors)
                      .filter(([_, value]) => value !== '')
                      .map(([key, value]) => (
                        <Typography key={key} color="error" component="li" sx={{ listStyleType: "' - '", marginLeft: '1em' }}>
                          {value}
                        </Typography>
                      ))
                    : <Typography color="error">Erreur inconnue dans le formulaire.</Typography>
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleCancel} variant="contained" color="error">Retour au Formulaire</Button> */}
              <Button onClick={handleCancel} >Retour au Formulaire</Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    )
  )
}
