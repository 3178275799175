import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import {
    cancelSelectedOneMiseEnLigneOfAll,
} from '../httpQuerys';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    Box,
    Typography,
} from '@mui/material';

// import dayjs from "dayjs";
import "dayjs/locale/fr"; // Importer la localisation française


export default function CancelAndAllowAddNewDialog({ isAddNewAllowed, openDialogToAllow, setOpenDialogToAllow, open, setOpen }) {

    // console.log(isAddNewAllowed.allowed ? "✅ " + isAddNewAllowed.message : "❌" + isAddNewAllowed.message);


    const dateISO = isAddNewAllowed.existingData.dateCreation;
    const date = new Date(dateISO);
    const options = { weekday: 'long', day: '2-digit', month: 'long', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('fr-FR', options);

    const navigate = useNavigate();
    const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));

    const [loading, setLoading] = useState(false); // État pour le chargement

    const handleClose = () => {
        setOpenDialogToAllow(false)
        navigate(`/dashboard/municipalites/${currentCity.cityCode}/mu-mise-en-ligne/add-new-data`);
    };

    const handleCancel = () => {
        setOpenDialogToAllow(false)
    };

    // Fonction pour la confirmation de l'ajout d'une nouvelle mise en ligne
    const handleConfirm = async () => {
        try {
            setLoading(true); // Activer le mode chargement
            setOpenDialogToAllow(false);
            await cancelSelectedOneMiseEnLigneOfAll(isAddNewAllowed.existingData.idTracking);
            setOpen(true);
        } catch (error) {
            console.error("Erreur lors de l'annulation de la mise en ligne :", error);
        } finally {
            setLoading(false); // Désactiver le mode chargement après l'opération
        }
    };


    if (!openDialogToAllow) return null; // Évite un rendu inutile si le dialogue est fermé
    return (
        openDialogToAllow && (

            <Dialog
                open={openDialogToAllow}
                onClose={handleClose}
            >
                <DialogTitle color="error">Une mise en ligne du même type est en cours de traitement !</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {/* <strong>{isAddNewAllowed.message}</strong><br /><br /> */}
                        Une mise en ligne du même type <strong>( {isAddNewAllowed.existingData.typeMEL} )</strong> a été soumise le <strong>{formattedDate}</strong> par <strong>{isAddNewAllowed.existingData.fullName}</strong> et est toujours en cours de traitement.
                        <br /><br />
                        <Typography color="error" component="span" >
                            Si vous confirmez l’enregistrement de cette nouvelle mise en ligne, la mise en ligne précédente sera annulée et remplacée par la nouvelle.
                        </Typography>
                    </DialogContentText>

                    {/* Progression linéaire en option */}
                    {loading &&
                        <>
                            <LinearProgress sx={{ mt: 2 }} />
                            <Box sx={{ color: '#999', fontSize: '0.875rem' }}>
                                Requête en cours, veuillez patienter...
                            </Box>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCancel}
                        color="error"
                        // variant="contained"
                        disabled={loading} // Désactive le bouton pendant le chargement
                    >
                        Annuler
                    </Button>
                    <Button
                        onClick={handleConfirm}
                        color="primary"
                        // variant="contained"
                        disabled={loading} // Désactive le bouton pendant le chargement
                    >
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        )
    )
}
