import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import "./MuUtilisateurs.scss";
import MuListeUtilisateurs from "./Mu-Users-List/MuListeUtilisateurs"
import AddUser from "./Mu-Add-User/AddUser";
import {
  Box,
  Tab
} from "@mui/material";
import {
  TabContext,
  TabList,
  TabPanel
} from '@mui/lab';
import MuHeader from "../../../components/mu-header/MuHeader";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import AddMuToUser from "./Add-Mu-To-User/AddMuToUser";


export default function MuUtilisateurs() {
  const { cityCode } = useParams();
  const currentCity = JSON.parse(localStorage.getItem("currentMunicipality"));

  const navigate = useNavigate();

  // Tableau des rôles autorisés pour la gestion des utilisateurs
  // ['ROLE_ADMIN', 'ROLE_CONSULT', 'ROLE_GESTION_UTILISATEURS', 'ROLE_MISE_EN_LIGNE', 'ROLE_GESTION_UTILISATEURS_ET_MISE_EN_LIGNE'];
   const allowedRolesForUserManagement = ['ROLE_ADMIN', 'ROLE_GESTION_UTILISATEURS', 'ROLE_GESTION_UTILISATEURS_ET_MISE_EN_LIGNE'];
  // const { roleName, idGroup } = JSON.parse(localStorage.getItem('currentUserInfo'));
  // const allowedManagement = allowedRolesForUserManagement.includes(roleName) || (idGroup === 'G00');
  const { roleName } = JSON.parse(localStorage.getItem('currentUserInfo'));
  const allowedManagement = allowedRolesForUserManagement.includes(roleName);

  const { page } = useParams();
  const [value, setValue] = useState(allowedManagement ? page : 'mu-users-list');

  useEffect(() => {
    if (!allowedManagement) {
      navigate(`/dashboard/municipalites/${cityCode}/mu-utilisateurs/mu-users-list`);
      setValue('mu-users-list')
    } else {
      setValue(page)
    }
  }, [allowedManagement, cityCode, navigate, page])

  const handleChange = (event, newValue) => {
    navigate(`/dashboard/municipalites/${cityCode}/mu-utilisateurs/${newValue}`)
    setValue(newValue);
  };

  return (

    currentCity && (
      <div className="mu-utilisateurs ">

        <MuHeader currentCity={currentCity} />

        <div className="topPage">
          <div className="topTitle">
            <ManageAccountsRoundedIcon />
            <div>
              Liste des utilisateurs {currentCity.description}{" "}
            </div>
            <span>
              <em>{currentCity.cityName}</em>
            </span>
          </div>
          <div className="topNav">
            <Link
              className="topNav-close"
              to={`/dashboard/municipalites/${cityCode}/mu-utilisateurs`}
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              <CloseRoundedIcon />
            </Link>
          </div>
        </div>

        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} >
                <Tab label="Utilisateurs de la municipalité" value='mu-users-list' />
                {allowedManagement ? (
                  <Tab label="Nouvel utilisateur" value='mu-add-new-user' />
                ) : null}
                {allowedManagement ? (
                  <Tab label="Importer un utilisateur" value='mu-import-user' />
                ) : null}

              </TabList>
            </Box>

            <TabPanel value="mu-users-list">
              <MuListeUtilisateurs allowedManagement={allowedManagement} />
            </TabPanel>

            <TabPanel value="mu-add-new-user">
              <AddUser />
            </TabPanel>

            <TabPanel value="mu-import-user">
              <AddMuToUser />
            </TabPanel>


          </TabContext>
        </Box>

      </div>
    )
  );
}
