import React, { useEffect, useState } from 'react';
import './UserInfoForm.scss';
import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';
import {
  getRoles,
  getTitles,
  getDepratements,
} from '../../httpQuerys';
import {
  validatePassword,
  validateField,
  validateEmail,
  formatPhoneNumber,
  generateRandomPassword,
  generateUserName,
  validateUserName,
} from '../../FunctionsUtils';
import {
  TextField,
  IconButton,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
  Autocomplete,
  Stack,
  FormControl,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import PhoneForwardedRoundedIcon from '@mui/icons-material/PhoneForwardedRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import WcRoundedIcon from '@mui/icons-material/WcRounded';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export default function UserInfoForm(props) {

  const currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
  const currentUserRole = currentUser.roleName;

  const {
    userToAdd,
    setUserToAdd,
    prenomError,
    setPrenomError,
    nomError,
    setNomError,
    userNameError,
    setUserNameError,
    passwordError,
    setPasswordError,
    emailError,
    setEmailError,
    telephoneError,
    setTelephoneError,
    passwordStrength,
    setPasswordStrength
  } = props;

  // console.log(userToAdd)

  const [showPassword, setShowPassword] = useState(false);
  const genres = [
    { id: 'M', label: "Masculin", value: "M" },
    { id: 'F', label: "Féminin", value: "F" },
    { id: 'N', label: "Non binaire", value: "N" },
    { id: 'A', label: "Ne pas répondre", value: "A" },
  ];
  const [roles, setRoles] = useState([]);
  const [titles, setTitles] = useState([]);
  const [departments, setDepartments] = useState([]);

  // ---------------------------------------------------------------
  // Configuration de la locale pour Day.js
  dayjs.locale("fr-ca");
  // ---------------------------------------------------------------

  //  valeurs a récupérer de la BD titres et departments attentions avec le e dans 'depart e ment'
  //  récuperer, une seule fois lors du chargement, toutes les données dont on a besoin de la BD
  useEffect(() => {
    // getRoles().then(data => setRoles(data));
    getRoles().then((data) => {
      setRoles(data);
      setUserToAdd((prevUser) => ({
        ...prevUser,
        roleName: prevUser.roleName !== '' ? prevUser.roleName : 'ROLE_BANNI',
        idRole: prevUser.idRole !== null ? prevUser.idRole : data.find((role) => role.roleName === 'ROLE_BANNI').idRole,
      }));
    });
    getTitles().then(data => setTitles(data));
    getDepratements().then(data => setDepartments(data));
  }, [setUserToAdd]);


  // Selon le rôle de l'utilisateur actuel (currentUserRole), on filtre la liste des droits qu'il peut attribuer.
  const filteredRoles =
    currentUserRole === 'ROLE_ADMIN'
      ? roles
      : roles.filter(role => [
        'ROLE_CONSULT',
        'ROLE_GESTION_UTILISATEURS',
        'ROLE_MISE_EN_LIGNE',
        'ROLE_GESTION_UTILISATEURS_ET_MISE_EN_LIGNE',
        'ROLE_BANNI'
      ].includes(role.roleName));

  // données des Options de la liste déroulante pour les rôles
  const rolesDefaultProps = {
    options: filteredRoles,
    getOptionLabel: (option) => option.roleLabel
  };

  // données des Options de la liste déroulante pour les titres
  const titlesDefaultProps = {
    options: titles,
    getOptionLabel: (option) => option.title
  };

  // données des Options de la liste déroulante pour les départements
  const departementsDefaultProps = {
    options: departments,
    getOptionLabel: (option) => option.department
  };

  //! ----------------------------------------------------------------
  const handleClickPassword = (e) => {
    setShowPassword(!showPassword)
  }

  const capitalizeFirstLetterOfWords = (str) => {
    //seulement la premiere lettre en majuscule
    return str.charAt(0).toUpperCase() + str.slice(1);
    //premiere lettre de chaque mot en majuscule
    // return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // Effacer le contenu d'champ en cliquant sur le x
  const handleClear = async (field) => {
    let updatedUser = {};
    switch (field) {
      case 'prenom':
        // Mettre à jour fullName et userName immédiatement
        updatedUser = {
          ...userToAdd,
          prenom: '',
          fullName: userToAdd.nom,  // Mettre à jour fullName
          userName: '' // Intialiser userName
        };
        // Mettre à jour userToAdd lorsque la résolution est terminée
        try {
          const data = await generateUserName('', userToAdd.nom); // generateUserName est asynchrone
          updatedUser.userName = data.userName; //mettre à jour userName
          setUserToAdd({
            ...updatedUser
          });
          setPrenomError('Le champ Prénom est obligatoire.')
        } catch (error) {
          console.error('Erreur lors de la génération du prénom de l\'utilisateur :', error);
        }
        break;
      case 'nom':
        // Mettre à jour fullName et userName immédiatement
        updatedUser = {
          ...userToAdd,
          nom: '',
          fullName: userToAdd.prenom,  // Mettre à jour fullName
          userName: '' // initialiser userName
        };
        // Mettre à jour userToAdd lorsque la résolution est terminée
        try {
          const data = await generateUserName(userToAdd.prenom, ''); // generateUserName est asynchrone
          updatedUser.userName = data.userName;//mettre à jour userName
          setUserToAdd({
            ...updatedUser,
          });
          setNomError('Le champ Nom est obligatoire.');
        } catch (error) {
          console.error('Erreur lors de la génération du nom de l\'utilisateur :', error);
        }
        break;
      case 'password':
        setUserToAdd({ ...userToAdd, password: '' })
        setPasswordStrength('Faible')
        setPasswordError('Le champ Mot de passe est obligatoire.')
        break;
      case 'userName':
        setUserToAdd({ ...userToAdd, userName: '' })
        setUserNameError('Le champ Identifiant de l\'utilisateur est obligatoire.')
        break;
      case 'email':
        setUserToAdd({ ...userToAdd, email: '' })
        setEmailError("L'adresse e-mail est obligatoire.")
        break;
      case 'telephone':
        setUserToAdd({ ...userToAdd, telephone: '', ext: '' })
        setTelephoneError('Veuillez saisir un numéro de téléphone à 10 chiffres.');
        break;
      case 'poste':
        setUserToAdd({ ...userToAdd, ext: '' })
        break;
      case 'description':
        setUserToAdd({ ...userToAdd, Description: '' })
        break;
      default:
        break;
    }
  };

  const regenratePassword = () => {
    const passwordGenerated = generateRandomPassword();
    validatePassword(passwordGenerated, setPasswordError, setPasswordStrength)
    setUserToAdd({ ...userToAdd, password: passwordGenerated })
  }

  const regenrateUserName = async () => {
    try {
      const data = await generateUserName(userToAdd.prenom, userToAdd.nom); // generateUserName est asynchrone
      const updatedUser = {
        ...userToAdd,
        userName: data.userName
      };
      setUserNameError('');
      setUserToAdd(updatedUser);
    } catch (error) {
      console.error("Erreur lors de la génération de l'identifiant de l'utilisateur :", error);
    }
  }

  const handleChange = async (e) => {
    // console.log(e.target.name);
    if (e.target.name === 'prenom') {
      const formattedPrenom = capitalizeFirstLetterOfWords(e.target.value);
      validateField(formattedPrenom, 'Prénom', setPrenomError);

      // Mettre à jour fullName et userName immédiatement
      const updatedUser = {
        ...userToAdd,
        prenom: formattedPrenom,
        fullName: formattedPrenom + ' ' + userToAdd.nom,  // Mettre à jour fullName
        userName: generateUserName(formattedPrenom, userToAdd.nom) // Mettre à jour userName
      };

      // Mettre à jour userToAdd lorsque la résolution est terminée
      try {
        const data = await updatedUser.userName;
        setUserToAdd({
          ...updatedUser,
          userName: data.userName,
        });
      } catch (error) {
        console.error('Erreur lors de la génération du Prénom de l\'utilisateur :', error);
      }
    }

    if (e.target.name === 'nom') {
      const formattedNom = capitalizeFirstLetterOfWords(e.target.value);
      validateField(formattedNom, 'Nom', setNomError);

      // Mettre à jour fullName et userName immédiatement
      const updatedUser = {
        ...userToAdd,
        nom: formattedNom,
        fullName: userToAdd.prenom + ' ' + formattedNom,  // Mettre à jour fullName
        userName: generateUserName(userToAdd.prenom, formattedNom) // Mettre à jour userName
      };

      // Mettre à jour userToAdd lorsque la résolution est terminée
      try {
        const data = await updatedUser.userName;
        setUserToAdd({
          ...updatedUser,
          userName: data.userName,
        });
      } catch (error) {
        console.error('Erreur lors de la génération du nom de l\'utilisateur :', error);
      }
    }

    if (e.target.name === 'userName') {
      if (!e.target.value) {
        setUserNameError('Le champ Identifiant de l\'utilisateur est obligatoire.')
        setUserToAdd({ ...userToAdd, [e.target.name]: '' });
      }
      else {
        setUserNameError('')
        setUserToAdd({ ...userToAdd, [e.target.name]: e.target.value });
      }
    }

    if (e.target.name === 'password') {
      // console.log(e.target.name);
      validatePassword(e.target.value, setPasswordError, setPasswordStrength)
      setUserToAdd({ ...userToAdd, [e.target.name]: e.target.value });
    }

    if (e.target.name === 'email') {
      validateEmail(e.target.value, setEmailError)
      setUserToAdd({ ...userToAdd, [e.target.name]: e.target.value })
    }

    if (e.target.name === 'telephone') {
      const inputValue = e.target.value;
      // Vérifier si le numéro de téléphone contient des caractères non numériques sauf () et -
      if (inputValue.match(/[^\d()\- ]/)) {
        setTelephoneError('Le numéro de téléphone ne peut contenir que des chiffres.');
      } else {
        setTelephoneError(''); // Réinitialiser le message d'erreur s'il n'y a pas de caractères non numériques non autorisés
      }
      const sanitizedPhoneNumber = inputValue.replace(/[^\d]/g, ''); // Supprime les caractères non numériques,
      const formattedPhoneNumber = formatPhoneNumber(sanitizedPhoneNumber);

      setUserToAdd({ ...userToAdd, [e.target.name]: formattedPhoneNumber });

      if (formattedPhoneNumber.length === 14) {
        setTelephoneError('');
      } else {
        setTelephoneError('Veuillez saisir un numéro de téléphone à 10 chiffres.');
      }
      return;
    }

    if (e.target.name === 'ext') {
      const extPoste = e.target.value.replace(/[^\d]/g, ''); // Supprime les caractères non numérique

      // Limiter la longueur à 5 chiffres
      const maxLength = 5;
      const truncatedValue = extPoste.slice(0, maxLength);
      setUserToAdd({ ...userToAdd, [e.target.name]: truncatedValue })
    }

    if (e.target.name === 'Description') {
      setUserToAdd({ ...userToAdd, [e.target.name]: e.target.value });
      return;
    }
  }

  return (
    <div className='UserInfoForm'>

      <Grid container className='gridContainer' spacing={2} m='0' >

        <Grid item className='gridItem' xs={12} md={6} lg={6}  >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0' >
            <InputLabel htmlFor="prenom">Prénom *</InputLabel>
            <Input
              id="prenom"
              name="prenom"
              autoComplete="prenom"
              required
              value={userToAdd.prenom || ''}
              onChange={handleChange}

              startAdornment={
                <InputAdornment position="start">
                  <PersonAddRoundedIcon
                    sx={{
                      color:
                        (userToAdd.prenom && prenomError === '')
                          ? 'green'
                          : prenomError !== ''
                            ? 'red'
                            : 'action.active',
                      mr: 1,
                      my: 0.5
                    }}
                  />
                </InputAdornment>
              }

              endAdornment={
                userToAdd.prenom && (
                  <InputAdornment position="end">
                    <ClearIcon onClick={() => handleClear('prenom')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                  </InputAdornment>
                )
              }
            />
            {prenomError && <Typography variant="caption" color="error">{prenomError}</Typography>}
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6}  >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0' >
            <InputLabel htmlFor="nom">Nom *</InputLabel>
            <Input
              id="nom"
              name="nom"
              autoComplete="nom"
              required
              value={userToAdd.nom || ''}
              onChange={handleChange}

              startAdornment={
                <InputAdornment position="start">
                  <PersonAddRoundedIcon
                    sx={{
                      color: (userToAdd.nom && nomError === '') ? 'green' : nomError !== '' ? 'red' : 'action.active',
                      mr: 1,
                      my: 0.5
                    }}
                  />
                </InputAdornment>
              }

              endAdornment={
                userToAdd.nom && (
                  <InputAdornment position="end">
                    <ClearIcon onClick={() => handleClear('nom')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                  </InputAdornment>
                )
              }
            />
            {nomError && <Typography variant="caption" color="error">{nomError}</Typography>}
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6}  >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0' >
            <InputLabel htmlFor="userName">Identifiant de l'utilisateur *</InputLabel>
            <Input
              id="userName"
              name="userName"
              // autoComplete="userName"
              autoComplete="off" // Désactive l'auto-complétion
              required
              value={userToAdd.userName || ''}
              onChange={handleChange}

              startAdornment={
                <InputAdornment position="start">
                  <AccountCircleIcon
                    sx={{
                      color: (!userToAdd.userName && !userNameError) ? 'gray' : (userNameError) ? 'red' : 'green',
                      mr: 1,
                      my: 0.5
                    }}
                  />
                </InputAdornment>
              }

              endAdornment={
                <InputAdornment position="end">
                  {(userToAdd.nom || userToAdd.prenom) && (
                    <AutorenewIcon
                      className='renewIcon'
                      onClick={regenrateUserName}
                      sx={{ cursor: 'pointer' }}
                    />
                  )}
                  {userToAdd.userName && (
                    <ClearIcon onClick={() => handleClear('userName')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                  )}
                </InputAdornment>
              }
            />
            {userNameError && <Typography variant="caption" color="error">{userNameError}</Typography>}
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6}  >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0' >
            <InputLabel htmlFor="password">Mot de passe *</InputLabel>
            <Input
              id="password"
              name="password"
              // autoComplete="new-password"  // Utilisation pour un nouveau mot de passe
              // autoComplete="password"
              autoComplete="off"
              type={showPassword ? 'text' : 'password'}
              required
              value={userToAdd.password || ''}
              onChange={handleChange}

              startAdornment={
                <InputAdornment position="start">
                  <LockRoundedIcon
                    sx={{
                      color:
                        (!userToAdd.password && !passwordError) ? 'gray' :
                          (passwordStrength === 'Faible') ? 'red' :
                            (passwordStrength === 'Moyen') ? '#e59d11' :
                              (passwordStrength === 'Élevé') ? 'green' : 'red',
                      mr: 1,
                      my: 0.5
                    }}
                  />
                </InputAdornment>
              }

              endAdornment={
                <InputAdornment position="end">
                  <AutorenewIcon
                    className='renewIcon'
                    onClick={regenratePassword}
                    sx={{ cursor: 'pointer' }}
                  />
                  <IconButton className='visibilityIcon' onClick={handleClickPassword}>
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                  {userToAdd.password && (
                    <ClearIcon onClick={() => handleClear('password')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                  )}
                </InputAdornment>
              }
            />
            {passwordError && <Typography variant="caption" color="error">{passwordError}</Typography>}
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6}>
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <InputLabel htmlFor="email">Courriel *</InputLabel>
            <Input
              id="email"
              name="email"
              autoComplete="email"

              value={userToAdd.email || ''}
              onChange={handleChange}
              placeholder="email@exp-azimut.com"
              type="email"
              required
              startAdornment={
                <InputAdornment position="start">
                  <EmailRoundedIcon
                    sx={{
                      color: userToAdd.email && emailError === '' ? 'green' : emailError !== '' ? 'red' : 'action.active',
                      mr: 1,
                      my: 0.5
                    }}
                  />
                </InputAdornment>
              }
              endAdornment={
                userToAdd.email && (
                  <InputAdornment position="end">
                    <ClearIcon onClick={() => handleClear('email')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                  </InputAdornment>
                )
              }
            />
            {emailError && <Typography variant="caption" color="error">{emailError}</Typography>}
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl ' variant="standard" sx={{ mb: 2 }}>
            <Grid container >

              <Grid item xs={8}> {/* 70% */}
                <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                  <InputLabel htmlFor="telephone"  >Téléphone</InputLabel>
                  <Input
                    id="telephone"
                    name="telephone"
                    autoComplete="telephone"
                    value={userToAdd.telephone || ''}
                    onChange={handleChange}
                    required

                    startAdornment={
                      <InputAdornment position="start">
                        <PhoneInTalkRoundedIcon
                          sx={{
                            color: (userToAdd.telephone && telephoneError === '') ? 'green' : telephoneError !== '' ? 'red' : 'action.active',
                            mr: 1,
                            my: 0.5
                          }}
                        />
                      </InputAdornment>
                    }

                    endAdornment={
                      userToAdd.telephone && (
                        <InputAdornment position="end">
                          <ClearIcon onClick={() => handleClear('telephone')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                        </InputAdornment>
                      )
                    }
                  />
                  {telephoneError && <Typography variant="caption" color="error">{telephoneError}</Typography>}

                </FormControl>
              </Grid>

              <Grid item xs={4}> {/* 30% */}
                <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                  <InputLabel htmlFor="ext">Poste</InputLabel>
                  <Input
                    id="ext"
                    name='ext'
                    value={userToAdd.ext || ''}
                    onChange={handleChange}

                    startAdornment={
                      <InputAdornment position="start">
                        <PhoneForwardedRoundedIcon
                          sx={{
                            color:
                              (userToAdd.ext) ? 'green' : 'action.active',
                            mr: 1,
                            my: 0.5
                          }}
                        />
                      </InputAdornment>
                    }

                    endAdornment={
                      // (userToAdd.ext || userToAdd.phone) && (
                      userToAdd.ext && (
                        <InputAdornment position="end">
                          <ClearIcon onClick={() => handleClear('poste')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                        </InputAdornment>
                      )
                    }
                  />
                </FormControl>
              </Grid>

            </Grid>
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                options={genres}
                getOptionLabel={(option) => option.label}
                id="genre"
                name="genre"
                clearOnEscape

                isOptionEqualToValue={(option, value) => (option.label === value.label)}

                onChange={(e, newValue) =>
                  setUserToAdd((prevState) => ({
                    ...prevState,
                    // genre: newValue ? (newValue.label ? newValue.value : '') : '',
                    genre: newValue ? newValue.value : null,
                  })
                  )}

                renderInput={
                  (params) => <TextField {...params}
                    label="Genre" variant="standard" name="genre"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <WcRoundedIcon />
                        </InputAdornment>
                      )
                    }}
                  />}



              />
            </Stack>
          </FormControl>
        </Grid>
        {/* <Grid item className='gridItem checkboxItem ' xs={12} md={6} lg={6} ></Grid> */}

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                {...rolesDefaultProps}

                id="role"
                name="role"
                clearOnEscape

                isOptionEqualToValue={(option, value) => (option.roleLabel === value.roleLabel || option.roleLabel === '')}

                value={roles.find((option) => option.roleName === userToAdd.roleName) || null}

                onChange={(e, newValue) => {
                  setUserToAdd((prevState) => ({
                    ...prevState,
                    roleName: newValue ? newValue.roleName : 'ROLE_BANNI',
                    idRole: newValue
                      ? newValue.idRole
                      : roles.find((role) => role.roleName === 'ROLE_BANNI')?.idRole,
                  }));
                }}

                // onChange={(e, newValue) => {
                //   setUserToAdd(prevState => {
                //     const updatedRole = newValue ? { roleName: newValue.roleName, idRole: newValue.idRole } : { roleName: null, idRole: null };
                //     return { ...prevState, ...updatedRole };
                //   });
                // }}

                renderInput={
                  (params) => <TextField {...params} label="Rôle" variant="standard" name="role"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <AdminPanelSettingsIcon
                            sx={{
                              color: userToAdd && userToAdd.roleName
                                ? userToAdd.roleName === 'ROLE_ADMIN' ? '#a52525'
                                  : userToAdd.roleName === 'ROLE_CONSULT' ? 'green'
                                    : '#406cd6'
                                : 'action.active',
                              mr: 1,
                              my: 0.5
                            }}
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                }
              // disableClearable
              />
            </Stack>
          </FormControl>
        </Grid>

        {/* <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                {...titlesDefaultProps}
                id="title"
                name="title"
                isOptionEqualToValue={(option, value) => option.title === value.title}
                onChange={(e, newValue) =>
                  setUserToAdd((prevState) => ({
                    ...prevState,
                    title: newValue ? newValue.title : null,
                    idtitle: newValue ? newValue.idtitle : null,
                  }))
                }

                clearOnEscape
                renderInput={(params) => <TextField {...params} label="Titre" variant="standard" name="title" />}
              />
            </Stack>
          </FormControl>
        </Grid> */}

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                {...titlesDefaultProps}
                id="title"
                name="title"
                isOptionEqualToValue={(option, value) => option.title === value.title || value.title === ''}
                onChange={(e, newValue) =>
                  setUserToAdd((prevState) => ({
                    ...prevState,
                    title: newValue ? newValue.title : '',
                    idtitle: newValue ? newValue.idtitle : null,
                  }))
                }

                clearOnEscape
                renderInput={(params) => <TextField {...params} label="Titre" variant="standard" name="title" />}
              />
            </Stack>
          </FormControl>
        </Grid>

        {/* <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                {...departementsDefaultProps}
                id="department"
                name="department"

                isOptionEqualToValue={(option, value) => option.department === value.department}
                onChange={(e, newValue) =>
                  setUserToAdd((prevState) => ({
                    ...prevState,
                    department: newValue ? newValue.department : null,
                    iddepartment: newValue ? newValue.iddepartment : null,
                  }))
                }

                clearOnEscape
                renderInput={(params) => <TextField {...params} label="Département" variant="standard" name="department" />}
              />
            </Stack>
          </FormControl>
        </Grid> */}
        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                {...departementsDefaultProps}
                id="department"
                name="department"

                isOptionEqualToValue={(option, value) => option.department === value.department || value.department === ''}
                onChange={(e, newValue) =>
                  setUserToAdd((prevState) => ({
                    ...prevState,
                    department: newValue ? newValue.department : '',
                    iddepartment: newValue ? newValue.iddepartment : null,
                  }))
                }

                clearOnEscape
                renderInput={(params) => <TextField {...params} label="Département" variant="standard" name="department" />}
              />
            </Stack>
          </FormControl>
        </Grid>

        {/* <Grid item className='gridItem' xs={12} md={12} lg={12} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <TextField
              id="description"
              label="Description"
              name='Description'
              autoComplete='Description'
              // helperText="Saisir une déscription"
              variant="standard"
              type="search"
              multiline
              InputProps={{
                endAdornment: userToAdd.Description && (
                  <ClearIcon onClick={() => handleClear('description')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                ),
              }}
              inputProps={{ style: { color: 'black' } }}
              value={userToAdd.Description}
              onChange={handleChange}
            />
          </FormControl>
        </Grid> */}
        <Grid item className='gridItem' xs={12} md={12} lg={12} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <TextField
              id="Description"
              label="Description"
              name='Description'
              autoComplete='Description'
              // helperText="Saisir une déscription"
              variant="standard"
              type="search"
              multiline
              InputProps={{
                endAdornment: userToAdd.Description && (
                  <ClearIcon onClick={() => handleClear('description')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                ),
              }}
              inputProps={{ style: { color: 'black' } }}
              value={userToAdd.Description}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>

      </Grid>

    </div>
  )
}
