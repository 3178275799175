import dataService from "../../../httpService";

// Fonction pour obtenir les mises en ligne
async function getMiseEnLigneData() {
  const { cityCode, mrcCode } = JSON.parse(localStorage.getItem('currentMunicipality'));
  try {
    const response = await dataService.get('/mise-en-ligne/municipalitie/get-list-mel', {
      params: {
        cityCode: cityCode,
        mrcCode: mrcCode
      }
    });
    // console.log(response)
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Fonction pour obtenir le type d'une mise
async function getMiseEnLigneType() {
  try {
    const response = await dataService.get('/mise-en-ligne/get-type-mel');
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Fonction pour obtenir les sous-types d'une mise en ligne
async function getMiseEnLigneSubDataType() {
  try {
    const response = await dataService.get('/mise-en-ligne/get-sub-data-type-mel');
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Fonction pour obtenir la liste de tous les profils
async function getProfileList(idGroup) {
  try {
    const response = await dataService.get('/user/profile-list', {
      params: {
        idGroup
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Fonction pour obtenir la liste de tous les services d'un profil
async function getServicesProfile(cityCode, idGroup, nameProfile) {
  try {
    const response = await dataService.get('/user/services-profile-list', {
      params: {
        cityCode, idGroup, nameProfile
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Fonction pour vérifier si une MEL en cours, existe ou non, pour une municipalité
// async function isAddNewMELAllowed(formDataMEL){
//   try {
//     const response = await dataService.post('/mise-en-ligne/municipalitie/check-active-mel', formDataMEL);
//     return  response.data ;
//   } catch (error) {
//     console.error('Erreur lors de l\'envoi:', error);
//     throw error; // Lancer l'erreur pour gestion ultérieure
//   }
// }
async function isAddNewMELAllowed(formDataMEL) {
  try {
    const response = await dataService.get('/mise-en-ligne/municipalitie/check-active-mel', {
      params: formDataMEL
    });
    return response.data;
  } catch (error) {
    console.error('Erreur lors de l\'envoi:', error);
    throw error; // Lancer l'erreur pour gestion ultérieure
  }
}

// Fonction pour ajouter une mises en ligne à une municipalité
async function addNewMiseEnLigne(formDataMEL) {
  try {
    const response = await dataService.post('/mise-en-ligne/municipalitie/addnew-mel', formDataMEL);
    return { response: response.data };
  } catch (error) {
    console.error('Erreur lors de l\'envoi:', error);
    throw error; // Lancer l'erreur pour gestion ultérieure
  }
}

// Fonction pour envoyer au serveur les fichiers d'une mises en ligne
async function sendFilesMiseEnLigne(formData) {
  try {
    const response = await dataService.post('/mise-en-ligne/municipalitie/send-file-mel', formData);
    // Retourner la réponse après l'envoi
    return { response: response.data };

  } catch (error) {
    console.error('Erreur lors de l\'envoi:', error);
    throw error; // Lancer l'erreur pour gestion ultérieure
  }
}

// Fonction pour mettre à jour une mises en ligne d'une municipalité
async function updateMiseEnLigne(formDataMEL) {
  try {
    // Envoi de formDataMEL
    const responseMEL = await dataService.put('/mise-en-ligne/municipalitie/update-mel', {
      formDataMEL: formDataMEL
    });
    return { responseMEL: responseMEL.data };
  } catch (error) {
    console.error('Erreur lors de l\'envoi:', error);
    throw error; // Lancer l'erreur pour gestion ultérieure
  }
}

// Fonction pour supprimer les fichiers en extra d'une MEL
async function deleteExtraFilesMEL(params) {
  // Vérification des paramètres requis
  if (!params.idTracking || !Array.isArray(params.fichiersASupprimer) || params.fichiersASupprimer.length === 0) {
    return { success: false, message: 'Paramètres invalides pour la suppression des fichiers.' };
  }

  try {
    const response = await dataService.delete('/mise-en-ligne/municipalitie/delete-extra-files-mel', {
      params: {
        idTracking: params.idTracking,
        fichiersASupprimer: params.fichiersASupprimer
      }
    });

    // Vérification de la réponse
    return response?.data?.success
      ? { success: true, data: response.data }
      : { success: false, message: "Suppression partielle ou échouée." };
  } catch (error) {
    console.error("Erreur suppression fichiers:", error);
    return { success: false, message: error.response?.data || "Erreur serveur." };
  }
}

// Fonction pour envoyer une mise à jour des fichiers d'une MEL
async function sendUpdatedFilesMEL(formData) {
  // // Vérifier le contenu de formData avant l'envoi
  // console.log('-----------------✅ Contenu du formData -----------------');
  // for (let [key, value] of formData.entries()) {
  //   console.log(key, value);
  // }
  try {
    // Envoi de formDataMEL
    const responseMEL = await dataService.put('/mise-en-ligne/municipalitie/send-updated-files-mel', formData);
    return { responseMEL: responseMEL.data };
  } catch (error) {
    console.error("Erreur lors de l'envoi des fichiers :", error);
    throw error; // Lancer l'erreur pour gestion ultérieure
  }
}

// Fonction pour récuprer les utilisateurs qu'on peut leur attribuer un nouveau service
async function getUsersListForContact(cityCode, idGroup) {
  try {
    const response = await dataService.get('/mise-en-ligne/users-list-for-contact', {
      params: {
        cityCode, idGroup
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Fonction pour supprimer une mise en ligne
async function deleteSelectedOneMiseEnLigneOfAll(idTracking) {
  try {
    const response = await dataService.delete('/mise-en-ligne/municipalitie/delete-one-off-all-mel', {
      params: { idTracking }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Fonction pour supprimer le dossier des fichiers d'une mise en ligne
async function deleteMELFolderById(idTracking) {
  try {
    const response = await dataService.delete('/mise-en-ligne/municipalitie/delete-one-off-all-mel-folder', {
      params: { idTracking }
    });
    return { success: true, data: response.data };
  } catch (error) {
    if (error?.response) {
      if (error.response.status === 404) {
        // Dossier non trouvé, traiter silencieusement sans lever d'erreur critique
        console.warn(`Le dossier avec idTracking ${idTracking} n'existe pas, aucune action requise.`);
        return { success: false, message: 'Dossier inexistant' };
      } else {
        console.error(`Erreur serveur (${error.response.status}) pour idTracking ${idTracking}:`, error.response.data);
      }
    } else if (error?.request) {
      // La requête a été envoyée mais aucune réponse reçue (ex : problème réseau)
      console.error(`Aucune réponse reçue pour la suppression du dossier avec idTracking ${idTracking}.`);
    } else {
      // Erreur inattendue pendant la configuration de la requête
      console.error(`Erreur lors de la suppression du dossier avec idTracking ${idTracking}:`, error.message);
    }

    return { success: false, message: 'Erreur lors de la suppression du dossier' };
  }
}

// Fonction pour annuler une mise en ligne
async function cancelSelectedOneMiseEnLigneOfAll(idTracking) {
  try {
    const response = await dataService.delete('/mise-en-ligne/municipalitie/cancel-one-off-all-mel', {
      params: { idTracking }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Fonction pour obtenir une mise en ligne selon un idTracking
async function getMELByIdTracking(idTracking) {
  try {
    const response = await dataService.get('/mise-en-ligne/get-mel-to-edit', {
      params: { idTracking }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Fonction pour obtenir la liste des fichiers d'une mise en ligne  selon un idTracking
async function getMELFilesByIdTracking(idTracking) {
  try {
    const response = await dataService.get('/mise-en-ligne/get-mel-files-to-edit', {
      params: { idTracking }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Fonction pour obtenir la liste des services d'une mise en ligne  selon un idTracking
async function getMELServicesByIdTracking(idTracking) {
  try {
    const response = await dataService.get('/mise-en-ligne/get-mel-services-to-edit', {
      params: { idTracking }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Exporter les fonctions
export {
  getMiseEnLigneData,
  getMiseEnLigneType,
  getMiseEnLigneSubDataType,
  isAddNewMELAllowed,
  addNewMiseEnLigne,
  updateMiseEnLigne,
  deleteExtraFilesMEL,
  sendUpdatedFilesMEL,
  getUsersListForContact,
  getProfileList,
  getServicesProfile,
  sendFilesMiseEnLigne,
  deleteSelectedOneMiseEnLigneOfAll,
  deleteMELFolderById,
  cancelSelectedOneMiseEnLigneOfAll,
  getMELByIdTracking,
  getMELFilesByIdTracking,
  getMELServicesByIdTracking,
};