import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './AddUser.scss';
import {
    validatePassword,
    validateField,
    validateEmail,
    validateUserName
} from '../FunctionsUtils';
import {
    Paper,
    Button,
} from '@mui/material';
import DialogConfirm from './DialogConfirm';
import UserInfoForm from './User-Info-Form/UserInfoForm';
import UserProfilesServicesForm from './User-Profiles-Services-Form/UserProfilesServicesForm';
import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';

export default function AddUser() {
    const toDay = dayjs().isValid() ? dayjs().locale('frFR').format('YYYY-MM-DD HH:mm:ss') : null;

    const addUserFormRef = useRef(null);
    const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));
    const { cityCode } = currentCity
    const currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));
    const { idGroup } = currentUserInfo;

    const [userToAdd, setUserToAdd] = useState(
        {
            currentCity: currentCity,
            idGroup: idGroup,
            prenom: '',
            nom: '',
            fullName: '',
            userName: '',
            password: '',
            email: '',
            telephone: '',
            ext: '',
            genre: null,
            title: null,
            idtitle: null,
            Description: '',
            iddepartment: null,
            department: null,
            roleName: '',
            idRole: null,
            profile: null,
            servicesProfile: [],
            defaultServiceProfile: null,
            expiration: toDay,
            contact: false,
        }
    );

    const [prenomError, setPrenomError] = useState('');
    const [nomError, setNomError] = useState('');
    const [userNameError, setUserNameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [telephoneError, setTelephoneError] = useState('');
    const [passwordStrength, setPasswordStrength] = useState('');

    const [profileError, setProfileError] = useState('');
    const [profileServicesError, setProfileServicesError] = useState('');
    const [defaultServiceError, setDefaultServiceError] = useState('');

    // const [isAccordionValidated, setIsAccordionValidated] = useState(false);
    const [isFormValidated, setIsFormValidated] = useState(false);
    const [open, setOpen] = useState(false);


    const attributDefaultService = (userToAdd) => {
        return userToAdd.servicesProfile.map((item) => ({
            ...item,
            defaultService: item.aliasName === userToAdd.defaultServiceProfile?.aliasName ? true : false
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation des champs obligatoires
        validateField(userToAdd.prenom, 'Prénom', setPrenomError);
        validateField(userToAdd.nom, 'Nom', setNomError);
        const isUserNameValid = await validateUserName(userToAdd.userName, "Identifiant de l'utilisateur", setUserNameError);
        validateEmail(userToAdd.email, setEmailError);
        validatePassword(userToAdd.password, setPasswordError, setPasswordStrength);

        if (!userToAdd.profile) setProfileError('Un profil est obligatoire.');
        if (userToAdd.servicesProfile.length === 0) setProfileServicesError('Au minimum un service est obligatoire.');
        if (!userToAdd.defaultServiceProfile) setDefaultServiceError('Le champ service par défaut est obligatoire.');

        const updatedUser = {
            ...userToAdd,
            defaultServiceProfile: userToAdd.defaultServiceProfile !== null ? attributDefaultService(userToAdd, setUserToAdd) : userToAdd.defaultServiceProfile,
            // Si la date d'expiration n'est pas valide, assigner null
            expiration: dayjs(userToAdd.expiration).isValid() ? userToAdd.expiration : null
        }

        let validateForm
        // il faut attendre isUserNameValid avant de poursuivre à ce niveau avec le reste du code
        if (!isUserNameValid) {
            validateForm = false;
            setUserNameError("Une erreur s'est produite lors de la vérification du nom d'utilisateur.")
            console.log('Le nom d\'utilisateur est invalide. Arrêt de la soumission.');
        } else {
            validateForm =
                (updatedUser.nom !== '' && !nomError) &&
                (updatedUser.prenom !== '' && !prenomError) &&
                (updatedUser.userName !== '' && !userNameError) &&
                (updatedUser.password !== '') &&
                (updatedUser.email !== '' && !emailError) &&
                (updatedUser.profile !== null) &&
                (updatedUser.servicesProfile !== null) &&
                (updatedUser.defaultServiceProfile !== null)
        }

        // console.log({ validateForm })
        // console.log('=========>>>> updatedUser : ', updatedUser)
        // console.log(profileServicesError)

        if (validateForm) {
            console.log('Requête de soumission du Formulaire envoyée');
        }
        else {
            console.log('Un des champs est manquant ou mal saisi');
        }
        setIsFormValidated(validateForm);
        // permet d'afficher la boite de dialogue à la soumission du formulaire
        setOpen(true);
    };

    return (
        <div className='muAddUser'>

            {open && (
                <DialogConfirm
                    userToAdd={userToAdd}
                    prenomError={prenomError}
                    nomError={nomError}
                    userNameError={userNameError}
                    passwordError={passwordError}
                    emailError={emailError}
                    telephoneError={telephoneError}
                    profileError={profileError}
                    profileServicesError={profileServicesError}
                    defaultServiceError={defaultServiceError}
                    open={open}
                    setOpen={setOpen}
                    isFormValidated={isFormValidated}
                />
            )}

            <Paper elevation={5} className='muAddUser-paper' sx={{ height: 600, overflowY: 'auto' }}>
                <form ref={addUserFormRef} className='muAddUserForm' onSubmit={handleSubmit}>

                    <UserInfoForm
                        userToAdd={userToAdd}
                        setUserToAdd={setUserToAdd}
                        prenomError={prenomError}
                        setPrenomError={setPrenomError}
                        nomError={nomError}
                        setNomError={setNomError}
                        userNameError={userNameError}
                        setUserNameError={setUserNameError}
                        passwordError={passwordError}
                        setPasswordError={setPasswordError}
                        emailError={emailError}
                        setEmailError={setEmailError}
                        telephoneError={telephoneError}
                        setTelephoneError={setTelephoneError}
                        passwordStrength={passwordStrength}
                        setPasswordStrength={setPasswordStrength}
                    />
                    <hr />
                    <UserProfilesServicesForm
                        userToAdd={userToAdd}
                        setUserToAdd={setUserToAdd}
                        profileError={profileError}
                        setProfileError={setProfileError}
                        profileServicesError={profileServicesError}
                        setProfileServicesError={setProfileServicesError}
                        defaultServiceError={defaultServiceError}
                        setDefaultServiceError={setDefaultServiceError}
                    />
                    <hr />

                    <div className='footerAddUser'>
                        <Link to={`/dashboard/municipalites/${cityCode}/mu-utilisateurs`} style={{ textDecoration: "none" }}>
                            <Button className='btnSubmit' variant="contained" sx={{ width: '200px', cursor: 'pointer' }}>
                                Annuler
                            </Button>
                        </Link>

                        <Button className='btnSubmit' type="submit" variant="contained" sx={{ width: '200px' }} onClick={handleSubmit}>
                            Enregistrer
                        </Button>

                    </div>

                </form>
            </Paper>

        </div>
    )
}
