import LockRoundedIcon from '@mui/icons-material/LockRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Grid, Input, InputAdornment, InputLabel, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import './DialogChangePassword.scss';
import {
  validatePassword,
} from './FunctionsUtils';
import {
  checkUserPassword,
} from './httpQuerys';

export default function DialogChangePassword({ userToEdit, setUserToEdit, open, setOpen }) {

  const [passwordError, setPasswordError] = useState('');
  const [checkPasswordError, setCheckPasswordError] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [confirmed, setConfirmed] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [openDialog, setOpenDialog] = useState(open);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClose = () => {
    setOpen(false)
    setOpenDialog(false);
  };

  const handleChange = (e) => {
    setConfirmed(false);

    if (e.target.name === 'currentPassword') {
      setCurrentPassword(e.target.value)
    }

    if (e.target.name === 'newPassword') {
      setNewPassword(e.target.value)
      validatePassword(e.target.value, setPasswordError, setPasswordStrength)
    }

    if (e.target.name === 'confirmPassword') {
      setConfirmPassword(e.target.value)
    }
  }

  //-------------------pour rendre le password visible ou non ---------------------------
  // const [showPassword, setShowPassword] = useState(false);
  // const handleClickPassword = () => {
  //   setShowPassword(!showPassword)
  // }

  // Vérifie si la touche pressée est la touche "Entrée" (code 13) pour valider la confirmation
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleConfirm(); // Appelle la fonction handleConfirm
    }
  };

  const handleConfirm = async () => {

    setConfirmed(true);
    setCheckPasswordError('')
    setConfirmPasswordError('')
    let passwordMatch = false;

    if (currentPassword) {
      // Vérification du mot de passe de l'utilisateur
      passwordMatch = await checkUserPassword(currentPassword);
      if (!passwordMatch) {
        setCheckPasswordError('Mot de passe incorrect !');
      }
    } else {
      setCheckPasswordError('Ancien mot de passe obligatoire!');
    }

    if (!newPassword) {
      setPasswordError('Nouveau mot de passe demandé!');
    }

    if (!confirmPassword) {
      setConfirmPasswordError('Confirmation du mot de passe oblgatoire !');
    } else {
      if (newPassword !== confirmPassword) {
        setConfirmPasswordError('Les mots de passe ne correspondent pas !')
      }
    }

    if (passwordMatch && newPassword && newPassword === confirmPassword) {
      setUserToEdit({ ...userToEdit, password: newPassword });
      handleClose()
    }
  };

  return (
    <div>

      <Dialog
        className='dialogChangePassword'
        open={openDialog}
        onClose={handleClose}
        onKeyDown={handleKeyDown}
      >

        <DialogTitle className='dialogTitle'>Changement de mot de passe</DialogTitle>

        <DialogContent>
          <Grid container className='dialogGridContainer' spacing={2}>

            <Grid item className='dialogGridItem-text' xs={12} md={12} lg={12}  >
              <DialogContentText>
                Pour changer votre mot de passe vous êtes demandé de saisir votre ancien mot de passe par mesures de sécurité.<br />
                Ensuite, veuillez entrer votre nouveau mot de passe et le confirmer. Merci !
              </DialogContentText>
            </Grid>

            <Grid item className='dialogGridItem' xs={12} md={6} lg={6}  >
              <InputLabel htmlFor="currentPassword">Mot de passe actuel</InputLabel>
              <Input
                type={showCurrentPassword ? 'text' : 'password'}
                id="currentPassword"
                name="currentPassword"
                value={currentPassword}
                onChange={handleChange}
                startAdornment={
                  <InputAdornment position="start">
                    <LockRoundedIcon
                      sx={{
                        color: confirmed ? (!checkPasswordError ? 'green' : 'red') : 'gray',
                        mr: 1,
                        my: 0.5
                      }}
                    />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                      {showCurrentPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>

            <Grid item className='dialogGridItem-error' xs={12} md={6} lg={6}  >
              {checkPasswordError && (
                <Typography variant="caption" color="error" sx={{ ml: '32px' }}>
                  {checkPasswordError}
                </Typography>
              )}
            </Grid>


            <Grid item className='dialogGridItem' xs={12} md={6} lg={6}  >
              <InputLabel htmlFor="newPassword">Nouveau mot de passe</InputLabel>
              <Input
                type={showNewPassword ? 'text' : 'password'}
                id="newPassword"
                name="newPassword"
                value={newPassword}
                onChange={handleChange}
                startAdornment={
                  <InputAdornment position="start">
                    <LockRoundedIcon
                      sx={{
                        color:
                          (!newPassword && !passwordError) ? 'gray' :
                            (passwordStrength === 'Faible') ? 'red' :
                              (passwordStrength === 'Moyen') ? '#e59d11' :
                                (passwordStrength === 'Élevé') ? 'green' : 'red',

                        mr: 1,
                        my: 0.5
                      }}
                    />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
                      {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>

            <Grid item className='dialogGridItem-error' xs={12} md={6} lg={6}  >
              {passwordError && (
                <Typography variant="caption" color="error" sx={{ ml: '32px' }}>
                  {passwordError}
                </Typography>
              )}
            </Grid>

            <Grid item className='dialogGridItem' xs={12} md={6} lg={6}  >
              <InputLabel htmlFor="confirmPassword">Confirmer nouveau mot de passe</InputLabel>
              <Input
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleChange}
                startAdornment={
                  <InputAdornment position="start">
                    <LockRoundedIcon
                      sx={{
                        color: confirmed ? ((confirmPassword && confirmPassword === newPassword && !confirmPasswordError) ? 'green' : 'red') : 'gray',
                        mr: 1,
                        my: 0.5
                      }}
                    />
                  </InputAdornment>
                }
                // onMouseDown={handleMouseDownPassword}
                // onMouseUp={handleMouseDownPassword}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                      {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>

            <Grid item className='dialogGridItem-error' xs={12} md={6} lg={6}  >
              {confirmPasswordError && (
                <Typography variant="caption" color="error" sx={{ ml: '32px' }}>
                  {confirmPasswordError}
                </Typography>
              )}
            </Grid>

            <Grid item className='dialogGridItem-message' xs={12} md={12} lg={12}  >
              {passwordError && (
                <Typography variant="caption" color="error" sx={{ ml: '32px' }}>
                  {/* Au moins une lettre minuscule, au moins une lettre majuscule, au moins un chiffre, au moins un caractère spécial parmi @$!%*?&#, et une longueur minimale de 8 caractères. */}
                  Au moins une lettre minuscule, au moins une lettre majuscule, au moins un chiffre et une longueur minimale de 6 caractères.
                </Typography>
              )}
            </Grid>

          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>ANNULER</Button>
          <Button onClick={handleConfirm}>CONFIRMER</Button>
        </DialogActions>

      </Dialog>
    </div>
  )
}
