import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { deleteSelectedOneUserOffAll } from "../httpQuerys";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Box,
} from "@mui/material";

export default function DialogConfirm({ open, setOpen, selectedRows }) {

  // const { cityCode, idGroup } = JSON.parse(localStorage.getItem('currentMunicipality'));
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(open);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [messageConfirmationDialog, setMessageConfirmationDialog] = useState("");
  const [loading, setLoading] = useState(false);

  // Synchronisation de openDialog avec open
  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setOpenDialog(false);
    setOpenSecondDialog(false);
    navigate("/dashboard/utilisateurs");
  };

  const handleCancel = () => {
    setOpen(false);
    setOpenDialog(false);
    setOpenSecondDialog(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") handleConfirm();
    if (e.key === "Escape") handleClose();
  };

  const handleConfirm = async () => {
    console.log("Deleting selected rows...");
    setLoading(true);

    try {
      // Utilisation de Promise.allSettled() pour éviter les interruptions en cas d'erreur
      const results = await Promise.allSettled(
        selectedRows.map((row) => deleteSelectedOneUserOffAll(row))
      );

      // Vérification des résultats des suppressions
      const errors = results.filter((res) => res.status === "rejected");
      if (errors.length === 0) {
        console.log("Suppression effectuée avec succès.");
        setMessageConfirmationDialog("Suppression effectuée avec succès.");
      } else {
        console.log("Certaines suppressions ont échoué !");
        setMessageConfirmationDialog("Certaines suppressions ont échoué !");
      }

      setOpenSecondDialog(true);
    } catch (error) {
      console.error("Erreur lors de la suppression", error);
      setMessageConfirmationDialog("Erreur lors de la suppression !");
      setOpenSecondDialog(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={openDialog} onClose={handleClose} onKeyDown={handleKeyPress}>
      {openSecondDialog ? (
        <>
          <DialogTitle>Confirmation de la suppression</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <strong>{messageConfirmationDialog}</strong>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Fermer
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>Suppression d'utilisateur(s)</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Êtes-vous sûr de vouloir supprimer{" "}
              {selectedRows.length > 1 ? `${selectedRows.length} utilisateurs` : " cet utilisateur"} ?
              Cette action est irréversible.
            </DialogContentText>
            {loading && (
              <>
                <LinearProgress sx={{ mt: 2 }} />
                <Box sx={{ color: "#999", fontSize: "0.875rem" }}>
                  Requête en cours, veuillez patienter...
                </Box>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="error" disabled={loading}>
              Annuler
            </Button>
            <Button onClick={handleConfirm} color="primary" disabled={loading}>
              Confirmer
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
