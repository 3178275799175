import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import './Messages.scss';
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MessagesList from "./MessagesList/MessagesList";
import AddNewMessage from "./AddNew-Message/AddNewMessage";

export default function Messages() {
  const navigate = useNavigate();

  // Tableau des rôles autorisés pour la gestion des messages ['ROLE_ADMIN', 'ROLE_CONSULT', 'ROLE_GESTION_UTILISATEURS', 'ROLE_MISE_EN_LIGNE', 'ROLE_GESTION_UTILISATEURS_ET_MISE_EN_LIGNE'];
  const allowedRolesForUserManagement = ['ROLE_ADMIN', 'ROLE_GESTION_UTILISATEURS', 'ROLE_GESTION_UTILISATEURS_ET_MISE_EN_LIGNE'];
  const { roleName, idUser, idGroup } = JSON.parse(localStorage.getItem('currentUserInfo'));
  const allowedManagement = allowedRolesForUserManagement.includes(roleName);
  // const allowedManagement = allowedRolesForUserManagement.includes(roleName) || (idGroup === 'G00');

  const { page } = useParams();
  const [value, setValue] = useState(allowedManagement ? page : 'users-list');

  useEffect(() => {
    if (page === 'add-new-message' && !allowedManagement) {
      navigate('/dashboard/messages/messages-list');
      setValue('messages-list')
    } else {
      setValue(page)
    }
  }, [allowedManagement, navigate, page])

  const handleChange = (event, newValue) => {
    navigate(`/dashboard/messages/${newValue}`)
    setValue(newValue);
  };

  return (
    <div className='Messages '>

      <div className="topPage">
        <div className="topTitle">
          <InsertCommentOutlinedIcon />
          <div>Gestion des Messages </div>
        </div>
        <div className="topNav">
          <Link className='topNav-close' to={`/dashboard`} style={{ textDecoration: "none", cursor: 'pointer' }}>
            <CloseRoundedIcon />
          </Link>
        </div>
      </div>

      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} >
              <Tab label="Liste des messagess" value='messages-list' />
              {allowedManagement ? (
                <Tab label="Nouveau message" value='add-new-message' />
              ) : null}
            </TabList>
          </Box>

          <TabPanel value="messages-list">
            <MessagesList idUser={idUser} idGroup={idGroup} allowedManagement={allowedManagement} />
          </TabPanel>

          <TabPanel value="add-new-message" >
            <AddNewMessage idUser={idUser} idGroup={idGroup}/>
          </TabPanel>

        </TabContext>
      </Box>
    </div>
  );
}