import React, { useEffect, useState } from "react";
import './Cards.scss'
import Card from "../card/Card";
import moment from 'moment'
import "moment/locale/fr"
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import MultipleStopRoundedIcon from '@mui/icons-material/MultipleStopRounded';
// import SavingsRoundedIcon from '@mui/icons-material/SavingsRounded';

moment.locale('fr-ca'); // Définir la locale fr-CA comme locale par défaut

// Créer un objet Date pour la date d'aujourd'hui
const today = new Date();
// (date d'aujourd'hui - date du premier jour de l'année => valeur en millisecondes) / nombre de millisecondes par jour (86400000)
// ceci nous donne le nombre de jours écoulés depuis le début de l'année en cours
const nbJourEcoules = Math.ceil((today - new Date(today.getFullYear(), 0, 1)) / 86400000);

const cardsData = [
  {
    seriesKey: "Utilisateurs",
    title: "Utilisateurs",
    color: {
      backGround: "linear-gradient(90deg, #cfccf2 0%, #c5d1e2 100%)",
      boxShadow: "2px 4px 10px 1px rgba(195, 193, 193, 0.47)",
    },
    png: PeopleAltRoundedIcon,
  },
  {
    seriesKey: "Sessions",
    title: "Sessions",
    color: {
      backGround: "linear-gradient(90deg, #c5d1e2 0%, #cfccf2 100%)",
      boxShadow: "2px 4px 10px 1px rgba(195, 193, 193, 0.47)",
    },
    png: HourglassTopRoundedIcon,
  },
  {
    seriesKey: "Requetes",
    title: "Requêtes",
    color: {
      backGround: "linear-gradient(90deg, #cfccf2 0%, #c5d1e2 100%)",
      boxShadow: "2px 4px 10px 1px rgba(195, 193, 193, 0.47)",
    },
    png: MultipleStopRoundedIcon,
  },
  // {
  //   seriesKey: "Economies",
  //   title: "Économies",
  //   color: {
  //     backGround: "linear-gradient(90deg, #ebebf9 0%, #cfe0f4 100%)",
  //     boxShadow: "2px 4px 10px 1px rgba(195, 193, 193, 0.47)",
  //   },
  //   png: SavingsRoundedIcon,
  // },
];

function AnalyticsCard({ title, color, seriesData, seriesX, total, moyenne, png: Png, isCurrentYear }) {
  return (
    <div className="parentContainer">
      <Card
        title={title}
        color={color}
        seriesData={seriesData}
        seriesX={seriesX}
        total={total}
        moyenne={moyenne}
        png={Png}
        isCurrentYear={isCurrentYear}
      />
    </div>
  );
}

function EconomiesCard({ title, color, totalEconomies, previsions, png: Png, isCurrentYear }) {
  return (
    <div className="parentContainer">
      <Card
        title={title}
        color={color}
        totalEconomies={totalEconomies}
        previsions={previsions}
        png={Png}
        isCurrentYear={isCurrentYear}
      />
    </div>
  );
}

export default function Cards({ dataTable, periode, version }) {

  const isCurrentYear = moment().year().toString() === periode;
  const [data, setData] = useState([...dataTable]);
  const getTotal = (key) => data.reduce((total, row) => total + row[key], 0);

  useEffect(() => {
    setData([...dataTable]);
  }, [dataTable]);

  const cards = cardsData.map((card, index) => {

    const seriesData = [
      { name: `${card.title}-${periode}`, data: data.map((row) => row[card.seriesKey]) },
    ];
    const seriesX = data.map((row, index) => moment().month(row.Mois - 1).format('MMM'));
    const total = getTotal(card.seriesKey);
    const moyenne = Number((total / data.length).toFixed(0));
    const totalEconomies = card.seriesKey === 'Economies' && getTotal('Sessions') * 5;
    const previsions = isCurrentYear ? card.seriesKey === 'Economies' && (totalEconomies / nbJourEcoules) * 365 : 0;

    return (
      card.seriesKey !== 'Economies' ?
        <AnalyticsCard
          key={index}
          title={card.title}
          color={card.color}
          seriesData={seriesData}
          seriesX={seriesX}
          total={total}
          moyenne={moyenne}
          png={card.png}
          isCurrentYear={isCurrentYear}
        />
        :
        version === 'Publique' &&
        <EconomiesCard
          key={index}
          title={card.title}
          color={card.color}
          totalEconomies={totalEconomies}
          previsions={previsions}
          png={card.png}
          isCurrentYear={isCurrentYear}
        />
    );
  });

  return (
    <div className="GridCards">
      {cards}
    </div>
  );
}