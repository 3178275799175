import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './MuMiseEnLigneEdit.scss'
import DialogConfirm from './DialogConfirm';
import {
    getMELByIdTracking,
    getMELFilesByIdTracking,
    getMELServicesByIdTracking,
} from '../httpQuerys';
import {
    // validateField,
    validateAlphanumericField,
} from '../FunctionsUtils';
import {
    Paper,
    Button,
} from '@mui/material';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";


import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';
import MuHeader from '../../../../components/mu-header/MuHeader';
import MiseEnLigneEditForm from './MiseEnLigne-Edit-Form/MiseEnLigneEditForm';

export default function MuMiseEnLigneEdit({ allowedManagement }) {


    // const { idTracking } = useParams();
    const location = useLocation();
    const melToEdit = useMemo(() => location.state || {}, [location.state]);

    // const toDay = dayjs().isValid() ? dayjs().locale('frFR').format('YYYY-MM-DD HH:mm:ss') : null;

    const miseEnLigneFormRef = useRef(null);
    const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));
    const { cityCode } = currentCity
    // const currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));

    const [formDataMEL, setFormDataMEL] = useState()
    const [lastMELValues, setLastMELValues] = useState()

    const [formErrors, setFormErrors] = useState({
        idDataTypeMaj: '',
        typeMaj: '',
        noteDemandeur: '',
        fichiers: '',
        PDForLink: '',
        lienWeb: '',
        producteur: '',
        dateProduction: '',
        idSubDataTypeMaj: '',
        zonageReglement: '',
        dateZonageEnVigueur: '',
        profile: '',
        services: '',
        messageMetaData: '',
        numeroReglement: '',
        zonageGuideInterpretation: '',
        zonageCoucheSource: '',
        zonageChampZone: '',
    });

    const [isFormValidated, setIsFormValidated] = useState(false);
    const [open, setOpen] = useState(false);

    // Vérifie si la chaîne input est un lien URL ou un nom de fichier
    const isFileOrURL = (input) => {
        // Vérifie si la chaîne input est une URL
        const urlRegex = /^(https?:\/\/)([\w.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/i;

        // Vérifie si la chaîne est un fichier
        const fileRegex = /^(.*[\\/])?([\w,\s-]+\.[A-Za-z]{1,5})$/;

        if (urlRegex.test(input)) {
            return 'URL';
        } else if (fileRegex.test(input)) {
            return 'Fichier';
        }
    };

    // Récupération des données à partir de la BD pour l'édition d'une mise en ligne
    useEffect(() => {
        const fetchAllData = async () => {
            try {
                // Récupérer les données principales
                const [mainData] = await getMELByIdTracking(melToEdit.idTracking);
                const filesData = await getMELFilesByIdTracking(melToEdit.idTracking);
                const servicesData = await getMELServicesByIdTracking(melToEdit.idTracking);

                // Ajouter le fichier dans filesData si mainData.zonageReglement n'est pas vide et qu'il s'agit d'un fichier
                if (mainData.zonageReglement) {
                    if (isFileOrURL(mainData.zonageReglement) === 'Fichier') {
                        filesData.push({ nomFichier: mainData.zonageReglement });
                    }
                }

                // Ajouter le fichier dans filesData si mainData.zonageGuideInterpretation n'est pas vide et qu'il s'agit d'un fichier
                if (mainData.zonageGuideInterpretation) {
                    if (isFileOrURL(mainData.zonageGuideInterpretation) === 'Fichier') {
                        filesData.push({ nomFichier: mainData.zonageGuideInterpretation });
                    }
                }

                // Récupérer uniquement les noms de fichiers
                const filesName = filesData.map((file) => file.nomFichier);

                // Construire les données mises à jour
                const updatedData = {
                    ...mainData,
                    dBfiles: filesData,
                    fichiers: filesData,
                    filesName,
                    profile: servicesData[0]?.nameProfile || '',
                    services: servicesData || [],

                    ...(mainData.typeMaj === 'Zonage' && {
                        PDForLink: filesData.length > 0 ? 'PDF' : 'Link',
                        lienWeb:
                            filesData.length > 0
                                ? ''
                                : mainData.zonageGuideInterpretation || mainData.zonageReglement || '',
                    }),

                    ...(mainData.typeMaj === 'Rôle' && {
                        messageMetaData:
                            'Les informations sur le triennal, le producteur, l’évaluateur et la date de production seront extraites du rôle transmis.',
                    }),

                    ...((mainData.typeMaj === 'Matrice' && mainData.subDataDescription === 'GOmatrice') && {
                        messageMetaData:
                            'Les informations sur le producteur et la date de production seront extraites des fichiers.',
                    }),

                    ...((mainData.typeMaj === 'Infrastructure' && mainData.subDataDescription === 'GOinfra') && {
                        messageMetaData:
                            'Les métadonnées seront déterminées par la date de mise en ligne.',
                    }),
                };

                // Mise à jour des états
                setFormDataMEL(updatedData);
                setLastMELValues(updatedData); // Synchroniser avec les valeurs initiales
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        };

        fetchAllData();
    }, [melToEdit.idTracking]);

    // Validation d'un lien vers une page web
    function isValidURL(string) {
        const urlPattern = new RegExp(
            '^(https?:\\/\\/)?' + // Protocole
            '((([a-zA-Z0-9$-_@.&+!*"(),])+\\.)+([a-zA-Z]{2,}))' + // Domaine
            '(\\/[a-zA-Z0-9$-_@.&+!*"(),]*)?' + // Chemin facultatif
            '(\\?.*)?$' // Query string facultatif
        );
        return !!urlPattern.test(string);
    }

    const handleReset = () => {
        // Réinitialiser tous les états
        console.log('reset des données de la mise en ligne courante')
        setFormDataMEL(lastMELValues);
    };

    //!================================================================
    //!================================================================
    //!================================================================


    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log('Reception du formulaire formDataMEL ', formDataMEL);

        const updatedFormData = {
            ...formDataMEL,
        };

        const updatedFormErrors = {
            ...formErrors,
        };

        // const typeMajArray = ['Autre', 'GOcadastre', 'Matrice', 'Rôle', 'Zonage', 'Orthophoto', 'Infrastructure', 'Croquis', 'Photos', 'Transactions immobilières', 'Taxes'];
        const typeMajArray = ['Matrice', 'Rôle', 'Zonage'];
        let validateForm = true;

        //!================================================================

        // Validation de la sélection d'un sous type de donnée
        // const typeMajWithSubTypeArray = ['Matrice', 'Zonage','Infrastructure'];
        // if (typeMajWithSubTypeArray.includes(updatedFormData.typeMaj)) {
        if (
            (updatedFormData.typeMaj === 'Matrice') ||
            (updatedFormData.typeMaj === 'Zonage') ||
            (updatedFormData.typeMaj === 'Infrastructure')
        ) {
            if (updatedFormData.idSubDataTypeMaj === null) {
                updatedFormErrors.idSubDataTypeMaj = `Veuillez sélectionner un sous type pour la mise à jour ${updatedFormData.typeMaj}`;
                validateForm = false;
            }
        }

        //!================================================================
        //!================================================================
        //!================================================================

        // Validation de la sélection d'un ou de plusieurs fichiers
        if (typeMajArray.includes(updatedFormData.typeMaj) && updatedFormData.fichiers.length === 0 && !updatedFormData.lienWeb) {
            updatedFormErrors.fichiers = 'Veuillez sélectionner le ou les fichiers à déposer !';
            validateForm = false;
        }

        // console.log('====>>>>', updatedFormData.typeMaj, updatedFormData.fichiers)
        if (typeMajArray.includes(updatedFormData.typeMaj) && updatedFormData.fichiers.length > 0) {
            // Extraire les noms des fichiers
            const filesName = formDataMEL.fichiers.map(file => file.nomFichier || file.name);

            // Ajouter le tableau des noms au formDataMEL
            updatedFormData.filesName = filesName;


            // Décomposer les fichiers en deux tableaux
            const anciensFichiers = updatedFormData.fichiers.filter(f => f.nomFichier); // Fichiers existants
            const nouveauxFichiers = updatedFormData.fichiers.filter(f => f instanceof File); // Nouveaux fichiers ajoutés

            // Extraire uniquement les noms des fichiers
            updatedFormData.nomsAnciensFichiers = anciensFichiers.map(f => f.nomFichier); // Noms des anciens fichiers
            updatedFormData.nomsNouveauxFichiers = nouveauxFichiers.map(f => f.name); // Noms des nouveaux fichiers (f.name pour les objets File)


            if ((updatedFormData.typeMaj === 'Zonage') &&
                (updatedFormData.idSubDataTypeMaj === 6 || updatedFormData.idSubDataTypeMaj === 8) &&
                (updatedFormData.PDForLink === 'PDF')) {
                updatedFormData.fichiers = nouveauxFichiers;
                updatedFormData.nomsAnciensFichiers = []
            }
        }

        // Validation de 'Producteur et dateProduction'
        if ((updatedFormData.typeMaj === 'Autre') ||
            (updatedFormData.typeMaj === 'Matrice' && (updatedFormData.idSubDataTypeMaj === 2 || updatedFormData.idSubDataTypeMaj === 10)) ||
            (updatedFormData.typeMaj === 'Zonage') ||
            (updatedFormData.typeMaj === 'Orthophoto') ||
            (updatedFormData.typeMaj === 'Croquis') ||
            (updatedFormData.typeMaj === 'Photos') ||
            (updatedFormData.typeMaj === 'Transactions immobilières')
        ) {
            // Validation du champs 'Producteur'
            validateAlphanumericField(updatedFormData.producteur, 'Producteur', (errorMessage) => {
                updatedFormErrors.producteur = errorMessage;
                if (errorMessage) validateForm = false;
            });

            // Validation de la date 'dateProduction'
            if (dayjs(updatedFormData.dateProduction).isValid()) {
                // Vérifier si la date sélectionnée est aujourd'hui
                if (dayjs(updatedFormData.dateProduction).isSame(dayjs(), 'day')) {
                    updatedFormErrors.dateProduction = "La date d'aujourd'hui est sélectionnée par défaut";
                } else {
                    updatedFormErrors.dateProduction = ''; // Pas d'erreur si la date est valide et différente d'aujourd'hui
                }
            } else {
                updatedFormErrors.dateProduction = 'La date n\'est pas correcte';
                validateForm = false; // Formulaire invalide si la date n'est pas correcte
            }
        }

        // Validation du champs 'Numéro de règlements'
        if (updatedFormData.typeMaj === 'Zonage') {
            validateAlphanumericField(updatedFormData.numeroReglement, 'Numéro de règlements', (errorMessage) => {
                updatedFormErrors.numeroReglement = errorMessage;
                if (errorMessage) validateForm = false;
            });
        }

        // Validation de la date 'dateZonageEnVigueur'
        if (updatedFormData.typeMaj === 'Zonage') {
            if (dayjs(updatedFormData.dateZonageEnVigueur).isValid()) {
                // Vérifier si la date sélectionnée est aujourd'hui
                if (dayjs(updatedFormData.dateZonageEnVigueur).isSame(dayjs(), 'day')) {
                    updatedFormErrors.dateZonageEnVigueur = "La date d'aujourd'hui est sélectionnée par défaut";
                } else {
                    updatedFormErrors.dateZonageEnVigueur = ''; // Pas d'erreur si la date est valide et différente d'aujourd'hui
                }
            } else {
                updatedFormErrors.dateZonageEnVigueur = 'La date n\'est pas correcte';
                validateForm = false; // Formulaire invalide si la date n'est pas correcte
            }
        }

        // Validation du champs 'Couche source'
        if (updatedFormData.typeMaj === 'Zonage' && updatedFormData.idSubDataTypeMaj === 4) {
            validateAlphanumericField(updatedFormData.zonageCoucheSource, 'Couche source', (errorMessage) => {
                updatedFormErrors.zonageCoucheSource = errorMessage;
                if (errorMessage) validateForm = false;
            });
        }

        // Validation du champs 'Champ zone'
        if (updatedFormData.typeMaj === 'Zonage' && updatedFormData.idSubDataTypeMaj === 4) {
            validateAlphanumericField(updatedFormData.zonageChampZone, 'Champ zone', (errorMessage) => {
                updatedFormErrors.zonageChampZone = errorMessage;
                if (errorMessage) validateForm = false;
            });
        }

        // Validation de Fichier .PDF ou un Lien vers une page Web
        if (
            (updatedFormData.typeMaj === 'Zonage') &&
            (updatedFormData.idSubDataTypeMaj === 6 || updatedFormData.idSubDataTypeMaj === 8) &&
            (updatedFormData.PDForLink === '')
        ) {
            updatedFormErrors.PDForLink = 'Veuillez selectionner Fichier .PDF ou un Lien vers une page Web !'
            validateForm = false; // Formulaire invalide si pas de sélection
        }

        // Validation du lien Web
        if (
            (updatedFormData.typeMaj === 'Zonage') &&
            (updatedFormData.idSubDataTypeMaj === 6 || updatedFormData.idSubDataTypeMaj === 8) &&
            (updatedFormData.PDForLink === 'Link') &&
            (!isValidURL(updatedFormData.lienWeb))
        ) {
            updatedFormErrors.zonageReglement = 'Veuillez entrer un lien valide vers une page web !'
            updatedFormErrors.zonageGuideInterpretation = 'Veuillez entrer un lien valide vers une page web !'
            validateForm = false; // Formulaire invalide si pas de sélection
        }

        // Mise à jour des champs directement pour le lien local ou fichier PDF
        if (updatedFormData.typeMaj === 'Zonage') {
            if (updatedFormData.idSubDataTypeMaj === 6 && updatedFormData.PDForLink === 'PDF') {
                // if (updatedFormData.idSubDataTypeMaj === 6 && updatedFormData.zonageReglement === '') {
                updatedFormData.zonageReglement = updatedFormData.nomsNouveauxFichiers[0];
                // updatedFormData.fichiers = [];
                updatedFormData.filesName = [];
                updatedFormData.nomsNouveauxFichiers = [];
            } else if (updatedFormData.idSubDataTypeMaj === 8 && updatedFormData.PDForLink === 'PDF') {
                // } else if (updatedFormData.idSubDataTypeMaj === 8 && updatedFormData.zonageGuideInterpretation === '') {
                updatedFormData.zonageGuideInterpretation = updatedFormData.nomsNouveauxFichiers[0];
                // updatedFormData.fichiers = [];
                updatedFormData.filesName = [];
                updatedFormData.nomsNouveauxFichiers = [];
            }
        }

        // Validation por le type 'Autre' service et profile
        if (updatedFormData.typeMaj === 'Autre') {
            if (updatedFormData.services.length === 0) {
                updatedFormErrors.services = 'Services manquants';
                validateForm = false;
            } else {
                updatedFormErrors.services = ''
            }

            if (!updatedFormData.profile) {
                updatedFormErrors.profile = 'Profile manquant';
                validateForm = false;
            } else {
                updatedFormErrors.profile = ''
            }
        }


        setFormDataMEL(updatedFormData);
        setFormErrors(updatedFormErrors);

        if (validateForm) {
            console.log('Soumission du Formulaire');
            setIsFormValidated(true);
        }
        else {
            console.log('Un des champs est manquant ou mal saisi');
            setIsFormValidated(false);
        }

        // permet d'afficher la boite de dialogue à la soumission du formulaire
        setOpen(true);
    };

    return (
        <div className='MiseEnLigneToEdit'>
            <MuHeader currentCity={currentCity} />

            <div className="topPage">
                <div className="topTitle">
                    <CloudUploadRoundedIcon />
                    <div>Edition de mise en ligne, {currentCity.description}</div>
                    <span><em>{currentCity.cityName}</em></span>
                </div>
                <div className="topNav">
                    <Link className='topNav-close' to={`/dashboard/municipalites/${cityCode}/mu-mise-en-ligne/mu-mel-list`} style={{ textDecoration: "none", cursor: 'pointer' }}>
                        <CloseRoundedIcon />
                    </Link>
                </div>
            </div>

            {open && (
                <DialogConfirm
                    formDataMEL={formDataMEL}
                    formErrors={formErrors}
                    open={open}
                    setOpen={setOpen}
                    isFormValidated={isFormValidated}
                />
            )}

            <Paper elevation={5} className='MiseEnLigneToEdit-paper' sx={{ height: 600, overflowY: 'auto' }}>
                <form ref={miseEnLigneFormRef} className='MiseEnLigneToEdit-Form' onSubmit={handleSubmit}>

                    <MiseEnLigneEditForm
                        formDataMEL={formDataMEL}
                        setFormDataMEL={setFormDataMEL}
                        formErrors={formErrors}
                        setFormErrors={setFormErrors}
                        allowedManagement={allowedManagement}
                    />

                    <hr />

                    <div className='footerMiseEnLigne'>
                        <Link to={`/dashboard/municipalites/${cityCode}/mu-mise-en-ligne`} style={{ textDecoration: "none" }}>
                            <Button className='btnSubmit' variant="contained" sx={{ width: '200px', cursor: 'pointer' }}>
                                Annuler
                            </Button>
                        </Link>

                        <Button className='btnSubmit' type="reset" variant="contained" sx={{ width: '200px', cursor: 'pointer' }} onClick={handleReset}>
                            Réinitialiser
                        </Button>

                        <Button className='btnSubmit' type="submit" variant="contained" sx={{ width: '200px' }} onClick={handleSubmit}>
                            Enregistrer
                        </Button>

                    </div>

                </form>
            </Paper>

        </div>
    )
}
