import React, { useEffect, useState } from "react";
import "./MuMiseEnLigneList"
import GridTable from "../data-grid/GridTable";
import SearchBar from "../../../../components/search-bar/SearchBar";
import { getMiseEnLigneData } from "../httpQuerys"
import "moment/locale/fr";
import moment from "moment";

export default function MuMiseEnLigneList({allowedManagement}) {
  const currentCity = JSON.parse(localStorage.getItem("currentMunicipality"));

  const [data, setData] = useState([]);

  useEffect(() => {
    getMiseEnLigneData().then((data) => setData(data));
  }, []);

  const [query, setQuery] = useState("");
  const tabKeys = ["idTracking", "typeMaj", "demandeur", "description", "statusName", "dateMaj", "dateMel"];

  // Fonction de filtrage de la liste des mises en ligne, elle prend en paramètre les données à filtrer et renvoie les résultats
  // correspondant à la requête de recherche 'query'.
  // Elle normalise la chaîne de recherche pour ignorer les accents et majuscules/minuscules, et applique le filtrage sur les clés
  // spécifiées dans 'tabKeys'.
  // Pour les champs contenant des dates ('dateMaj' ou 'dateMel'), les dates sont formatées en texte lisible (ex. 'lun 01 jan 2024 12:00:00') afin de permettre
  // une recherche par jour, mois ou date complète.
  // Pour les autres champs, une recherche simple est effectuée en convertissant les valeurs en chaîne de caractères normalisées (sans accents, en minuscules),
  // et en vérifiant si elles contiennent la requête de recherche.

  const showList = (data) => {
    const normalizedQuery = query
      .normalize("NFD") // Supprimer les accents
      .replace(/[\u0300-\u036f]/g, "")// RegEx pour supprimer les signes diacritiques (accents graves, aigus, circonflexes, etc.) des caractères. les rechercher et et les remplacer par une chaîne vide ("")
      .toLowerCase();   // Convertir en minuscules

    return data.filter((row) => {
      return tabKeys.some((key) => {
        if (key === "dateMaj" || key === "dateMel") {
          // Formatage de la date pour permettre la recherche sur le jour, mois ou date complète
          const formattedDate = moment.utc(row[key])
            // .format("ddd DD MMM YYYY HH:mm:ss")
            .format("ddd DD MMM YYYY HH:mm")
            .toLowerCase();

          // Vérifier si la date formatée inclut la requête normalisée
          return formattedDate.includes(normalizedQuery);
        }

        // Recherche standard sur les autres champs en supprimant les accents et en mettant en minuscules
        return row[key]
          ?.toString()  // Convertir la valeur en chaîne de caractères
          .normalize("NFD") // Supprimer les accents
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()  // Mettre en minuscules
          .includes(normalizedQuery); // Vérifier la correspondance avec la requête
      });
    });
  };

  return (
    currentCity && (
      <div className="MuMiseEnLigneList">
        <SearchBar query={query} setQuery={setQuery} />
        <GridTable data={showList(data)} allowedManagement={allowedManagement}/>
      </div>
    )
  );
}
