import React, { useState } from 'react'
import './LandingPage.scss'
import { getLandingPageData } from './httpQuerys'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LandingCard from './LandingCard';
import SearchBar from '../../components/search-bar/SearchBar';
import { useQuery } from 'react-query';


export default function LandingPage() {

  const {gde} = JSON.parse(localStorage.getItem('portailclient'));
  const currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));
  const [query, setQuery] = useState('');

  // const [data, setData] = useState([]);
  // useEffect(() => {
  //   getLandingPageData().then(data => setData(data));
  // }, []);

  const { data, isLoading, isError } = useQuery('LPCities', () => getLandingPageData(gde, currentUserInfo));
  if (isLoading) {
    return <div>Chargement en cours...</div>;
  }
  if (isError) {
    return <div>Une erreur s'est produite lors de la récupération des données.</div>;
  }

  // a revoir comme démarche dans les deux sens
  // const removeAccents = (str) => {
  //   return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  // };
  // const visibleProducts = PRODUCTS.filter((product) => (
  //   (!search || removeAccents(product.name).toLocaleLowerCase().includes(removeAccents(search).toLocaleLowerCase()))
  // ));

  const tabKeys = ['cityCode', 'cityName', 'description', 'mrcCode', 'mrcName', "mrcRealName"];
  const showList = (data) => {
    const normalizedQuery = query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    return data.filter((row) =>
      tabKeys.some((key) =>
        row[key]?.toString()
          .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(normalizedQuery)
      )
    );
  };

  return (
    data &&
    <Box className='LandingPage'>
      <SearchBar query={query} setQuery={setQuery} />

      <Box
        className="main-container"
        sx={{
          width: "100%",
          flexGrow: 1,
          backgroundColor: 'transparent'
        }}
      >
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          {
            showList(data).map((row, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
                <LandingCard row={row} />
              </Grid>
            ))
          }
        </Grid>
      </Box>
    </Box>
  )
}