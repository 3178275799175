import DOMPurify from 'dompurify';
// import { checkUserNameUniqueness } from './httpQuerys'

// validation d'un champ de type chaine de caractères
export const validateField = async (value, fieldName, setFieldError) => {
    const sanitizedValue = DOMPurify.sanitize(value);

    if (!sanitizedValue.trim()) {
        setFieldError(`Le champ ${fieldName} est obligatoire.`);
        return false;
    }

    if (!/^[a-zA-ZÀ-ÿ\s'-]+$/.test(sanitizedValue)) {
        setFieldError(`Le champ ${fieldName} doit contenir uniquement des lettres.`);
        return false;
    }

    // Validation réussie
    setFieldError('');
    return true;
};

// Validation d'un champ de type chaîne alphanumérique
export const validateAlphanumericField = async (value, fieldName, setFieldError) => {
    const sanitizedValue = DOMPurify.sanitize(value);

    // Vérification si le champ est vide
    if (!sanitizedValue.trim()) {
        setFieldError(`Le champ ${fieldName} est obligatoire.`);
        return false;
    }

    // Définition des caractères autorisés
    const regex = /^[\p{L}\p{N}\s'’\-.,;:«»()!?_]+$/u;

    // Validation des caractères
    if (!regex.test(sanitizedValue)) {
        setFieldError(`Le champ ${fieldName} contient des caractères non autorisés.`);
        return false;
    }

    // Validation réussie
    setFieldError('');
    return true;
};




export const capitalizeFirstLetterOfWords = (str) => {
    //seulement la premiere lettre en majuscule
    return str.charAt(0).toUpperCase() + str.slice(1);

    //premiere lettre de chaque mot en majuscule
    // return str.replace(/\b\w/g, (char) => char.toUpperCase());
};


const Utils = {
    validateField,
    capitalizeFirstLetterOfWords,
}
export default Utils;