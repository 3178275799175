import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { addNewMunicipalityUser, sendEmailToNewUser } from '../httpQuerys';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  Typography,
  // CircularProgress,
  LinearProgress,
  Box
} from '@mui/material';

// export default function DialogConfirm({ userToAdd, errors, passwordStrength, open, setOpen, isFormValidated }) {

export default function DialogConfirm(props) {
  const {
    userToAdd,
    prenomError,
    nomError,
    userNameError,
    passwordError,
    emailError,
    telephoneError,
    profileError,
    profileServicesError,
    defaultServiceError,
    open,
    setOpen,
    isFormValidated,
  } = props;

  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(open);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setOpenDialog(false);
    setOpenSecondDialog(false);
    navigate(`/dashboard/municipalites/${userToAdd?.currentCity?.cityCode}/mu-utilisateurs`);
  };

  const handleCancel = () => {
    setOpen(false);
    setOpenDialog(false);
    setOpenSecondDialog(false);
  };

  // // Vérifie si la touche pressée est la touche "Entrée" ou la touche "Échap"
  // const handleKeyPress = (e) => {
  //   if (e.key === "Enter") handleConfirm();
  //   if (e.key === "Escape") handleClose();
  // };

  const handleConfirm = async () => {
    if (!isFormValidated) return;

    setLoading(true);

    try {
      const result = await addNewMunicipalityUser(userToAdd);
      if (result?.success) {
        if (sendEmail) {
          await sendEmailToNewUser(userToAdd);
          setOpenSecondDialog(true);
        } else {
          handleClose();
        }
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'utilisateur :", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Dialogue de confirmation d'envoi d'email */}
      <Dialog open={openSecondDialog} onClose={handleClose}>
        <DialogTitle>Confirmation d'envoi de courriel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Un courriel a été envoyé à l'adresse {userToAdd?.email}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">Fermer</Button>
        </DialogActions>
      </Dialog>

      {/* Dialogue de validation du formulaire */}
      <Dialog open={openDialog} onClose={handleCancel}>
        <DialogTitle>{isFormValidated ? "Requête d'ajout d'un utilisateur" : "Erreur dans le formulaire"}</DialogTitle>
        <DialogContent>
          {isFormValidated ? (
            <>
              <DialogContentText>
                Vous êtes sur le point d'ajouter <strong>{userToAdd?.fullName}</strong>
                {userToAdd?.currentCity?.cityName && ` pour la ville de ${userToAdd.currentCity.cityName}`}.<br />
                Veuillez confirmer cette requête !
              </DialogContentText>
              <DialogContentText>
                Envoyer un courriel avec l'identifiant et le mot de passe.
                <Checkbox checked={sendEmail} onChange={() => setSendEmail(!sendEmail)} />
              </DialogContentText>
              {/* {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <CircularProgress />
                </Box>
              )} */}

              {loading &&
                <>
                  <LinearProgress sx={{ mt: 2 }} />
                  <Box sx={{ color: '#999', fontSize: '0.875rem' }}>
                    Requête en cours, veuillez patienter...
                  </Box>
                </>
              }
            </>
          ) : (
            <>
              <DialogContentText>Veuillez vérifier les erreurs suivantes :</DialogContentText>
              <ul>
                {[
                  prenomError,
                  nomError,
                  userNameError,
                  passwordError,
                  emailError,
                  telephoneError,
                  profileError,
                  profileServicesError,
                  defaultServiceError,
                ]
                  .filter(Boolean)  // Supprime les valeurs nulles ou undefined
                  .map((error, index) => (
                    <Typography
                      key={index}
                      color="error"
                      component="li"
                      sx={{ listStyleType: "'- '", marginLeft: '1em' }}
                    >
                      {error}
                    </Typography>
                  ))}
              </ul>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="error" disabled={loading}>Annuler</Button>
          {isFormValidated && (
            <Button onClick={handleConfirm} color="primary" disabled={loading}>
              Confirmer
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
