import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  addMunicipalityToUser,
} from '../httpQuerys';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  LinearProgress,
  Box
} from '@mui/material';

export default function DialogConfirm(props) {
  const {
    userToAdd,
    profileError,
    profileServicesError,
    defaultServiceError,
    open,
    setOpen,
    isFormValidated,
  } = props;

  // console.log({ userToAdd });

  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(open);
  const [loading, setLoading] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setOpenDialog(false);
    setShowConfirmationDialog(false); // Close confirmation dialog
    navigate(`/dashboard/municipalites/${userToAdd?.currentCity?.cityCode}/mu-utilisateurs`);
  };

  const handleCancel = () => {
    setOpen(false);
    setOpenDialog(false);
    setShowConfirmationDialog(false); // Close confirmation dialog
  };

  const handleConfirm = async () => {
    if (!isFormValidated) return;
    setLoading(true);

    try {
      const result = await addMunicipalityToUser(userToAdd);
      if (result?.success) {
        setShowConfirmationDialog(true); // Show the success confirmation
      } else {
        handleClose();
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'utilisateur :", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    showConfirmationDialog ? (
      <Dialog open={showConfirmationDialog} onClose={handleClose}>
        <DialogTitle>Confirmation d'ajout d'une utilisateur</DialogTitle>
        <DialogContent>
          <DialogContentText>
            L'utilisateur <strong><em>{userToAdd?.fullName}</em></strong> a été ajouté
            {userToAdd.currentCity.article === 'La' ? ' à la ' : userToAdd?.currentCity?.article === 'Le' ? ' au ' : ' aux '}
            <strong><em>{userToAdd?.currentCity?.description} de {userToAdd?.currentCity?.cityName}</em></strong>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">Fermer</Button>
        </DialogActions>
      </Dialog>
    ) : (
      isFormValidated ? (
        <Dialog open={openDialog} onClose={handleClose}>
          <DialogTitle>Confirmation d'ajout d'une utilisateur</DialogTitle>
          <DialogContent>
            <DialogContentText>
              L'utilisateur <strong><em>{userToAdd?.fullName}</em></strong> sera ajouté
              {userToAdd.currentCity.article === 'La' ? ' à la ' : userToAdd?.currentCity?.article === 'Le' ? ' au ' : ' aux '}
              <strong><em>{userToAdd?.currentCity?.description} de {userToAdd?.currentCity?.cityName}</em></strong>.
              <br /><br />
              Êtes-vous sûr de vouloir continuer ?
            </DialogContentText>
            {loading &&
              <>
                <LinearProgress sx={{ mt: 2 }} />
                <Box sx={{ color: '#999', fontSize: '0.875rem' }}>
                  Requête en cours, veuillez patienter...
                </Box>
              </>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="error" disabled={loading}>Annuler</Button>
            <Button onClick={handleConfirm} color="primary" disabled={loading}>Confirmer</Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog open={open} onClose={handleCancel}>
          <DialogTitle>Erreur dans le formulaire</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Un ou plusieurs champs du formulaire sont manquants ou erronés.
              Veuillez vérifier vos données !
            </DialogContentText>
            <ul>
              {[profileError, profileServicesError, defaultServiceError].some(Boolean) && (
                <ul>
                  {[profileError, profileServicesError, defaultServiceError]
                    .filter(Boolean)
                    .map((error, index) => (
                      <Typography key={index} color="error" component="li" sx={{ listStyleType: "'- '", marginLeft: '1em' }}>
                        {error}
                      </Typography>
                    ))}
                </ul>
              )}
            </ul>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="error">Retour au Formulaire</Button>
          </DialogActions>
        </Dialog>
      )
    )
  );
}
