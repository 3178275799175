import dataService from "../../../httpService";

// Fonction pour obtenir les données des images d'une municipalité
async function getImagesDetails(cityCode) {
    try {
        const response = await dataService.get('/user/municipalitie/images', {
            params: {
                cityCode,
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour mettre à jour la configuration des images
async function updateMuImagesDetails(cityCode, fileNameLogo, newFileNameLogo, fileNameFondEcran) {
    try {
        const response = await dataService.put('/user/municipalitie/images/update', {
            cityCode,
            fileNameLogo,
            newFileNameLogo,
            fileNameFondEcran
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour envoyer un e-mail de demande pour mettre à jour la configuration des images
async function sendEmailToUpdateMuImages(currentUserInfo, cityCode, cityName, text, fileName, imageFile) {
    try {
        const formData = new FormData();
        // formData.append('currentUserInfo', JSON.stringify(currentUserInfo));  // Convert object to JSON string
        formData.append('idUser', currentUserInfo.idUser);
        formData.append('fullName', currentUserInfo.fullName);
        formData.append('email', currentUserInfo.email);
        formData.append('idGroup', currentUserInfo.idGroup);
        formData.append('cityCode', cityCode);
        formData.append('cityName', cityName);
        formData.append('text', text);
        formData.append('fileName', fileName);
        formData.append('image', imageFile);

        // for (let [key, value] of formData.entries()) {
        //     console.log(key, value);
        // }

        const response = await dataService.post('/user/municipalitie/images/send-email-to-update-images', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour vérifier le mot de passe de l'utilisateur principal
async function checkUserPassword(password) {
    try {
        const response = await dataService.get('/user/check-password', {
            params: {
                password
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste des tyes de messages d'avertissement
async function getMessageTypeList() {
    try {
        const response = await dataService.get('/messages/message-type-list');
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste des détails des messages d'avertissement selon la municipalité cityCode
async function getMessagesListByType(cityCode, idtypeMessage) {
    try {
        const response = await dataService.get('/messages/messages-list-by-type', {
            params: {
                cityCode,
                idtypeMessage
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour ajouter un nouveau pop-up message à la municipalité
async function addMuMessage(messageToSave) {
    try {
        const response = await dataService.post('/messages/add-new-message', messageToSave)
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour mettre à jour un pop-up message
async function updateMuMessage(messageToUpdate) {
    try {
        const response = await dataService.put('/messages/update-message', messageToUpdate)
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour supprimer un message (row) parmis la liste de sélections (rows)
async function deleteMessage(row) {
    try {
        const response = await dataService.delete('/messages/delete-message', {
            params: {
                // cityCode: row.cityCode,
                idCityMessage: row.idCityMessage,
                idMessage: row.idMessage,
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir tous les messages avec leurs détails pour un utilisateur donné
async function getAllMessagesData(idUser) {
    try {
        const response = await dataService.get('/messages/get-all-messages', {
            params: {
                idUser
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir les détails d'un messages par son idCityMessage
async function getMessageDetailsById(idCityMessage) {
    try {
        const response = await dataService.get('/messages/get-message-by-id', {
            params: {
                idCityMessage
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste des municipalités de l'utilisateur à éditer
async function getUserMunicipalitesList(idUser) {
    try {
        const response = await dataService.get('/user/all-users/user/to-edit/municipalites', {
            params: {
                idUser
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour supprimer un message (row) parmis la liste de sélections (rows)
async function deleteSelectedMessage(row) {
    try {
        const response = await dataService.delete('/messages/delete-message', {
            params: {
                idCityMessage: row.idCityMessage,
                idMessage: row.idMessage,
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste de tous les profils
async function getServices(cityCode) {
    try {
        const response = await dataService.get('/user/municipality/services', {
            params: {
                cityCode
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// liste des message pop-up type 3 avec ses détails pour une municipalité donnée
async function getPopUpMessagesList(cityCode) {
    try {
        const response = await dataService.get('/messages/pop-up-messages-list', {
            params: {
                cityCode: cityCode
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste des tyes de messages d'avertissement
async function getTypeMessageList() {
    try {
        const response = await dataService.get('/messages/message-type-list');
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste des détails des messages d'avertissement selon la municipalité cityCode
async function getMessagesList(cityCode, idtypeMessage) {
    try {
        const response = await dataService.get('/messages/messages-list', {
            params: {
                cityCode,
                idtypeMessage
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste des messages d'avertissement de la municipalité
async function getCityMessagesList(idCityMessage) {
    try {
        const response = await dataService.get('/messages/city-messages-list', {
            params: {
                idCityMessage,
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour supprimer un pop-up message (row) parmis la liste de sélections (rows)
async function deleteSelectedPopUpMessage(row) {
    try {
        const response = await dataService.delete('/messages/delete-message', {
            params: {
                idCityMessage: row.idCityMessage,
                idMessage: row.idMessage
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Exporter les fonctions
export {
    getImagesDetails,
    updateMuImagesDetails,
    sendEmailToUpdateMuImages,
    checkUserPassword,
    getMessageTypeList,
    getMessagesListByType,
    addMuMessage,
    updateMuMessage,
    deleteMessage,
    getAllMessagesData,
    getMessageDetailsById,
    getUserMunicipalitesList,
    getServices,
    deleteSelectedMessage,
    getPopUpMessagesList,
    getTypeMessageList,
    getMessagesList,
    getCityMessagesList,
    deleteSelectedPopUpMessage
};

