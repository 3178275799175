import axios from 'axios';

// Créer une instance Axios avec la baseURL définie depuis les variables d'environnement
const httpService = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

// Fonction pour vérifier si un token JWT est expiré
function isTokenExpired(token) {
    if (!token) return true; // Si aucun token n'est fourni, considérer comme expiré

    try {
        // Décodage sécurisé du payload du JWT (Base64URL -> Base64)
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const decodedToken = JSON.parse(atob(base64));

        return decodedToken.exp < Date.now() / 1000; // Comparaison avec l'heure actuelle
    } catch (error) {
        console.error("Erreur lors de la vérification du token:", error);
        return true; // Considérer le token comme expiré en cas d'erreur
    }
}

// Intercepteur de requêtes pour ajouter automatiquement le token d'authentification
httpService.interceptors.request.use(async (config) => {
    const { url } = config;

    // Éviter d'ajouter un token aux requêtes d'authentification
    if (!url.startsWith('/auth')) {
        const portailclient = JSON.parse(localStorage.getItem('portailclient'));

        if (portailclient) {
            let { access_token, refresh_token } = portailclient;

            // Vérifier si le token d'accès est expiré
            if (isTokenExpired(access_token)) {
                // Vérifier si le refresh token est aussi expiré
                if (isTokenExpired(refresh_token)) {
                    console.log('Refresh token expiré. Redirection vers la page de connexion...');
                    localStorage.removeItem('portailclient'); // Supprime uniquement l'élément concerné
                    localStorage.clear();
                    // window.location.href = '/';
                    window.location.replace('/'); // Redirige proprement
                    return Promise.reject(new Error("Refresh token expiré"));
                }

                try {
                    // Tenter d'obtenir un nouveau token d'accès avec le refresh token
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/refreshtoken`, { refresh_token });
                    // Mettre à jour les clés access_token et refresh_token de l'objet
                    // portailclient.access_token
                    // portailclient.refresh_token
                    access_token = response.data.access_token;
                    refresh_token = response.data.refresh_token;

                    // Mettre à jour le localStorage avec les nouveaux tokens
                    localStorage.setItem('portailclient', JSON.stringify({ access_token, refresh_token }));

                } catch (error) {
                    console.error("Erreur lors du rafraîchissement du token:", error);
                    localStorage.removeItem('portailclient');
                    localStorage.clear();
                    // window.location.href = '/';
                    window.location.replace('/');
                    return Promise.reject(error);
                }
            }

            // Ajouter le token d'accès valide dans l'en-tête de la requête
            config.headers.Authorization = `Bearer ${access_token}`;
        } else {
            console.log('Aucun identifiant trouvé. Redirection vers la connexion.');
            localStorage.removeItem('portailclient');
            localStorage.clear();
            // window.location.href = '/';
            window.location.replace('/');
            return Promise.reject(new Error("Aucun identifiant trouvé"));
        }
    }
    return config;
});

// Gestionnaire global des erreurs Axios
function handleError(error) {
    console.error("Erreur Axios:", error.response?.data || error.message);
    return Promise.reject(error);
}

// Service de gestion des requêtes HTTP avec interception des erreurs
const dataService = {
    get: (url, config) => httpService.get(url, config).catch(handleError),
    post: (url, data, config) => httpService.post(url, data, config).catch(handleError),
    put: (url, data, config) => httpService.put(url, data, config).catch(handleError),
    delete: (url, config) => httpService.delete(url, config).catch(handleError),
    patch: (url, data, config) => httpService.patch(url, data, config).catch(handleError),
};

export default dataService;
