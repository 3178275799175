import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { updateMuUserInfoAndServies } from '../httpQuerys';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  LinearProgress, Box
} from '@mui/material';
import _ from 'lodash';

export default function DialogConfirm({ userToEdit, lastUserValues, open, setOpen, isFormValidated }) {
  // const { cityCode } = JSON.parse(localStorage.getItem('currentMunicipality'));
  // console.log({ userToEdit })
  // console.log({ lastUserValues })
  // if (!_.isEqual(userToEdit, lastUserValues)) {
  //   console.log('Objects are different');
  // } else {
  //   console.log('Objects are the same');
  // }
  // // test et affiche les elements presentant une differences
  // const getObjectDifferences = (obj1, obj2) => {
  //   return Object.keys(obj1).reduce((diff, key) => {
  //     if (obj1[key] !== obj2[key]) {
  //       diff[key] = { oldValue: obj2[key], newValue: obj1[key] };
  //     }
  //     return diff;
  //   }, {});
  // };
  // const differences = getObjectDifferences(userToEdit, lastUserValues);
  // console.log(differences);

  const navigate = useNavigate();
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [messageConfirmationDialog, setMessageConfirmationDialog] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setOpenSecondDialog(false);
      setMessageConfirmationDialog('');
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setOpenSecondDialog(false);
    if (openSecondDialog) {
      navigate(`/dashboard/municipalites/${userToEdit.cityCode}/mu-utilisateurs`);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') handleConfirm();
    if (e.key === 'Escape') handleClose();
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const userUpdated = await updateMuUserInfoAndServies(userToEdit);
      setMessageConfirmationDialog(userUpdated ? 'Mise à jour effectuée avec succès.' : 'Erreur lors de la mise à jour !');
      setOpenSecondDialog(true);
    } catch (error) {
      setMessageConfirmationDialog('Erreur de la mise à jour !');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (!isFormValidated) {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Erreur dans le formulaire</DialogTitle>
        <DialogContent>
          <DialogContentText>Veuillez vérifier vos données.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Retour</Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (_.isEqual(userToEdit, lastUserValues)) {
    return (
      <Dialog open={open} onClose={handleClose} onKeyDown={handleKeyDown}>
        <DialogTitle>Pas de mise à jour</DialogTitle>
        <DialogContent>
          <DialogContentText>Aucune modification n'a été apportée.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={handleClose} onKeyDown={handleKeyDown}>
      <DialogTitle>{openSecondDialog ? 'Confirmation' : 'Enregistrer les modifications'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {openSecondDialog
            ? messageConfirmationDialog
            : `Confirmez-vous la modification de ${userToEdit.fullName} ?`}
        </DialogContentText>
        {loading && (
          <>
            <LinearProgress sx={{ mt: 2 }} />
            <Box sx={{ color: '#999', fontSize: '0.875rem' }}>
              Requête en cours, veuillez patienter...
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error" disabled={loading}>
          {openSecondDialog ? 'Fermer' : 'Annuler'}
        </Button>
        {!openSecondDialog && (
          <Button onClick={handleConfirm} color="primary" disabled={loading}>
            Confirmer
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
