import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import './Signin.scss'
import FormInput from '../../components/form/FormInput'
import Logo from '../../assets/images/logo512.png'
import { useAuth } from '../../contexts/AuthContext'
import { Link } from 'react-router-dom'

export default function Signin() {
    const navigate = useNavigate();
    const submitBtnRef = useRef();
    const { currentUser, login } = useAuth();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [formValues, setFormValues] = useState({
        username: '',
        password: '',
    })

    // useEffect(() => {
    //     currentUser && navigate('/dashboard')
    // }, [currentUser, navigate]);

    useEffect(() => {
        if (currentUser) {
            navigate('/dashboard', { replace: true });
        }
    }, [currentUser, navigate]);


    const inputs = [
        {
            id: 'login-username',
            name: 'username',
            type: 'text',
            // ref: {usernameRef},
            autoComplete: 'login-username',
            label: 'Identifiant de l\'utilisateur ',
            placeholder: 'Saisir votre identifiant',
            errorMessage: 'Ce champs est obligatoire pour vous identifier ! ',
            required: true,
        },

        {
            id: 'login-password',
            name: 'password',
            type: 'password',
            // ref: {passwordRef},
            autoComplete: 'login-password',
            // autoComplete: 'off',
            label: 'Mot de passe ',
            placeholder: 'Saisir votre mot de passe ',
            errorMessage: 'Identifiant et/ou mot de passe incorrect(s)',
            required: true,
        },
    ]

    // const handleChange = (e) => {
    //     setError(false)
    //     const submitButton = submitBtnRef.current;
    //     if (submitButton) {
    //         submitButton.classList.remove('nope');
    //     }
    //     setFormValues({ ...formValues, [e.target.name]: e.target.value })
    // }

    const handleChange = (e) => {
        if (error) setError(false);  // Seulement si une erreur est présente
        submitBtnRef.current?.classList.remove('nope');
        setFormValues(prev => ({ ...prev, [e.target.name]: e.target.value }));
    };

    async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        setErrorMessage(""); // Réinitialiser le message d'erreur

        try {
            const result = await login(formValues.username, formValues.password);

            if (!result.success) {
                setError(true);
                setErrorMessage(result.message); // Afficher le message d'erreur approprié
                submitBtnRef.current?.classList.add('nope');
            }
        } catch (error) {
            console.error("Erreur lors de la connexion :", error);
            setError(true);
            setErrorMessage("Une erreur inconnue s'est produite.");
        }

        setLoading(false);
    }


    return (
        !currentUser &&
        <div className='Signin'>
            <form onSubmit={handleSubmit}>

                <div className="loginHeader">
                    <div className="loginHeader__logo">
                        <img src={Logo} alt='logo Azimut' />
                    </div>
                    <h1 className="loginHeader__title">PORTAIL CLIENT</h1>
                </div>

                {inputs.map((input) => {
                    return (
                        <FormInput
                            className="inputPW"
                            key={input.id}
                            {...input}
                            value={formValues[input.name]}
                            handleChange={handleChange}
                        />
                    )
                })}

                <p aria-live="assertive" style={{ color: "red" }}>
                    {errorMessage}
                </p>
                <br />

                <button
                    ref={submitBtnRef}
                    disabled={loading}
                >
                    {!error ? 'SE CONNECTER' : 'Erreur de Connexion'}
                </button>

                <div className="loginFooter">
                    <Link
                        className="forgotLink"
                        to={`/forgot-pw`}
                        style={{ textDecoration: "none", cursor: "pointer" }}
                    >
                        Mot de passe oublié
                    </Link>
                </div>
            </form>
        </div>
    )
}


