import React, { useRef, useState, useEffect } from 'react';
import './Card.scss';
import { Box, Typography, List, ListItem, IconButton } from '@mui/material';
import FilePresentRoundedIcon from '@mui/icons-material/FilePresentRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import dayjs from 'dayjs';

// Fonction pour obtenir les types de fichiers acceptés
const getFileAcceptType = (typeMaj, constSubTypeMAJ, idSubDataTypeMaj) => {

    let fileTypes = '';
    let fileTypesMessage = '';

    // Détermination des types de fichiers acceptés en fonction du type de mise à jour
    switch (typeMaj) {
        case 'Rôle':
            fileTypes = '.mdb, .xml, .zip, .rar';
            fileTypesMessage = 'Un seul fichier est autorisé avec une extension REFXXXXX.mdb, RFXXXXX.mdb, .xml, .zip, ou .rar';
            break;
        case 'Matrice':
            if (idSubDataTypeMaj === constSubTypeMAJ.GOmatrice) {
                fileTypes = '.mdb, .zip, .rar';
                fileTypesMessage = 'Type(s) de fichiers autorisés : GOmatriceXXXXX.mdb, GOmatriceHabXXXXX.mdb, GOmatriceHisXXXXX.mdb, .zip, .rar';
            } else if (idSubDataTypeMaj === constSubTypeMAJ.GML) {
                fileTypes = '.zip, .rar, .gml'; // Restriction sur les types de fichier
                fileTypesMessage = 'Type(s) de fichiers autorisés : .zip, .rar .gml';
            } else if (idSubDataTypeMaj === constSubTypeMAJ.Autre) {
                fileTypes = '.txt, .pdf, .mdb, .xml, .zip, .rar'; // Aucune restriction sur les types de fichier
                fileTypesMessage = 'Tous les types de fichiers sont acceptés, les fichiers .zip sont suggérés';
            }
            break;
        case 'Zonage':
            if (idSubDataTypeMaj === constSubTypeMAJ.Zone) {
                fileTypes = '.zip, .rar';
                fileTypesMessage = 'Type(s) de fichiers autorisés : .zip, .rar';
            } else if (idSubDataTypeMaj === constSubTypeMAJ.Grille) {
                fileTypes = '.pdf,.zip, .rar';
                fileTypesMessage = 'Type(s) de fichiers autorisés : .pdf, .zip, .rar';
            } else if (idSubDataTypeMaj === constSubTypeMAJ.Reglement) {
                fileTypes = '.pdf';
                fileTypesMessage = 'Un seul fichier est autorisé avec une extension : .pdf';
            } else if (idSubDataTypeMaj === constSubTypeMAJ.PIIA) {
                fileTypes = '.pdf';
                fileTypesMessage = 'Type(s) de fichiers autorisés : .pdf';
            } else if (idSubDataTypeMaj === constSubTypeMAJ.Guidedinterpretation) {
                fileTypes = '.pdf';
                fileTypesMessage = 'Un seul fichier est autorisé avec une extension : .pdf';
            }
            break;
        case 'Infrastructure':
            if (idSubDataTypeMaj === constSubTypeMAJ.GOinfra) {
                fileTypesMessage = 'C’est une requête de mise en ligne dans GOnet.';
            } else if (idSubDataTypeMaj === -constSubTypeMAJ.GOinfra) {
                fileTypes = '.zip, .rar';
                fileTypesMessage = 'Il s’agit de la transmission de nouvelles données. Type(s) de fichiers autorisés : .zip, .rar';
            }
            break;
        // case 'Orthophoto':
        // case 'Photos':
        // case 'Croquis':
        //     // fileTypes = '...';
        //     fileTypesMessage = 'Les photos et croquis sont trop volumineux pour être transférés à partir de cette page. Indiquez où les déposer pour récupération par le soutien technique d\'AZIMUT.';
        //     break;
        case 'Autre':
            fileTypes = '.shp,.gdb,.mxd,.gpkg, .zip, .rar';
            fileTypesMessage = 'Type(s) de fichiers autorisés : .shp, .gdb, .mxd, .gpkg, .zip, .rar';
            break;
        case 'Transactions immobilières':
            fileTypes = '.xlsx, .xml, .zip, .rar';
            fileTypesMessage = 'Type(s) de fichiers autorisés : .xlsx, .xml, .zip, .rar';
            break;
        default:
            // fileTypes = ''; // Par défaut, aucun fichier accepté
            fileTypesMessage = '';
    }

    return { fileTypes, fileTypesMessage };
};


export default function Card({ formDataMEL, setFormDataMEL, formErrors, setFormErrors, typeMaj, constSubTypeMAJ, idSubDataTypeMaj, fichiers, setFichiers }) {

    const refInput = useRef(null);
    const [inputKey, setInputKey] = useState(Date.now());
    const [messageTypeFiles, setMessageTypeFiles] = useState('');
    const [acceptedFileTypes, setAcceptedFileTypes] = useState('');

    // Mettre à jour les types de fichiers autorisés lorsque `typeMaj` change
    useEffect(() => {
        const { fileTypes, fileTypesMessage } = getFileAcceptType(typeMaj, constSubTypeMAJ, idSubDataTypeMaj);
        setMessageTypeFiles(fileTypesMessage);
        setAcceptedFileTypes(fileTypes);
    }, [typeMaj, idSubDataTypeMaj, constSubTypeMAJ]);


    //================================================================

    const handleUploadClick = () => {
        refInput.current.click();
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    // Fonction qui encapsule la logique pour filtrer et valider les fichiers en fonction des conditions définis
    const processFiles = (selectedFiles, typeMaj, idSubDataTypeMaj, acceptedFileTypes) => {

        // Filtrer les doublons (éviter d'ajouter des fichiers déjà présents)
        const filteredFiles = selectedFiles.filter(newFile => {
            return !fichiers.some(existingFile =>
                (existingFile.nomFichier === newFile.name) || // Comparaison avec les anciens fichiers
                (existingFile.name === newFile.name && existingFile.size === newFile.size) // Comparaison avec les nouveaux fichiers
            );
        });

        let validFiles = [];

        // Vérification spécifique pour 'Rôle'
        // if (typeMaj === 'Rôle') {
        //     validFiles = filteredFiles.filter(file => {
        //         const fileExtension = file.name.split('.').pop();
        //         return acceptedFileTypes.split(',').some(type => type.trim().includes(fileExtension));
        //     });

        //     // Limiter à un seul fichier valide
        //     if (validFiles.length > 0) {
        //         return [validFiles[0]]; // Accepter uniquement le premier fichier valide
        //     }
        // }
        if (typeMaj === 'Rôle') {
            validFiles = filteredFiles.filter(file => {
                const fileExtension = file.name.split('.').pop().toLowerCase();
                const fileName = file.name;
                const isValidZip = fileExtension === 'zip';
                const isValidRar = fileExtension === 'rar';
                const isValidMdb = fileExtension === 'mdb' && (
                    /^REF\d{5}\.mdb$/.test(fileName) ||
                    /^RF\d{5}\.mdb$/.test(fileName)
                );
                return isValidZip || isValidMdb || isValidRar;
            });
            // Limiter à un seul fichier valide
            if (validFiles.length > 0) {
                return [validFiles[0]]; // Accepter uniquement le premier fichier valide
            }
        }
        else
            // Vérification spécifique pour le type 'Matrice' et sous-type 'GOmatrice'
            if (typeMaj === 'Matrice' && (idSubDataTypeMaj === constSubTypeMAJ.GOmatrice)) {
                validFiles = filteredFiles.filter(file => {
                    const fileExtension = file.name.split('.').pop().toLowerCase();
                    const fileName = file.name;

                    const isValidZip = fileExtension === 'zip';
                    const isValidRar = fileExtension === 'rar';
                    const isValidMdb = fileExtension === 'mdb' && (
                        /^GOmatrice\d{5}\.mdb$/.test(fileName) ||
                        /^GOmatriceHab\d{5}\.mdb$/.test(fileName) ||
                        /^GOmatriceHis\d{5}\.mdb$/.test(fileName)
                    );
                    return isValidZip || isValidMdb || isValidRar;
                });

                // Vérification spécifique pour le type 'Zonage' et sous-types 'Règlements' ou 'Guide d’interprétation'
            } else if (typeMaj === 'Zonage' &&
                (idSubDataTypeMaj === constSubTypeMAJ.Reglement ||
                    idSubDataTypeMaj === constSubTypeMAJ.Guidedinterpretation)) {
                validFiles = filteredFiles.filter(file => {
                    const fileExtension = file.name.split('.').pop().toLowerCase();
                    return acceptedFileTypes.split(',').some(type => type.trim().includes(fileExtension));
                });

                if (validFiles.length > 0) {
                    return [validFiles[0]]; // Accepter uniquement le premier fichier valide
                }
            }
            // Cas général : vérifier uniquement les extensions acceptées
            else {
                validFiles = filteredFiles.filter(file => {
                    const fileExtension = file.name.split('.').pop().toLowerCase();
                    return acceptedFileTypes.split(',').some(type => type.trim().includes(fileExtension));
                });
            }

        return validFiles; // Retourne les fichiers valides
    };

    // Gèrer l'événement de drag-and-drop pour ajouter des fichiers.
    const handleDrop = (event) => {
        event.preventDefault();// Empêche le comportement par défaut du navigateur comme l'ouvrerture des fichiers

        // Récupère les fichiers déposés sous forme de tableau
        const droppedFiles = Array.from(event.dataTransfer.files);

        // Change la clé d'entrée pour forcer un re-render de l'élément input
        setInputKey(Date.now());

        // Réinitialise les erreurs de formulaire pour les fichiers
        setFormErrors((prevState) => ({ ...prevState, fichiers: '' }));

        // Filtre et valide les fichiers sélectionnés
        const validFiles = processFiles(droppedFiles, typeMaj, idSubDataTypeMaj, acceptedFileTypes);

        // Mettre à jour la liste des fichiers avec les fichiers valides
        if (validFiles.length > 0) {
            setFichiers([...fichiers, ...validFiles]);
        }
    };

    // Gèrer l'ajout de fichiers via l'input de type file.
    const handleChangeFile = (e) => {
        e.preventDefault();
        // Récupère les fichiers sélectionnés sous forme de tableau
        const selectedFiles = Array.from(e.target.files);

        // Change la clé d'entrée pour forcer un re-render de l'élément input
        setInputKey(Date.now());

        // Réinitialise les erreurs de formulaire pour les fichiers
        setFormErrors((prevState) => ({ ...prevState, fichiers: '' }));

        // Filtre et valide les fichiers sélectionnés
        const validFiles = processFiles(selectedFiles, typeMaj, idSubDataTypeMaj, acceptedFileTypes);

        //! Mettre à jour la liste des fichiers avec les fichiers valides
        if (validFiles?.length > 0) {
            if (typeMaj === 'Zonage' &&
                (idSubDataTypeMaj === constSubTypeMAJ.Reglement ||
                    idSubDataTypeMaj === constSubTypeMAJ.Guidedinterpretation)) {
                setFichiers(validFiles); // Accepte uniquement le premier fichier valide
            }
            else
                setFichiers([...fichiers, ...validFiles]);
        }
    };


    // const handleDrop = (event) => {
    //     event.preventDefault();
    //     const droppedFiles = Array.from(event.dataTransfer.files);

    //     // Changer la clé de l'input pour forcer un re-render
    //     setInputKey(Date.now());

    //     setFormErrors((prevState) => ({ ...prevState, fichiers: '' }))

    //     // Filtrer les doublons (vérifie à la fois anciens et nouveaux fichiers)
    //     const filteredFiles = droppedFiles.filter(newFile => {
    //         return !fichiers.some(existingFile =>
    //             (existingFile.nomFichier === newFile.name) || // Comparaison avec les anciens fichiers
    //             (existingFile.name === newFile.name && existingFile.size === newFile.size) // Comparaison avec les nouveaux fichiers
    //         );
    //     });

    //     let validFiles = [];
    //     // Condition spécifique pour 'Matrice' avec types 'GOmatrice' ou 'GML'
    //     // if (typeMaj === 'Matrice' && (idSubDataTypeMaj === constSubTypeMAJ.GOmatrice || idSubDataTypeMaj === constSubTypeMAJ.GML)) {

    //     // Condition spécifique pour 'Matrice' avec types 'GOmatrice'
    //     if (typeMaj === 'Matrice' && (idSubDataTypeMaj === constSubTypeMAJ.GOmatrice)) {
    //         validFiles = filteredFiles.filter(file => {
    //             const fileExtension = file.name.split('.').pop();
    //             const fileName = file.name;

    //             // Vérification de l'extension .zip ou du format de nom .mdb
    //             const isValidZip = fileExtension === 'zip';
    //             const isValidRar = fileExtension === 'rar';
    //             const isValidMdb = fileExtension === 'mdb' && (
    //                 /^GOmatrice\d{5}\.mdb$/.test(fileName) || /^GOmatriceHab\d{5}\.mdb$/.test(fileName) || /^GOmatriceHis\d{5}\.mdb$/.test(fileName)
    //             );

    //             return isValidZip || isValidMdb || isValidRar;
    //         });
    //     }

    //     // Condition spécifique pour 'Zonage' avec types 'Règlements' ou 'Guide d’interprétation'
    //     else
    //         if (typeMaj === 'Zonage' && (idSubDataTypeMaj === constSubTypeMAJ.Reglement || idSubDataTypeMaj === constSubTypeMAJ.Guidedinterpretation)) {
    //             validFiles = filteredFiles.filter(file => {
    //                 const fileExtension = file.name.split('.').pop();
    //                 return acceptedFileTypes.split(',').some(type => type.trim().includes(fileExtension));
    //             });

    //             // Limiter à un seul fichier valide
    //             if (validFiles.length > 0) {
    //                 setFichiers([validFiles[0]]); // Accepte uniquement le premier fichier valide
    //                 return; // Sortie de la fonction après la mise à jour
    //             }
    //         }
    //         // Cas par défaut pour d'autres types avec vérification des types de fichiers acceptés
    //         else {
    //             validFiles = filteredFiles.filter(file => {
    //                 const fileExtension = file.name.split('.').pop();
    //                 return acceptedFileTypes.split(',').some(type => type.trim().includes(fileExtension));
    //             });
    //         }

    //     // Mise à jour des fichiers uniquement si des fichiers valides ont été trouvés
    //     if (validFiles.length > 0) {
    //         setFichiers([...fichiers, ...validFiles]);
    //     }
    // };

    // const handleChangeFile = (e) => {
    //     e.preventDefault();
    //     const selectedFiles = Array.from(e.target.files);

    //     // Changer la clé de l'input pour forcer un re-render
    //     setInputKey(Date.now());

    //     setFormErrors((prevState) => ({ ...prevState, fichiers: '' }))

    //     // Filtrer les doublons (vérifie à la fois les anciens et les nouveaux fichiers)
    //     const filteredFiles = selectedFiles.filter(newFile => {
    //         return !fichiers.some(existingFile =>
    //             (existingFile.nomFichier === newFile.name) || // Comparaison avec les anciens fichiers
    //             (existingFile.name === newFile.name && existingFile.size === newFile.size) // Comparaison avec les nouveaux fichiers
    //         );
    //     });

    //     // Filtrer les doublons (vérifie à la fois anciens et nouveaux fichiers)
    //     // const filteredFiles = selectedFiles.filter(newFile => {
    //     //     console.log(`Vérification pour le nouveau fichier :`, newFile.name, newFile.size);

    //     //     const isDuplicate = fichiers.some(existingFile => {
    //     //         const isOldFileDuplicate = existingFile.nomFichier === newFile.name; // Comparaison avec les anciens fichiers
    //     //         const isNewFileDuplicate = existingFile.name === newFile.name && existingFile.size === newFile.size; // Comparaison avec les nouveaux fichiers

    //     //         console.log('--- Comparaison avec un fichier existant ---');
    //     //         console.log('Ancien fichier :', existingFile.nomFichier || existingFile.name, existingFile.size || 'N/A');
    //     //         console.log(`Est un ancien fichier doublon : ${isOldFileDuplicate}`);
    //     //         console.log(`Est un nouveau fichier doublon : ${isNewFileDuplicate}`);

    //     //         return isOldFileDuplicate || isNewFileDuplicate;
    //     //     });

    //     //     console.log(`Le fichier ${newFile.name} est-il un doublon ?`, isDuplicate);
    //     //     return !isDuplicate;
    //     // });

    //     // console.log('Fichiers filtrés :', filteredFiles);

    //     let validFiles = [];

    //     // Condition spécifique pour 'Matrice' avec types 'GOmatrice'
    //     if (typeMaj === 'Matrice' && (idSubDataTypeMaj === constSubTypeMAJ.GOmatrice)) {
    //         validFiles = filteredFiles.filter(file => {
    //             const fileExtension = file.name.split('.').pop();
    //             const fileName = file.name;

    //             // Vérification de l'extension .zip ou du format de nom .mdb
    //             const isValidZip = fileExtension === 'zip';
    //             const isValidRar = fileExtension === 'rar';
    //             const isValidMdb = fileExtension === 'mdb' && (
    //                 /^GOmatrice\d{5}\.mdb$/.test(fileName) || /^GOmatriceHab\d{5}\.mdb$/.test(fileName) || /^GOmatriceHis\d{5}\.mdb$/.test(fileName)
    //             );

    //             return isValidZip || isValidMdb || isValidRar;
    //         });
    //     }

    //     // Condition spécifique pour 'Zonage' avec types 'Règlements' ou 'Guide d’interprétation'
    //     else
    //         if (typeMaj === 'Zonage' && (idSubDataTypeMaj === constSubTypeMAJ.Reglement || idSubDataTypeMaj === constSubTypeMAJ.Guidedinterpretation)) {
    //             validFiles = filteredFiles.filter(file => {
    //                 const fileExtension = file.name.split('.').pop();
    //                 return acceptedFileTypes.split(',').some(type => type.trim().includes(fileExtension));
    //             });

    //             // Limiter à un seul fichier valide
    //             if (validFiles.length > 0) {
    //                 setFichiers([validFiles[0]]); // Accepte uniquement le premier fichier valide
    //                 return; // Sortie de la fonction après la mise à jour
    //             }
    //         }
    //         // Cas par défaut pour d'autres types avec vérification des types de fichiers acceptés
    //         else {
    //             validFiles = filteredFiles.filter(file => {
    //                 const fileExtension = file.name.split('.').pop();
    //                 return acceptedFileTypes.split(',').some(type => type.trim().includes(fileExtension));
    //             });
    //         }

    //     // Mise à jour des fichiers uniquement si des fichiers valides ont été trouvés
    //     if (validFiles.length > 0) {
    //         setFichiers([...fichiers, ...validFiles]);
    //     }
    // };

    const handleDeleteFile = (index) => {
        const updatedFiles = fichiers.filter((_, i) => i !== index);
        setFichiers(updatedFiles); // Mise à jour des fichiers dans le parent
    };

    //================================================================

    return (
        <div>
            <div className="file-card">
                <div className="titre-image"><em>Fichier(s) à déposer</em></div>

                <section className="file-inputs" onClick={handleUploadClick}>
                    <input
                        type="file"
                        multiple
                        disabled={!typeMaj || messageTypeFiles === ''}
                        ref={refInput}
                        key={inputKey}
                        onChange={handleChangeFile}
                        accept={acceptedFileTypes}
                        // accept={acceptedFileTypes === '*' ? '' : acceptedFileTypes} // Si accept est '*', on le supprime pour accepter tous les fichiers
                        style={{ display: 'none' }}
                    />
                    <Box
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        sx={{
                            textAlign: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        <Typography variant="body2" color="textSecondary">
                            Glissez-déposez vos fichiers ici ou cliquez pour sélectionner <br /><br />
                            {messageTypeFiles === '' ? 'Aucun type de fichier n\'est accepté.' : messageTypeFiles}
                        </Typography>
                    </Box>
                </section>

                {fichiers?.length > 0 ? (
                    <>
                        <Typography variant="body2" color="textSecondary">
                            <br />Liste des fichiers
                        </Typography>
                        <List>
                            {fichiers.map((file, index) => (
                                <ListItem
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: file instanceof File ? '#83b6d86e' : '#d8d5eeb0', // Vert pour nouveaux fichiers, bleu pour anciens #a8cce1 #d8d5ee #d8d5ee85
                                        borderRadius: '4px',
                                        marginBottom: '5px',
                                        padding: '10px',
                                    }}
                                >
                                    <FilePresentRoundedIcon
                                    // sx={{
                                    //     color: file.nomFichier ? 'blue' : 'green', // Icône de couleur différente
                                    // }}
                                    />
                                    <Typography sx={{ marginLeft: '10px', flexGrow: 1 }}>
                                        {file.nomFichier || file.name} {/* Affiche nomFichier pour anciens ou name pour nouveaux */}
                                    </Typography>
                                    {/* Ajout de la spécification pour les anciens fichiers */}
                                    {!(file instanceof File && file.name) && (
                                        <Typography sx={{ marginLeft: '10px', color: 'gray', fontStyle: 'italic' }}>
                                            Fichier déposé en date du {dayjs(file.dateCreation).format('DD MMM YYYY')}
                                        </Typography>
                                    )}
                                    <IconButton onClick={() => handleDeleteFile(index)}>
                                        <DeleteForeverRoundedIcon />
                                    </IconButton>
                                </ListItem>
                            ))}
                        </List>
                    </>
                ) : (
                    <Typography variant="caption" color="error">
                        {formErrors.fichiers}
                    </Typography>
                )}


            </div>
        </div>
    );
}
