import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import {
  addNewMiseEnLigne,
  sendFilesMiseEnLigne
} from '../httpQuerys';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  LinearProgress,
  Box,
} from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';

export default function DialogConfirm({ formDataMEL, formErrors, open, setOpen, isFormValidated }) {

  const navigate = useNavigate();
  const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));
  const toDay = dayjs().isValid() ? dayjs().locale('fr-ca').format('YYYY-MM-DD HH:mm:ss') : null;

  const [openDialog, setOpenDialog] = useState(open);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [messageConfirmationDialog, setMessageConfirmationDialog] = useState('');
  const [loading, setLoading] = useState(false); // État pour le chargement

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false)
    setOpenDialog(false)
    setOpenSecondDialog(false)
    navigate(`/dashboard/municipalites/${formDataMEL.cityCode}/mu-mise-en-ligne/mu-mel-list`);
  };

  const handleCancel = () => {
    setOpen(false)
    setOpenDialog(false)
    setOpenSecondDialog(false)
  };

  // Fonction pour la confirmation de l'ajout d'une nouvelle mise en ligne
  const handleConfirm = async () => {
    console.log('Ajout de la nouvelle mise en ligne');
    setLoading(true); // Démarre le chargement

    try {
      // Mettre à jour les données dans une nouvelle variable
      const updatedFormDataMEL = {
        ...formDataMEL, // Copier l'état actuel
        dateMaj: toDay  // Mettre à jour la date
      };

      // Étape 1 : Envoi des données sans le stockage des fichiers
      const response = await addNewMiseEnLigne(updatedFormDataMEL);

      if (response?.response?.idTracking) {
        const idTracking = response.response.idTracking;

        // Vérifier si des fichiers sont présents avant de les envoyer
        if (updatedFormDataMEL.fichiers?.length > 0) {
          console.log('Des fichiers sont présents, préparation de l\'envoi...');


          // Créer un objet FormData pour envoyer les fichiers physiquement
          const formData = new FormData();
          formData.append('idTracking', idTracking); // Ajouter idTracking

          // Ajouter tous les fichiers au FormData
          updatedFormDataMEL.fichiers.forEach((file) => {
            formData.append('fichiers', file); // Utilisation de 'fichiers' comme clé
          });

          // 🔹 Logs pour vérification
          // console.log('-----------------✅ Contenu du formData -----------------');
          // for (let [key, value] of formData.entries()) {
          //   console.log(key, value);
          // }

          // Étape 2 : Envoi des fichiers
          const responseAddFiles = await sendFilesMiseEnLigne(formData);
          console.log('Réponse fichiers :', responseAddFiles?.response?.success);

          if (responseAddFiles?.response.success) {
            console.log('Soumission du formulaire réussie');
            setMessageConfirmationDialog('La mise à jour a été ajoutée avec succès.');
            setOpenSecondDialog(true); // Ouvrir la boîte de confirmation
          } else {
            throw new Error('Erreur lors de l\'envoi des fichiers.');
          }
        } else {
          console.log('Aucun fichier sélectionné. Mise à jour enregistrée sans fichiers.');
          setMessageConfirmationDialog('La mise à jour a été ajoutée avec succès (sans fichiers).');
          setOpenSecondDialog(true);
        }

      } else {
        throw new Error('ID de suivi manquant dans la réponse.');
      }

    } catch (error) {
      console.error('Erreur lors de l\'ajout de la requête de mise à jour :', error)
      setMessageConfirmationDialog('Erreur lors de l\'ajout de la requête de mise à jour.');
      setOpenSecondDialog(true); // Ouvrir la boîte de dialogue d\'erreur
    } finally {
      setLoading(false); // Arrêter le chargement après la fin de la requête
    }
  };


  return (
    openSecondDialog ? (

      // <Dialog open={openSecondDialog} onClose={handleClose} onKeyDown={handleEscape}>
      <Dialog open={openSecondDialog} onClose={handleClose} >
        <DialogTitle>Confirmation d'ajout de nouvelle mise en ligne</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Une requête d'ajout d'une nouvelle mise en ligne de type {formDataMEL.typeMaj} est lancée.<br /><br />
            Un temps de traitement de votre demande est requis, vous serez avisé de toute mise à jour en temps et en heure ! <br /><br />
            <strong>{messageConfirmationDialog}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">Fermer</Button>
        </DialogActions>
      </Dialog>

    ) : (
      isFormValidated ? (
        <div>
          <Dialog
            open={openDialog}
            onClose={handleClose}
          // onKeyDown={handleKeyDown}
          >
            <DialogTitle>Requête d'ajout d'une nouvelle mise en ligne</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Vous êtes sur le point de soumettre une requête de mise en ligne de type <strong>{formDataMEL.typeMaj}</strong> pour la ville de <em>{currentCity.cityName}</em>.
                <br /><br />
                Veuillez confirmer cette requête !
              </DialogContentText>
              {messageConfirmationDialog}
              {/* Indicateur de chargement */}
              {/* {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <CircularProgress />
                </Box>
              )} */}

              {/* Progression linéaire en option */}
              {loading &&
                <>
                  <LinearProgress sx={{ mt: 2 }} />
                  <Box sx={{ color: '#999', fontSize: '0.875rem' }}>
                    Requête en cours, veuillez patienter...
                  </Box>
                </>
              }
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCancel}
                color="error"
                // variant="contained"
                disabled={loading} // Désactive le bouton pendant le chargement
              >
                Annuler
              </Button>
              <Button
                onClick={handleConfirm}
                color="primary"
                // variant="contained"
                disabled={loading} // Désactive le bouton pendant le chargement
              >
                Confirmer
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div>
          <Dialog open={open} onClose={handleCancel}>
            <DialogTitle color="error">Erreur dans le formulaire</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Un ou plusieurs champs du formulaire sont incorrects ou manquants ! <br /><br />
                {
                  Object.entries(formErrors).length > 0
                    ? Object.entries(formErrors)
                      .filter(([_, value]) => value !== '')
                      .map(([key, value]) => (
                        <Typography key={key} color="error" component="li" sx={{ listStyleType: "' - '", marginLeft: '1em' }}>
                          {value}
                        </Typography>
                      ))
                    : <Typography color="error">Erreur inconnue dans le formulaire.</Typography>
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCancel}
              // variant="contained"
              // color="error"
              >Retour au Formulaire</Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    )
  )
}
