import React, { useRef } from 'react'
import { useState } from 'react'
import './ForgotPW.scss'
import FormInput from '../../components/form/FormInput'
import Logo from '../../assets/images/logo512.png'
import { useAuth } from '../../contexts/AuthContext'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import { Link } from 'react-router-dom'
import DialogConfirm from './DialogConfirm'
import { masquerEmail } from './FunctionsUtils';


export default function ForgotPW() {
    const submitBtnRef = useRef();
    const { forgotPW } = useAuth();

    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    // const [loading, setLoading] = useState(false);
    const [hiddenEmail, setHiddenEmail] = useState('');

    const [dialogueMessage, setDialogMessage] = useState('')
    const [dialogueResponse, setDialogResponse] = useState({})

    const [formValues, setFormValues] = useState({
        username: '',
        email: '',
    })

    const inputs = [
        {
            id: 1,
            name: 'username',
            type: 'text',
            label: 'Identifiant de l\'utilisateur ',
            placeholder: 'Saisir votre identifiant',
            errorMessage: 'Ce champs est obligatoire pour vous identifier ! ',
            // required: true,
        },
        {
            id: 2,
            name: 'email',
            type: 'email',
            placeholder: 'Email ',
            label: 'Courriel ',
            errorMessage: 'Email non valide! ',
            // required: true,
        }
    ]

    const handleChange = (e) => {
        setErrorMessage("");
        submitBtnRef.current.classList.remove('nope');
        setFormValues({ ...formValues, [e.target.name]: e.target.value })
    }

    //! ----------------------------------------------------------------

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        let message = '';

        const submitButton = submitBtnRef.current;
        if (submitButton) {
            submitButton.classList.remove('nope');
        }

        // je verifie si au moins l'un des deux champs est rempli
        if (!formValues['username'] && !formValues['email']) {
            if (submitButton) {
                submitButton.classList.add('nope');
            }
            setErrorMessage("Veuillez remplir au moins un des champs !");
        }
        else {
            // sur la base de l'identifiant ou de l'adresse email
            // je verifie et renvoie toutes les infos en appelant la fonction forgotPW
            // qui retourne un json { userExists, emailExiste, userName, fullName, userEmail, countEmail }
            try {
                const response = await forgotPW({
                    username: formValues['username'],
                    email: formValues['email'],
                });
                response.status = 'failure'

                if (response.userExists) {
                    if ((response.emailExists && response.userEmail === formValues['email'])
                        || (response.emailExists && formValues['email'] === '')) {

                        if (response.emailExists && response.countEmail === 1) {
                            // console.log(response.userEmail, ' est utilisée une seule fois')
                            message = `Un courriel de changement de mot de passe vous sera envoyé à l'adresse
                            ${masquerEmail(response.userEmail)}.
                            Par la suite, vérifier votre boîte de réception et suivre les instructions pour changer votre mot de passe.
                            Pour continuer, veuillez confirmer l'envoi.`;
                            response.status = 'success';
                            setHiddenEmail(masquerEmail(response.userEmail));
                        }
                        else
                            if (response.emailExists && response.countEmail > 1) {
                                if (response.userName !== '') {
                                    console.log(response.userEmail, ' est utilisée ', response.countEmail, ' fois')
                                    message = `L'adresse courriel associée à votre identifiant est exploitée par plusieurs utilisateurs.
                                     Un courriel de réinitialisation de mot de passe sera envoyé à l'adresse ${masquerEmail(response.userEmail)}.
                                     Veuillez ensuite vérifier votre boîte de réception et suivre les instructions pour changer votre mot de passe.
                                     Pour continuer, veuillez confirmer l'envoi.`;
                                    response.status = 'double';
                                    setHiddenEmail(masquerEmail(response.userEmail));
                                }
                                else {
                                    console.log(response.userEmail, ' est utilisée ', response.countEmail, ' fois')
                                    message = `L'adresse courriel fournie est utilisée par plusieurs utilisateurs.
                                     veuillez indiquer votre identifiant. En cas d'oubli contacter vorte administration !`;
                                }
                            }
                    }
                    else
                        if (response.emailExists && response.emailExists !== formValues['email'] && formValues['email'] !== '') {
                            console.log('verification demandée l adresse saisie ne correspnd pas à celle enregistrée dans la BD ')
                            message = `verification demandée ! L'adresse du courriel saisie ne correspnd pas à celle enregistrée dans nos bases de données !`;
                        }
                        else {
                            console.log(response.fullName, ' n a pas de e-mail enregistré dans la BD ')
                            message = response.fullName
                                ? `${response.fullName}, aucune adresse e-mail n'est associée à votre compte !`
                                : `Aucune adresse de courriel n'est associée à votre compte !`;
                        }
                }
                else
                    if (formValues['username']) {
                        console.log('identifiant introuvable')
                        message = `Nous éprouvons des difficultés à trouver votre identifiant dans nos bases de données.`
                    }
                    else
                        if (formValues['email']) {
                            console.log('email introuvable')
                            message = `Nous éprouvons des difficultés à trouver votre adresse du courriel dans nos bases de données.`
                        }
                        else {
                            message = `Erreur au niveau des données saisies`
                        }

                setDialogMessage(message)
                setDialogResponse(response)
                setOpen(true);
            } catch (error) {
                console.error("Erreur lors de la connexion :", error);
            }
        }
        return;
    }

    return (
        <div className='ForgotPW'>
            {open && (
                <DialogConfirm
                    // emailAdress={emailAdress}
                    open={open}
                    setOpen={setOpen}
                    message={dialogueMessage}
                    hiddenEmail={hiddenEmail}
                    response={dialogueResponse}
                    // isFormValidated={isFormValidated}
                />
            )}

            <form onSubmit={handleSubmit}>

                <div className="forgotHeader">
                    <div className="forgotHeader__logo">
                        <img src={Logo} alt='logo Azimut' />
                    </div>
                    <div className='titles'>
                        <h1 className="forgotHeader__title">PORTAIL CLIENT</h1>
                        <div className="forgotHeder__second">
                            <h4 className="forgotHeader__subtitle">Mot de passe oublié</h4>
                            <Link to="/login" className='backIcon' title='Retour vers la page de connexion'>
                                <KeyboardBackspaceRoundedIcon />
                            </Link>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: 'left', fontSize: '12px', paddingBottom: '12px' }}>
                    Veuillez remplir le champ identifiant et / ou courriel
                </div>
                {inputs.map((input) => (
                    <FormInput
                        className="inputPW"
                        key={input.id}
                        {...input}
                        value={formValues[input.name]}
                        handleChange={handleChange}
                    />
                ))}

                <button
                    className='submitButton'
                    type="submit"
                    ref={submitBtnRef}
                    // disabled={loading}
                >
                    {!errorMessage ? 'Soumettre' : 'Erreur de soummission'}
                </button>

                <div className='errorMessage'>
                    {errorMessage && <p>{errorMessage}</p>}
                </div>

            </form>
        </div>
    )
}


