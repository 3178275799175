import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './AddMuToUser.scss'
// import {
//     addMunicipalityToUser,
// } from '../httpQuerys';
import {
    Paper,
    Button,
} from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';

import DialogConfirm from './DialogConfirm';

import UserProfilesServicesForm from './User-Profiles-Services-Form/UserProfilesServicesForm';
import UserInfoForm from './User-Info-Form/UserInfoForm';

export default function AddMuToUser() {
    const toDay = dayjs().isValid() ? dayjs().locale('frFR').format('YYYY-MM-DD HH:mm:ss') : null;

    const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));
    const { cityCode } = currentCity;
    const currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));
    const { idGroup } = currentUserInfo;

    const addUserFormRef = useRef(null);
    const [userError, setUserError] = useState('');
    const [userToAdd, setUserToAdd] = useState(
        {
            currentCity: currentCity,
            idGroup: idGroup,
            fullName: '',
            userName: '',
            profile: null,
            servicesProfile: [],
            defaultServiceProfile: null,
            expiration: toDay,
            contact: false,
        }
    );

    const [profileError, setProfileError] = useState('');
    const [profileServicesError, setProfileServicesError] = useState('');
    const [defaultServiceError, setDefaultServiceError] = useState('');

    const [isFormValidated, setIsFormValidated] = useState(false);
    const [open, setOpen] = useState(false);

    const attributDefaultService = (userToUpdate, setUserToUpdate) => {

        const updatedServicesProfile = userToUpdate.servicesProfile.map((item) => ({
            ...item,
            defaultService: item.aliasName === userToUpdate.defaultServiceProfile.aliasName ? true : false
        }));

        const updatedUser = {
            ...userToUpdate,
            servicesProfile: updatedServicesProfile
        };

        setUserToUpdate(updatedUser);
        return updatedUser;

    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log({ userToAdd: userToAdd });

        if (userToAdd.userName === '') setUserError('Sélectionner un utilisateur.')
        if (userToAdd.profile === null) setProfileError('Un profil est obligatoire.')
        if (userToAdd.servicesProfile.length === 0) setProfileServicesError('Au minimum un service est obligatoire.')
        if (userToAdd.defaultServiceProfile === null) setDefaultServiceError('Le champ service par défaut est obligatoire.')

        const updatedUser = userToAdd.defaultServiceProfile !== null ? attributDefaultService(userToAdd, setUserToAdd) : userToAdd;

        let validateForm =
            (userToAdd.userName) &&
            (updatedUser.profile !== null) &&
            (updatedUser.servicesProfile.length > 0) &&
            (updatedUser.defaultServiceProfile !== null)

        // console.log({ validateForm })
        // console.log({ userToAdd: userToAdd });
        //     if (validateForm) {
        //         // console.log({ userToAdd: userToAdd });
        //         // const result = true;
        //         const result = await addMunicipalityToUser(updatedUser);
        //         if (result) {

        //             console.log('Soumission du Formulaire');
        //             setIsFormValidated(true);
        //             // permet d'afficher la boite de dialogue à la soumission du formulaire
        //             // setOpen(true);
        //         }
        //     }
        //     else {
        //         console.log('Un des champs est manquant ou mal saisi');
        //         setIsFormValidated(false);
        //     }
        //     // permet d'afficher la boite de dialogue à la soumission du formulaire
        //     setOpen(true);
        // };

        if (validateForm) {
            console.log('Requête de soumission du Formulaire envoyée');
        }
        else {
            console.log('Un des champs est manquant ou mal saisi');
        }
        setIsFormValidated(validateForm);
        // permet d'afficher la boite de dialogue à la soumission du formulaire
        setOpen(true);
    };

    return (
        // <div className='muAddUser-main-wrapper'>
        //     <AddMuToUserForm idUser={idUser} currentCity={currentCity} current />
        // </div>

        <div className='addMuToUser'>
            {open && (
                <DialogConfirm
                    userToAdd={userToAdd}
                    profileError={profileError}
                    profileServicesError={profileServicesError}
                    defaultServiceError={defaultServiceError}
                    open={open}
                    setOpen={setOpen}
                    isFormValidated={isFormValidated}
                />
            )}

            <Paper elevation={5} className='muAddUser-paper' sx={{ height: 600, overflowY: 'auto' }}>
                <form ref={addUserFormRef} className='muAddUserForm' onSubmit={handleSubmit}>

                    <UserInfoForm
                        userToAdd={userToAdd}
                        setUserToAdd={setUserToAdd}
                        userError={userError}
                        setUserError={setUserError}
                    />
                    <hr />
                    <UserProfilesServicesForm
                        userToAdd={userToAdd}
                        setUserToAdd={setUserToAdd}
                        profileError={profileError}
                        setProfileError={setProfileError}
                        profileServicesError={profileServicesError}
                        setProfileServicesError={setProfileServicesError}
                        defaultServiceError={defaultServiceError}
                        setDefaultServiceError={setDefaultServiceError}
                    // isAccordionValidated={isAccordionValidated}
                    />
                    <hr />

                    <div className='footerAddUser'>
                        <Link to={`/dashboard/municipalites/${cityCode}/mu-utilisateurs`} style={{ textDecoration: "none" }}>
                            <Button className='btnSubmit' variant="contained" sx={{ width: '200px', cursor: 'pointer' }}>
                                Annuler
                            </Button>
                        </Link>

                        <Button className='btnSubmit' type="submit" variant="contained" sx={{ width: '200px' }} onClick={handleSubmit}>
                            Enregistrer
                        </Button>

                    </div>

                </form>
            </Paper>

        </div>
    )
}