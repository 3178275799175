import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { deleteSelectedUser } from '../httpQuerys';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Box,
} from '@mui/material';

export default function DialogConfirm({ open, setOpen, selectedRows }) {
  const navigate = useNavigate();
  const { cityCode } = JSON.parse(localStorage.getItem('currentMunicipality'));

  const [loading, setLoading] = useState(false);
  const [messageConfirmation, setMessageConfirmation] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    setShowConfirmation(false);
  }, [open]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setShowConfirmation(false);
    navigate(`/dashboard/municipalites/${cityCode}/mu-utilisateurs`);
  }, [setOpen, cityCode, navigate]);

  const handleConfirm = async () => {
    setLoading(true);

    try {
      // Utilisation de Promise.allSettled pour gérer les erreurs par utilisateur supprimé.
      const responses = await Promise.allSettled(
        selectedRows.map((row) => deleteSelectedUser(row))
      );

      // Comportement de Promise.allSettled
      // Attend que toutes les promesses soient terminées (réussies ou échouées).
      // Ne rejette jamais directement, il donne un tableau d'objets décrivant l'état de chaque promesse (fulfilled ou rejected).
      // Lorsqu'on veut gérer individuellement les succès et les erreurs, sans arrêter tout en cas d'échec
      // (dans notre cas : suppression de plusieurs utilisateurs).

      const isSuccess = responses.every((res) => res.status === 'fulfilled');

      setMessageConfirmation(
        isSuccess
          ? 'Suppression effectuée avec succès.'
          : 'Certaines suppressions ont échoué.'
      );
      setShowConfirmation(true);
    } catch (error) {
      console.error('Erreur lors de la suppression des utilisateurs :', error);
      setMessageConfirmation('Erreur lors de la suppression.');
      setShowConfirmation(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      {showConfirmation ? (
        <>
          <DialogTitle>Confirmation de suppression</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <strong>{messageConfirmation}</strong>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>Suppression d'utilisateur(s)</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Êtes-vous sûr de vouloir retirer les services de{' '}
              {selectedRows.length > 1
                ? `ces ${selectedRows.length} utilisateurs`
                : 'cet utilisateur'}
              ? Cette action est irréversible.
            </DialogContentText>
            {loading && (
              <>
                <LinearProgress sx={{ mt: 2 }} />
                <Box sx={{ color: '#999', fontSize: '0.875rem' }}>
                  Requête en cours, veuillez patienter...
                </Box>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error" disabled={loading}>
              Annuler
            </Button>
            <Button onClick={handleConfirm} color="primary" disabled={loading}>
              Confirmer
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
