import React, { useEffect, useState } from 'react';
import "./MiseEnLigneAddNewForm.scss"
import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';
import {
  getMiseEnLigneType,
  getMiseEnLigneSubDataType,
  getUsersListForContact,
  getProfileList,
  getServicesProfile,
} from '../../httpQuerys';
import { capitalizeFirstLetterOfWords } from '../../FunctionsUtils';
import {
  TextField,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
  Autocomplete,
  Stack,
  FormControl,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  Box,
  Checkbox,
  FormHelperText,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import PersonIcon from '@mui/icons-material/Person';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddLinkIcon from '@mui/icons-material/AddLink';
import Card from './files-Card/Card';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export default function MiseEnLigneAddNewForm(props) {

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  // ---------------------------------------------------------------
  // Configuration de la locale pour Day.js
  dayjs.locale("fr-ca");
  // ---------------------------------------------------------------
  const toDay = dayjs().isValid() ? dayjs().locale('frFR').format('YYYY-MM-DD HH:mm:ss') : null;


  const currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
  const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));
  const { cityCode } = currentCity

  const {
    formDataMEL,
    setFormDataMEL,
    formErrors,
    setFormErrors,
  } = props;

  const [profilesList, setProfilesList] = useState([]);
  const [profile, setProfile] = useState(null);
  const [profileServicesList, setProfileServicesList] = useState([]);
  const [profileServices, setProfileServices] = useState([]);
  const [, setProfileError] = useState('');
  const [profileServicesError, setProfileServicesError] = useState('');
  const [PDForLink, SetPDForLink] = useState();
  const [typesMEL, setTypesMEL] = useState([]);
  const [subDataTypeMaj, setSubDataTypeMaj] = useState([]);
  const [users, setUsers] = useState([]);

  // console.log('idSubDataTypeMaj ', formDataMEL?.idSubDataTypeMaj);
  // console.log('PDForLink ', formDataMEL?.PDForLink);
  // console.log('PDForLink ', PDForLink);

  //  valeurs a récupérer de la BD la liste des profils
  useEffect(() => {
    getProfileList(currentUser.idGroup).then(data => setProfilesList(data));
    profile?.nameProfile && getServicesProfile(cityCode, currentCity.idGroup, profile.nameProfile).then(data => setProfileServicesList(data));
  }, [cityCode, currentCity.idGroup, currentUser.idGroup, profile]);

  // données des Options de la liste déroulante pour les profils
  const profilesDefaultProps = {
    options: profilesList,
    getOptionLabel: (option) => option.nameProfile ? `${option.descriptionProfile}` : ''
    // getOptionLabel: (option) => option.nameProfile ? `${option.nameProfile} - ${option.descriptionProfile}` : ''
    // getOptionLabel: (option) => option.nameProfile || ''
  };

  // données des Options de la liste déroulante pour les servicesProfile
  const profileServicesDefaultProps = {
    options: profileServicesList,
    getOptionLabel: (option) => option.aliasName ? option.aliasName : ''
  };

  //?================================================================
  //=================================================================
  //!================================================================
  //?================================================================


  //  valeurs a récupérer de la BD
  useEffect(() => {
    getMiseEnLigneType().then((data) => setTypesMEL(data));
    getMiseEnLigneSubDataType().then((data) => setSubDataTypeMaj(data));
    getUsersListForContact(cityCode, currentUser.idGroup).then((data) => setUsers(data));
  }, [cityCode, currentUser.idGroup]);

  // Créer un objet contenant les constantes
  const constSubTypeMAJ = subDataTypeMaj.reduce((acc, item) => {
    // Créer une constante avec le nom basé sur la description
    const constName = item.description
      .normalize('NFD')  // Normalisation pour décomposer les accents
      .replace(/[\u0300-\u036f]/g, '')  // Suppression des accents
      .replace(/[^a-zA-Z0-9]/g, '');  // Suppression des caractères spéciaux
    acc[constName] = item.idSubDataTypeMaj;
    return acc;
  }, {});

  // constSubTypeMAJ = {Autre:10,  GML:2,  GOinfra:9,  GOmatrice:1,  Grille:5,  Guidedinterpretation:8,  PIIA:7,  Reglement:6,  Zone:4}
  // console.log(constSubTypeMAJ);

  // const typeMajArray = ['Autre', 'GOcadastre', 'Matrice', 'Rôle', 'Zonage', 'Orthophoto', 'Infrastructure', 'Croquis', 'Photos', 'Transactions immobilières', 'Taxes'];
  const typeMajArray = ['Matrice', 'Rôle', 'Zonage'];

  const temporaryTypesMEL = typesMEL.filter((item) => typeMajArray.includes(item.description));
  // console.log(temporaryTypesMEL)

  // données des Options de la liste déroulante pour le type des mises en ligne
  const typeDataToAddDefaultProps = {
    options: temporaryTypesMEL,
    getOptionLabel: (option) => `${option.idDataTypeMaj} - ${option.description}`
    // getOptionLabel: (option) => `${option.description}`
  };

  // données des Options de la liste déroulante pour les titres
  const usersDefaultProps = {
    options: users,
    // getOptionLabel: (option) => `${option.fullName} (${option.idUser})`
    getOptionLabel: (option) => `${option.fullName}`
  };

  //! ----------------------------------------------------------------
  // Validation d'un lien vers une page web
  function isValidURL(string) {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // Protocole
      '((([a-zA-Z0-9$-_@.&+!*"(),])+\\.)+([a-zA-Z]{2,}))' + // Domaine
      '(\\/[a-zA-Z0-9$-_@.&+!*"(),]*)?' + // Chemin facultatif
      '(\\?.*)?$' // Query string facultatif
    );
    return !!urlPattern.test(string);
  }

  //!================================================================
  // Gestion de changement dans un champ textfield
  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;

    setFormDataMEL({
      ...formDataMEL,
      [name]: capitalizeFirstLetterOfWords(value)
    });

    // Validation pour ce champ
    if (value.trim() === '') {
      setFormErrors({
        ...formErrors,
        [name]: 'Ce champ ne peut pas être vide.'
      });
    } else {
      setFormErrors({
        ...formErrors,
        [name]: ''
      });
    }
  };

  //!================================================================
  // Effacer le contenu d'champ en cliquant sur le x
  const handleClear = async (field) => {
    // console.log(field)
    switch (field) {
      case 'noteDemandeur':
        setFormDataMEL((prevState) => ({
          ...prevState,
          noteDemandeur: ''
        }));
        break;
      case 'lienWeb':
        setFormDataMEL((prevState) => ({
          ...prevState,
          lienWeb: ''
        }));
        break;
      default:
        break;
    }
  };

  return (
    <div className='MiseEnLigneAddNewForm'>

      <Grid container className='gridContainer' spacing={2} m='0' >

        <Grid item className='gridItem' xs={12} md={12} lg={12}>
          <FormControl
            className='gridItem-formControl'
            variant="standard" sx={{ mb: 2 }}
          >
            <FormLabel
              component="legend"
              error={Boolean(formErrors.typeMaj)} // Active la bordure rouge en cas d'erreur
              required>
              Type de donnée à ajouter
            </FormLabel>

            <Stack spacing={1}>
              <Autocomplete
                {...typeDataToAddDefaultProps}
                id="typeMaj"
                name="typeMaj"
                clearOnEscape
                isOptionEqualToValue={(option, value) => (option.description === value.description || value === '')}
                value={typesMEL.find((option) => option.description === formDataMEL.typeMaj) || null}

                onChange={(e, newValue) => {
                  // console.log(newValue)
                  // Mise à jour du formDataMEL avec la nouvelle valeur sélectionnée
                  setFormDataMEL((prevState) => ({
                    ...prevState,
                    typeMaj: newValue ? newValue.description : '',
                    idDataTypeMaj: newValue ? newValue.idDataTypeMaj : '',
                    producteur: '',
                    dateProduction: null,
                    dateZonageEnVigueur: null,
                    fichiers: [],
                    filesName: [],
                    idSubDataTypeMaj: null,
                    PDForLink: '',
                    lienWeb: '',
                    messageMetaData: '',
                    noteDemandeur: '',
                    numeroReglement: '',
                    services: [],
                    zonageChampZone: '',
                    zonageCoucheSource: '',
                    zonageGuideInterpretation: '',
                    zonageReglement: '',
                  }));

                  // Gérer les erreurs
                  if (newValue) {
                    setFormErrors({
                      ...formErrors,
                      typeMaj: '', // Réinitialiser l'erreur si une valeur est sélectionnée
                      producteur: '',
                      dateProduction: '',
                      dateZonageEnVigueur: '',
                      fichiers: '',
                      idSubDataTypeMaj: '',
                      PDForLink: '',
                      lienWeb: '',
                      messageMetaData: '',
                      noteDemandeur: '',
                      numeroReglement: '',
                      services: '',
                      zonageChampZone: '',
                      zonageCoucheSource: '',
                      zonageGuideInterpretation: '',
                      zonageReglement: '',
                    });
                  } else {
                    setFormErrors({
                      ...formErrors,
                      typeMaj: 'Veuillez sélectionner un type de donnée !',
                    });
                  }

                  // Mise à jour du message si typeMaj est 'Rôle'
                  if (newValue && newValue.description === 'Rôle') {
                    setFormDataMEL((prevState) => ({
                      ...prevState,
                      messageMetaData: 'Les informations sur le triennal, le producteur, l’évaluateur et la date de production seront extraites du rôle transmis.'
                    }));
                  } else {
                    setFormDataMEL((prevState) => ({
                      ...prevState,
                      messageMetaData: '' // Réinitialiser le message si ce n'est pas 'Rôle'
                    }));
                  }

                }}

                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    name="typeMaj"
                    error={Boolean(formErrors.typeMaj)} // Affiche une bordure rouge si une erreur existe
                    helperText={formErrors.typeMaj} // Affiche le message d'erreur si présent
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <PlaylistAddCheckIcon sx={{ mr: 1, my: 0.5 }} />
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
            </Stack>
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6}  >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0' >
            <InputLabel htmlFor="demandeur">Demandeur</InputLabel>
            <Input
              id="demandeur"
              name="demandeur"
              autoComplete="demandeur"
              disabled
              value={currentUser.fullName || ''}

              startAdornment={
                <InputAdornment position="start">
                  <PersonIcon
                    sx={{
                      color: 'action.active',
                      mr: 1,
                      my: 0.5
                    }}
                  />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                {...usersDefaultProps}
                id="finduser"
                name="finduser"

                isOptionEqualToValue={(option, value) => option.fullName === value.fullName}

                // Utilise la valeur de contact et initialise à demandeur si contact est vide
                value={usersDefaultProps.options.find(option => option.idUser === formDataMEL.contact) ||
                  usersDefaultProps.options.find(option => option.idUser === formDataMEL.demandeur) || null}

                onChange={(e, newValue) => {
                  // console.log(newValue);
                  setFormDataMEL((prevState) => ({
                    ...prevState,
                    contact: newValue ? newValue.idUser : formDataMEL.demandeur
                  }));
                }}

                clearOnEscape
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Contact"
                    variant="standard"
                    name="finduser"
                    helperText={formErrors.contact || 'Sélectionnez un utilisateur à contacter'} // Affiche le message d'erreur si présent, sinon le texte d'aide
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <PermPhoneMsgIcon
                            sx={{
                              color: 'action.active',
                              mr: 1,
                              my: 0.5
                            }}
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
            </Stack>
          </FormControl>
        </Grid>

        {formDataMEL.typeMaj === 'Matrice' && (
          <Grid item className='gridItem' xs={12} md={12} lg={12}>
            <FormControl
              className='gridItem-formControl'
              variant="standard"
              sx={{ mb: 2 }}
              error={Boolean(formErrors.idSubDataTypeMaj)} // Active la bordure rouge en cas d'erreur
              required // Indiquer que le champ est requis
            >
              <Stack spacing={1}>
                {/* Conteneur flex pour aligner le titre et les boutons radio sur la même ligne */}
                <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                  {/* Titre du groupe de boutons radio */}
                  <FormLabel component="legend" sx={{ mr: 2 }}>
                    Type d'informations
                  </FormLabel>

                  {/* Groupe de boutons radio */}
                  <RadioGroup
                    row // Utilise 'row' pour aligner les boutons horizontalement
                    aria-label="matrice-type"
                    name="matrice-type"

                    value={formDataMEL.idSubDataTypeMaj || null}

                    onChange={(e) => {
                      setFormDataMEL((prevData) => ({
                        ...prevData,
                        idSubDataTypeMaj: Number(e.target.value),
                        producteur: '',
                        dateProduction: toDay,
                        fichiers: [],
                        filesName: [],
                      }));

                      // Effacer les erreurs si le type change
                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        idSubDataTypeMaj: '',
                        producteur: '',
                        dateProduction: '',
                        fichiers: '',
                      }));

                      // Mise à jour du Message Meta Data si typeMaj est 'GOmatrice'
                      if (Number(e.target.value) === constSubTypeMAJ.GOmatrice) {
                        setFormDataMEL((prevState) => ({
                          ...prevState,
                          messageMetaData: 'Les informations sur le producteur et la date de production seront extraites des fichiers.'
                        }));
                      } else {
                        setFormDataMEL((prevState) => ({
                          ...prevState,
                          messageMetaData: '' // Réinitialiser le message si ce n'est pas 'GOmatrice'
                        }));
                      }
                    }}
                  >
                    <FormControlLabel value={constSubTypeMAJ.GOmatrice} control={<Radio />} label="GOmatrice" />
                    <FormControlLabel value={constSubTypeMAJ.GML} control={<Radio />} label="GML" />
                    <FormControlLabel value={constSubTypeMAJ.Autre} control={<Radio />} label="Autre" />
                  </RadioGroup>
                  {/* Affichage du message d'erreur si aucun bouton n'est sélectionné */}
                  {formErrors.idSubDataTypeMaj && (
                    <FormHelperText error>{formErrors.idSubDataTypeMaj}</FormHelperText>
                  )}
                </Box>
              </Stack>
            </FormControl>
          </Grid>
        )}

        {formDataMEL.typeMaj === 'Zonage' && (
          <Grid item className='gridItem' xs={12} md={12} lg={12}>
            {/* <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}> */}
            <FormControl
              className='gridItem-formControl'
              variant="standard"
              sx={{ mb: 2 }}
              error={Boolean(formErrors.idSubDataTypeMaj)} // Active la bordure rouge en cas d'erreur
              required // Indiquer que le champ est requis
            >
              <Stack spacing={1}>
                {/* Conteneur flex pour aligner le titre et les boutons radio sur la même ligne */}
                <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                  {/* Titre du groupe de boutons radio */}
                  <FormLabel component="legend" sx={{ mr: 2 }}>
                    Type d'informations
                  </FormLabel>

                  {/* Groupe de boutons radio */}
                  <RadioGroup
                    row // Utilise 'row' pour aligner les boutons horizontalement
                    aria-label="zonage-type"
                    name="zonage-type"

                    value={formDataMEL.idSubDataTypeMaj || ''}

                    onChange={(e) => {
                      setFormDataMEL((prevData) => ({
                        ...prevData,
                        idSubDataTypeMaj: Number(e.target.value),
                        producteur: '',
                        dateProduction: toDay,
                        numeroReglement: "",
                        dateZonageEnVigueur: toDay,
                        zonageCoucheSource: '',
                        zonageChampZone: '',
                        zonageGuideInterpretation: '',
                        zonageReglement: '',
                        fichiers: [],
                        filesName: [],
                        lienWeb: '',
                        PDForLink: '',
                      }));

                      // Réinitialiser l'erreur lorsque l'utilisateur sélectionne une option
                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        idSubDataTypeMaj: '',
                        producteur: '',
                        dateProduction: '',
                        numeroReglement: '',
                        dateZonageEnVigueur: '',
                        zonageCoucheSource: '',
                        zonageChampZone: '',
                        zonageGuideInterpretation: '',
                        zonageReglement: '',
                        fichiers: '',
                        lienWeb: '',
                        PDForLink: '',
                      }));


                      if (
                        Number(e.target.value) === constSubTypeMAJ.Zone ||
                        Number(e.target.value) === constSubTypeMAJ.Grille ||
                        Number(e.target.value) === constSubTypeMAJ.PIIA
                      ) {
                        SetPDForLink('PDF')
                      } else {
                        SetPDForLink('')
                      }

                    }}
                  >
                    <FormControlLabel value={constSubTypeMAJ.Zone} control={<Radio />} label="Zone" />
                    <FormControlLabel value={constSubTypeMAJ.Grille} control={<Radio />} label="Grille" />
                    <FormControlLabel value={constSubTypeMAJ.Reglement} control={<Radio />} label="Règlement" />
                    <FormControlLabel value={constSubTypeMAJ.PIIA} control={<Radio />} label="PIIA" />
                    <FormControlLabel value={constSubTypeMAJ.Guidedinterpretation} control={<Radio />} label="Guide d’interprétation" />
                  </RadioGroup>

                  {/* Affichage du message d'erreur si aucun bouton n'est sélectionné */}
                  {formErrors.idSubDataTypeMaj && (
                    <FormHelperText error>{formErrors.idSubDataTypeMaj}</FormHelperText>
                  )}

                </Box>
              </Stack>
            </FormControl>
          </Grid>
        )}

        {formDataMEL.typeMaj === 'Infrastructure' && (
          <Grid item className='gridItem' xs={12} md={12} lg={12}>
            <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
              <Stack spacing={1}>
                {/* Conteneur flex pour aligner le titre et les boutons radio sur la même ligne */}
                <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                  {/* Titre du groupe de boutons radio */}
                  <FormLabel component="legend" sx={{ mr: 2 }}>
                    GOinfra
                  </FormLabel>

                  {/* Groupe de boutons radio */}
                  <RadioGroup
                    row // Utilise 'row' pour aligner les boutons horizontalement
                    aria-label="infrastructure-type"
                    name="infrastructure-type"
                    value={formDataMEL.idSubDataTypeMaj || ''}
                    onChange={(e) => {
                      setFormDataMEL({
                        ...formDataMEL,
                        idSubDataTypeMaj: Number(e.target.value),
                      });

                      // Effacer les erreurs si le type change
                      setFormErrors({
                        ...formErrors,
                        producteur: '',
                        dateProduction: '',
                      });

                      // Mise à jour du message si typeMaj est 6 - Infrastructure
                      if (Number(e.target.value) === constSubTypeMAJ.GOinfra) {
                        setFormDataMEL((prevState) => ({
                          ...prevState,
                          messageMetaData: 'Les métadonnées seront déterminées par la date de mise en ligne.'
                        }));
                      } else {
                        setFormDataMEL((prevState) => ({
                          ...prevState,
                          messageMetaData: '' // Réinitialiser le message si ce n'est pas 'Rôle'
                        }));
                      }
                    }}
                  >
                    <FormControlLabel value={constSubTypeMAJ.GOinfra} control={<Radio />} label="Oui" />
                    <FormControlLabel value={-constSubTypeMAJ.GOinfra} control={<Radio />} label="Non" />
                  </RadioGroup>
                </Box>
              </Stack>
            </FormControl>
          </Grid>
        )}

        {(
          formDataMEL.typeMaj === 'Rôle' ||
          (formDataMEL.typeMaj === 'Matrice' && formDataMEL.idSubDataTypeMaj === constSubTypeMAJ.GOmatrice) ||
          (formDataMEL.typeMaj === 'Infrastructure' && formDataMEL.idSubDataTypeMaj === constSubTypeMAJ.GOinfra)
        ) && (
            <Grid item className='gridItem' xs={12} md={12} lg={12}>
              <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                <Stack spacing={1}>
                  <Typography variant="body2" color="primary">
                    {formDataMEL.messageMetaData}
                  </Typography>
                </Stack>
              </FormControl>
            </Grid>
          )}

        {/* Si le type sélectionné correspond à l'un de ces choix on affiche le champs Producteur et Date de production */}
        {(formDataMEL.typeMaj === 'Zonage' ||
          (formDataMEL.typeMaj === 'Matrice' && (formDataMEL.idSubDataTypeMaj === constSubTypeMAJ.GML || formDataMEL.idSubDataTypeMaj === constSubTypeMAJ.Autre)) ||
          (formDataMEL.typeMaj === 'Orthophoto') ||
          (formDataMEL.typeMaj === 'Croquis') ||
          (formDataMEL.typeMaj === 'Photos') ||
          (formDataMEL.typeMaj === 'Autre') ||
          (formDataMEL.typeMaj === 'Transactions immobilières')
        ) && (
            <>
              <Grid item className='gridItem' xs={12} md={6} lg={6}>
                {/* Champ Producteur */}
                <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                  <Stack spacing={1}>
                    <TextField
                      label="Producteur"
                      variant="standard"
                      name="producteur"

                      value={formDataMEL.producteur || ''}

                      onChange={(e) => {
                        setFormDataMEL((prevData) => ({
                          ...prevData,
                          producteur: e.target.value,
                          dateProduction: dayjs(prevData.dateProduction).isValid()
                            ? dayjs(prevData.dateProduction).locale('frFR').format('YYYY-MM-DD HH:mm:ss')
                            : toDay,
                        }));

                        // Mise à jour des erreurs potentielles
                        setFormErrors((prevErrors) => ({
                          ...prevErrors,
                          producteur: '',
                          dateProduction: dayjs(formDataMEL.dateProduction).isSame(dayjs(), 'day')
                            ? "La date d'aujourd'hui est sélectionnée par défaut"
                            : ''
                        }));
                      }}

                      error={Boolean(formErrors.producteur)}
                      helperText={formErrors.producteur || ''}
                      required

                      InputProps={{
                        endAdornment: formDataMEL.producteur && (
                          <InputAdornment position="end">
                            <ClearIcon
                              sx={{ cursor: 'pointer', fontSize: 'small' }}
                              onClick={() => {
                                setFormDataMEL((prevData) => ({
                                  ...prevData,
                                  producteur: '', // Effacer le champ producteur
                                }));
                                setFormErrors((prevErrors) => ({
                                  ...prevErrors,
                                  producteur: '', // Réinitialiser l'erreur pour le champ producteur
                                }));
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}

                    />
                  </Stack>
                </FormControl>
              </Grid>

              <Grid item className='gridItem gridItem-date' xs={12} md={6} lg={6} >
                {/* Champ Date de production dateProduction*/}
                <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr-FR">
                  <DatePicker
                    /* Afficher la date sélectionnée dans un format lisible */
                    name="dateProduction"
                    label="Date de production"
                    format="YYYY/MM/DD"
                    openTo="day"
                    views={["year", "month", "day"]}
                    slotProps={{
                      textField: {
                        variant: "filled",
                        size: "small",
                        style: { width: '100%', height: '100%' }
                      }
                    }}

                    // Il faut s'assurer que la date est toujours un objet Day.js ou null
                    value={dayjs(formDataMEL.dateProduction).isValid() ? dayjs(formDataMEL.dateProduction) : dayjs()}


                    // onChange={(date) => setFormDataMEL({ ...formDataMEL, dateProduction: date ? date.toISOString() : dayjs() })}
                    // onChange={(date) => setFormDataMEL((prevData) => ({
                    //   ...prevData,
                    //   dateProduction: dayjs(date).isValid()
                    //     ? dayjs(date).locale('fr').format('YYYY-MM-DD HH:mm:ss')
                    //     : toDay,
                    // }))}

                    onChange={(date) => {
                      setFormDataMEL((prevData) => ({
                        ...prevData,
                        dateProduction: date
                          ? dayjs(date).locale('fr').format('YYYY-MM-DD HH:mm:ss')
                          : toDay
                      }));

                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        dateProduction: dayjs(date).isValid()
                          ? dayjs(date).isSame(dayjs(), 'day')
                            ? "La date d'aujourd'hui est sélectionnée par défaut"
                            : ''
                          : 'La date n\'est pas correcte'
                      }));
                    }}
                  />
                </LocalizationProvider>
                <p style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px' }}>
                  {formErrors.dateProduction}
                </p>
              </Grid>

            </>
          )}

        {(formDataMEL.typeMaj === 'Zonage') && (
          <>
            <Grid item className='gridItem' xs={12} md={6} lg={6}>
              <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                <Stack spacing={1}>
                  {/* Champ Numéro de règlements */}
                  <TextField
                    label="Numéro de règlements"
                    variant="standard"
                    name="numeroReglement"

                    value={formDataMEL.numeroReglement || ''}

                    onChange={(e) => {
                      // Mettre à jour formDataMEL avec le nouveau numeroReglement et gérer la dateZonageEnVigueur
                      setFormDataMEL((prevData) => ({
                        ...prevData,
                        numeroReglement: e.target.value,
                        dateZonageEnVigueur: dayjs(prevData.dateZonageEnVigueur).isValid()
                          ? dayjs(prevData.dateZonageEnVigueur).locale('frFR').format('YYYY-MM-DD HH:mm:ss')
                          : toDay,
                      }));

                      // Mise à jour des erreurs potentielles
                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        numeroReglement: '',
                        dateZonageEnVigueur: dayjs(formDataMEL.dateZonageEnVigueur).isSame(dayjs(), 'day')
                          ? "La date d'aujourd'hui est sélectionnée par défaut"
                          : ''
                      }));
                    }}

                    error={Boolean(formErrors.numeroReglement)}
                    helperText={formErrors.numeroReglement || ''}
                    required

                    InputProps={{
                      endAdornment: formDataMEL.numeroReglement && (
                        <InputAdornment position="end">
                          <ClearIcon
                            sx={{ cursor: 'pointer', fontSize: 'small' }}
                            onClick={() => {
                              setFormDataMEL((prevData) => ({
                                ...prevData,
                                numeroReglement: '', // Efface le champ numeroReglement
                              }));
                              setFormErrors((prevErrors) => ({
                                ...prevErrors,
                                numeroReglement: '', // Réinitialise l'erreur pour le champ numeroReglement
                              }));
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </FormControl>
            </Grid>

            <Grid item className='gridItem gridItem-date' xs={12} md={6} lg={6} >
              <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr-FR">
                <DatePicker
                  name="dateZonageEnVigueur"
                  label="Date d'entrée en vigueur"
                  format="YYYY/MM/DD"
                  openTo="day"
                  views={["year", "month", "day"]}
                  slotProps={{
                    textField: {
                      variant: "filled",
                      size: "small",
                      style: { width: '100%', height: '100%' }
                    }
                  }}

                  // Il faut s'assurer que la date est toujours un objet Day.js ou null
                  // value={ dayjs(formDataMEL.dateZonageEnVigueur).isValid()? dayjs(formDataMEL.dateZonageEnVigueur) : dayjs()}
                  value={formDataMEL.dateZonageEnVigueur ? dayjs(formDataMEL.dateZonageEnVigueur) : dayjs()}
                  // onChange={(date) => setFormDataMEL({ ...formDataMEL, dateZonageEnVigueur: date ? date.toISOString() : dayjs() })}
                  onChange={(date) => {
                    setFormDataMEL((prevData) => ({
                      ...prevData,
                      dateZonageEnVigueur: date
                        ? dayjs(date).locale('fr').format('YYYY-MM-DD HH:mm:ss')
                        : dayjs().locale('fr').format('YYYY-MM-DD HH:mm:ss')
                    }));

                    setFormErrors((prevErrors) => ({
                      ...prevErrors,
                      dateZonageEnVigueur: dayjs(date).isValid()
                        ? dayjs(date).isSame(dayjs(), 'day')
                          ? "La date d'aujourd'hui est sélectionnée par défaut"
                          : ''
                        : 'La date n\'est pas correcte'
                    }));
                  }}

                />
              </LocalizationProvider>

              <p style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px' }}>
                {formErrors.dateZonageEnVigueur}
              </p>
            </Grid>

            {formDataMEL.idSubDataTypeMaj === constSubTypeMAJ.Zone && (
              <>
                <Grid item className='gridItem' xs={12} md={6} lg={6}>
                  <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                    <Stack spacing={1}>
                      {/* Couche source */}
                      <TextField
                        label="Couche source"
                        variant="standard"
                        name="zonageCoucheSource"

                        value={formDataMEL.zonageCoucheSource || ''}

                        // onChange={(e) => setFormDataMEL({ ...formDataMEL, zonageCoucheSource: e.target.value })}


                        onChange={(e) => {
                          // Mettre à jour formDataMEL avec le nouveau contenu
                          setFormDataMEL((prevData) => ({
                            ...prevData,
                            zonageCoucheSource: e.target.value,
                          }));

                          // Mise à jour des erreurs potentielles
                          setFormErrors((prevErrors) => ({
                            ...prevErrors,
                            zonageCoucheSource: '',
                          }));
                        }}


                        error={Boolean(formErrors.zonageCoucheSource)}
                        helperText={formErrors.zonageCoucheSource || ''}
                        required

                        InputProps={{
                          endAdornment: formDataMEL.zonageCoucheSource && (
                            <InputAdornment position="end">
                              <ClearIcon
                                sx={{ cursor: 'pointer', fontSize: 'small' }}
                                onClick={() => {
                                  setFormDataMEL((prevData) => ({
                                    ...prevData,
                                    zonageCoucheSource: '', // Efface le champ zonageCoucheSource
                                  }));
                                  setFormErrors((prevErrors) => ({
                                    ...prevErrors,
                                    zonageCoucheSource: '', // Réinitialise l'erreur pour le champ zonageCoucheSource
                                  }));
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                  </FormControl>
                </Grid>

                <Grid item className='gridItem' xs={12} md={6} lg={6}>
                  <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                    <Stack spacing={1}>
                      {/* Champ zone */}
                      <TextField
                        label="Champ zone"
                        variant="standard"
                        name="zonageChampZone"

                        value={formDataMEL.zonageChampZone || ''}

                        onChange={(e) => setFormDataMEL({ ...formDataMEL, zonageChampZone: e.target.value })}

                        error={Boolean(formErrors.zonageChampZone)}
                        helperText={formErrors.zonageChampZone || ''}
                        required

                        InputProps={{
                          endAdornment: formDataMEL.zonageChampZone && (
                            <InputAdornment position="end">
                              <ClearIcon
                                sx={{ cursor: 'pointer', fontSize: 'small' }}
                                onClick={() => {
                                  setFormDataMEL((prevData) => ({
                                    ...prevData,
                                    zonageChampZone: '', // Efface le champ zonageChampZone
                                  }));
                                  setFormErrors((prevErrors) => ({
                                    ...prevErrors,
                                    zonageChampZone: '', // Réinitialise l'erreur pour le champ zonageChampZone
                                  }));
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                  </FormControl>
                </Grid>
              </>
            )}
          </>
        )}

        {(
          formDataMEL.typeMaj === 'Autre'
        ) && (<>
          <hr />
          <Grid item className='gridItem checkboxItem' xs={12} md={12} lg={12}>
            <FormLabel
              className='checkboxItem-title'
              sx={{ fontWeight: 'bold' }}
            >
              THÉMATIQUES : PROFILS ET THÉMATIQUES
            </FormLabel>
          </Grid>

          <Grid item className='gridItem' xs={12} md={6} lg={6} >
            <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
              <Stack spacing={1} >
                <Autocomplete
                  {...profilesDefaultProps}
                  id="profilesList"
                  name="profilesList"

                  isOptionEqualToValue={(option, value) => option.nameProfile === value?.nameProfile}

                  value={profile}

                  onChange={(e, newValue) => {
                    setProfileError('');
                    setProfileServicesError('')

                    if (newValue) {
                      // console.log({ newValue })
                      setProfile(newValue || null);
                      setProfileServices([]); // Réinitialiser les services du profil
                      setFormDataMEL({
                        ...formDataMEL,
                        profile: newValue.nameProfile,
                      });
                    }
                    else {
                      // L'utilisateur a effacé la valeur sélectionnée
                      setProfile(null);
                      setProfileServices([])
                    }
                  }
                  }

                  onInputChange={(e, newInputValue) => {
                    if (!newInputValue) {
                      // L'utilisateur a effacé le champ au clavier
                      setProfile(null);
                      setProfileServices([])
                    }
                  }}

                  clearOnEscape
                  renderInput={(params) => <TextField {...params} label="Profils" variant="standard" name="profilesList" />}
                />
              </Stack>
              {formErrors.profile && <Typography variant="caption" color="error">{formErrors.profile}</Typography>}
            </FormControl>
          </Grid>
          {/* <Grid item className='gridItem' xs={12} md={6} lg={6} ></Grid> */}

          <Grid item className='gridItem' xs={12} md={6} lg={6} >
            <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
              <Stack spacing={1} >
                <Autocomplete
                  {...profileServicesDefaultProps}
                  multiple
                  id="multipleServicesProfile"
                  name="multipleServicesProfile"
                  disableCloseOnSelect
                  disabled={!profile?.nameProfile}

                  isOptionEqualToValue={(option, value) => option.aliasName === value.aliasName}
                  value={profileServices}

                  onChange={(e, newValue) => {
                    // console.log(newValue);
                    if ({ newValue }) {
                      // console.log(newValue);
                      setProfileServices(newValue)
                      setProfileServicesError('');

                      const updatedServicesProfile = newValue.map(item => ({
                        idService: item.idService,
                        aliasName: item.aliasName
                      }));

                      setFormDataMEL({
                        ...formDataMEL,
                        services: updatedServicesProfile,
                      });

                      // console.log(updatedServicesProfile)

                    }
                  }}

                  // disableCloseOnSelect

                  renderOption={(props, option, { selected }) => {
                    return (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.aliasName}
                      </li>
                    )
                  }}

                  clearOnEscape
                  renderInput={(params) => <TextField {...params} label="Thématiques du profil" variant="standard" name="multipleServicesProfile" />}
                />
              </Stack>
              {profileServicesError && <Typography variant="caption" color="error">{profileServicesError}</Typography>}
            </FormControl>
          </Grid>
        </>)}

        <hr />

        <Grid item className='gridItem' xs={12} md={12} lg={12}>
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1}>
              <TextField
                id="noteDemandeur"
                label="Note du demandeur"
                name="noteDemandeur" // le nom correspond à celui dans formDataMEL
                helperText="Saisir une note"
                multiline
                type="search"
                rows={2}
                variant="standard"
                value={formDataMEL.noteDemandeur || ''} // Affiche la valeur actuelle du champ
                onChange={handleTextFieldChange} // Met à jour la valeur du champ
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EditNoteIcon sx={{ color: 'action.active', mr: 1 }} />
                    </InputAdornment>
                  ),
                  endAdornment: formDataMEL.noteDemandeur && (
                    <ClearIcon
                      onClick={() => handleClear('noteDemandeur')}
                      sx={{ cursor: 'pointer', fontSize: 'small' }}
                    />
                  ),
                }}
                inputProps={{ style: { color: 'black' } }}

                error={Boolean(formErrors.noteDemandeur)} // Affiche une bordure rouge si une erreur existe
              />
            </Stack>
          </FormControl>
        </Grid>

        <hr />

        {(
          formDataMEL.typeMaj === 'Zonage' &&
          (formDataMEL.idSubDataTypeMaj === constSubTypeMAJ.Reglement || formDataMEL.idSubDataTypeMaj === constSubTypeMAJ.Guidedinterpretation)
        )
          && (
            <>
              <Grid item className='gridItem' xs={12} md={12} lg={12}>
                <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                  <Stack spacing={1}>
                    {/* Conteneur flex pour aligner le titre et les boutons radio sur la même ligne */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                      {/* Titre du groupe de boutons radio */}
                      <FormLabel component="legend" sx={{ mr: 2 }}>
                        Fichier PDF ou Lien Hypertext
                      </FormLabel>

                      {/* Groupe de boutons radio */}
                      <RadioGroup
                        row // Utilise 'row' pour aligner les boutons horizontalement
                        aria-label="PDForLink"
                        name="PDForLink"
                        value={PDForLink || ''}
                        // onChange={(e) => (SetPDForLink(e.target.value))}
                        onChange={(e) => {
                          SetPDForLink(e.target.value)

                          setFormDataMEL((prevState) => ({
                            ...prevState,
                            fichiers: [],
                            filesName: [],
                            PDForLink: e.target.value,
                            lienWeb: '',
                            zonageGuideInterpretation: '',
                            zonageReglement: '',
                          }));
                          setFormErrors({
                            ...formErrors,
                            fichiers: '',
                            PDForLink: '',
                            lienWeb: '',
                            zonageGuideInterpretation: '',
                            zonageReglement: '',
                          })
                        }}
                      >
                        <FormControlLabel value="PDF" control={<Radio />} label="Fichier .PDF" />
                        <FormControlLabel value="Link" control={<Radio />} label="Lien Web" />
                      </RadioGroup>

                      {/* Affichage du message d'erreur si aucun bouton n'est sélectionné */}
                      {formErrors.PDForLink && (
                        <FormHelperText error>{formErrors.PDForLink}</FormHelperText>
                      )}
                    </Box>
                  </Stack>
                </FormControl>
              </Grid>
            </>
          )
        }

        {((formDataMEL.typeMaj === 'Zonage') &&
          (formDataMEL.idSubDataTypeMaj === constSubTypeMAJ.Reglement || formDataMEL.idSubDataTypeMaj === constSubTypeMAJ.Guidedinterpretation) &&
          (PDForLink === 'Link')
        ) ? (
          <Grid item className='gridItem' xs={12} md={12} lg={12}  >
            <div className="titre"><em>Lien vers une page Web</em></div>
            <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0' >
              <Input
                id="lienWeb"
                name="lienWeb"
                autoComplete="lienWeb"
                value={formDataMEL.lienWeb || ''} // Affiche la valeur actuelle du champ
                // Met à jour la valeur du champ
                onChange={(e) => {
                  setFormDataMEL((prevState) => ({
                    ...prevState,
                    lienWeb: e.target.value,
                    zonageReglement: prevState.idSubDataTypeMaj === constSubTypeMAJ.Reglement ? e.target.value : '',
                    zonageGuideInterpretation: prevState.idSubDataTypeMaj === constSubTypeMAJ.Guidedinterpretation ? e.target.value : '',
                    fichiers: [],
                    filesName: [],
                  }));
                  setFormErrors({
                    ...formErrors,
                    lienWeb: '',
                    zonageReglement: '',
                    zonageGuideInterpretation: '',
                    fichiers: '',
                  })
                }}
                // Vérifie la validité du lien lorsqu'on quitte le champ
                onBlur={() => {
                  if (!isValidURL(formDataMEL.lienWeb)) {
                    setFormErrors({
                      ...formErrors,
                      lienWeb: '',
                      zonageReglement: formDataMEL.idSubDataTypeMaj === constSubTypeMAJ.Reglement && 'Veuillez entrer un lien valide vers une page web !',
                      zonageGuideInterpretation: formDataMEL.idSubDataTypeMaj === constSubTypeMAJ.Guidedinterpretation && 'Veuillez entrer un lien valide vers une page web !',
                    })
                  }
                }}

                startAdornment={
                  <InputAdornment position="start">
                    <AddLinkIcon
                      sx={{
                        color: 'action.active',
                        mr: 1,
                        my: 0.5
                      }}
                    />
                  </InputAdornment>
                }
                endAdornment={
                  formDataMEL.lienWeb && (
                    <InputAdornment position="end">
                      <ClearIcon
                        onClick={() => handleClear('lienWeb')}
                        sx={{ cursor: 'pointer', fontSize: 'small' }}
                      />
                    </InputAdornment>
                  )
                }
              />
              {/* Affichage d'un message d'erreur si l'URL est invalide */}
              <Typography variant="caption" color="error">
                {formErrors.zonageReglement || formErrors.zonageGuideInterpretation}
              </Typography>


            </FormControl>
          </Grid>
        ) : (
          <>

            {/* Fichier(s) à déposer */}
            {((formDataMEL.typeMaj === 'Rôle') ||
              (formDataMEL.typeMaj === 'Matrice') ||
              (formDataMEL.typeMaj === 'Zonage' && PDForLink === 'PDF' && formDataMEL.idSubDataTypeMaj !== null) ||
              (formDataMEL.typeMaj === 'Infrastructure') ||
              (formDataMEL.typeMaj === 'Autre') ||
              (formDataMEL.typeMaj === 'Transactions immobilières')
            ) ? (
              <>
                <Grid item className="gridItem" xs={12} sm={12} md={12} lg={12}>
                  <Card
                    // Propriétés à transmettre au composant Card
                    formDataMEL={formDataMEL}
                    setFormDataMEL={setFormDataMEL}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                    typeMaj={formDataMEL.typeMaj} // type de mise à jour
                    constSubTypeMAJ={constSubTypeMAJ}
                    idSubDataTypeMaj={formDataMEL.idSubDataTypeMaj || null}

                    fichiers={formDataMEL.fichiers}    // Nom du fichier transmis depuis formDataMEL
                    // Fonction de gestion de mise à jour des fichiers
                    setFichiers={(newFiles) => setFormDataMEL({ ...formDataMEL, fichiers: newFiles })}
                  // setFichiers={(newFiles) => setFormDataMEL((prevData) => ({
                  //   ...prevData,
                  //   fichiers: newFiles
                  // }))}

                  />
                </Grid>
              </>
            ) : (
              <>
                {(
                  (formDataMEL.typeMaj === 'Orthophoto') ||
                  (formDataMEL.typeMaj === 'Croquis') ||
                  (formDataMEL.typeMaj === 'Photos')
                ) && (
                    <Grid item className='gridItem' xs={12} md={12} lg={12}>
                      <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                        <Stack spacing={1}>
                          <Typography variant="body2" color="primary">
                            Les {formDataMEL.typeMaj} sont généralement trop volumineuses pour être transféré à partir de cette page. <br />
                            Si c'est le cas, indiquer où les {formDataMEL.typeMaj} ont été déposés pour qu'elles soient récupérées par le soutien technique d'AZIMUT.
                          </Typography>
                        </Stack>
                      </FormControl>
                    </Grid>
                  )}
              </>
            )
            }
          </>
        )}

      </Grid>

    </div>
  )
}
