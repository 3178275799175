import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  addUserMunicipalitiesAndServices,
  sendEmailToNewUser,
} from '../httpQuerys';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  Typography,
  LinearProgress,
  Box
} from '@mui/material';

export default function DialogConfirm(props) {
  const {
    newUserToAdd,
    prenomError,
    nomError,
    userNameError,
    passwordError,
    emailError,
    telephoneError,
    userProfilesErrors,
    open,
    setOpen,
    isFormValidated
  } = props;

  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(open);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const [loading, setLoading] = useState(false);

  // Réinitialise les états quand la fenêtre principale est fermée
  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setOpenDialog(false);
    setOpenSecondDialog(false);
    navigate(`/dashboard/utilisateurs`);
  };

  const handleCancel = () => {
    setOpen(false);
    setOpenDialog(false);
    setOpenSecondDialog(false);
  };

  // Vérifie si la touche pressée est "Entrée" ou "Échap"
  // const handleKeyPress = (e) => {
  //   if (e.key === "Enter" && isFormValidated) handleConfirm();
  //   if (e.key === "Escape") handleClose();
  // };

  const handleConfirm = async () => {
    if (!isFormValidated) return;

    setLoading(true);

    try {
      const result = await addUserMunicipalitiesAndServices(newUserToAdd);
      if (result?.success) {
        if (sendEmail) {
          // Envoi du courriel si la checkbox est cochée
          await sendEmailToNewUser(newUserToAdd);
          setOpenSecondDialog(true);
        } else {
          handleClose();
        }
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'utilisateur :", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Dialogue de confirmation d'envoi d'email */}
      {/* <Dialog open={openSecondDialog} onClose={handleClose} onKeyDown={handleKeyPress}> */}
      <Dialog open={openSecondDialog} onClose={handleClose} >
        <DialogTitle>Confirmation d'envoi de courriel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Un courriel a été envoyé à l'adresse {newUserToAdd?.email}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">Fermer</Button>
        </DialogActions>
      </Dialog>

      {/* Dialogue de validation du formulaire */}
      {/* <Dialog open={openDialog} onClose={handleCancel} onKeyDown={handleKeyPress}> */}
      <Dialog open={openDialog} onClose={handleCancel} >
        <DialogTitle>{isFormValidated ? "Requête d'ajout d'un utilisateur" : "Erreur dans le formulaire"}</DialogTitle>
        <DialogContent>
          {isFormValidated ? (
            <>
              <DialogContentText>
                Vous êtes sur le point d'ajouter <strong>{newUserToAdd?.fullName}</strong>
                {newUserToAdd?.currentCity?.cityName && ` pour la ville de ${newUserToAdd.currentCity.cityName}`}.<br />
                Veuillez confirmer cette requête !
              </DialogContentText>

              <DialogContentText>
                Envoyer un courriel avec l'identifiant et le mot de passe.
                <Checkbox checked={sendEmail} onChange={() => setSendEmail(!sendEmail)} />
              </DialogContentText>

              {loading && (
                <>
                  <LinearProgress sx={{ mt: 2 }} />
                  <Box sx={{ color: '#999', fontSize: '0.875rem' }}>
                    Requête en cours, veuillez patienter...
                  </Box>
                </>
              )}
            </>
          ) : (
            <>
              <DialogContentText>Veuillez corriger les erreurs suivantes :</DialogContentText>
              <ul>
                {[prenomError, nomError, userNameError, passwordError, emailError, telephoneError, userProfilesErrors]
                  .filter(Boolean) // Supprime les erreurs nulles ou undefined
                  .map((error, index) => (
                    <Typography
                      key={index}
                      color="error"
                      component="li"
                      sx={{ listStyleType: "'- '", marginLeft: '1em' }}
                    >
                      {error}
                    </Typography>
                  ))}
              </ul>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="error" disabled={loading}>Annuler</Button>
          {isFormValidated && (
            <Button onClick={handleConfirm} color="primary" disabled={loading}>
              Confirmer
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
