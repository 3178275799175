import dataService from "../../../httpService";

// Fonction pour obtenir les statistiques d'une municipalité
async function getStatsData({ SQLDatabase, version, periode }) {
  try {
    const response = await dataService.get('/user/municipalitie/statistiques', {
      params: {
        SQLDatabase, version, periode
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Exporter les fonctions
export { getStatsData };