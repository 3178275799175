import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MuHeader from '../../../components/mu-header/MuHeader';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import "./MuConfiguration.scss";
import MuImages from "./Mu-Images/MuImages";
import WarningMessages from "./Warning-Messages/WarningMessages";
// import PopUpMessages from "./popUp-Messages/PopUpMessages";
// import MuListeMessages from "./popUp-Messages/Mu-Messages-List/MuListeMessages";


export default function MuConfiguration() {
    const navigate = useNavigate();

    // Tableau des rôles autorisés pour la gestion des utilisateurs
    // ['ROLE_ADMIN', 'ROLE_CONSULT', 'ROLE_GESTION_UTILISATEURS', 'ROLE_MISE_EN_LIGNE', 'ROLE_GESTION_UTILISATEURS_ET_MISE_EN_LIGNE'];
    const allowedRolesForUserManagement = ['ROLE_ADMIN', 'ROLE_GESTION_UTILISATEURS', 'ROLE_GESTION_UTILISATEURS_ET_MISE_EN_LIGNE'];
    // const { roleName, idGroup } = JSON.parse(localStorage.getItem('currentUserInfo'));
    // const allowedManagement = allowedRolesForUserManagement.includes(roleName) || (idGroup === 'G00');
    const { roleName } = JSON.parse(localStorage.getItem('currentUserInfo'));
    const allowedManagement = allowedRolesForUserManagement.includes(roleName);

    const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));
    const { page } = useParams();

    const [value, setValue] = useState(allowedManagement ? page : 'mu-images');

    useEffect(() => {
        if (page === 'warning-messages' && !allowedManagement) {
            navigate(`/dashboard/municipalites/${currentCity.cityCode}/mu-configuration/mu-images`)
            setValue('mu-images')
        } else {
            setValue(page)
        }
    }, [allowedManagement, currentCity.cityCode, navigate, page])


    const handleChange = (event, newValue) => {
        navigate(`/dashboard/municipalites/${currentCity.cityCode}/mu-configuration/${newValue}`)
        setValue(newValue);
    };


    return (
        <div className="Configuration">

            <MuHeader currentCity={currentCity} />

            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} >

                            <Tab label="Logo et fond d'écran" value='mu-images' />

                            {allowedManagement ? (
                                <Tab label="Textes d'avertissement" value='warning-messages' />
                            ) : null}

                            {/* {allowedManagement ? (
                                <Tab label="Messages à l'ouverture" value='popUp-messages-list' />
                            ) : null}

                            {allowedManagement ? (
                                <Tab label="Nouveau Message à l'ouverture" value='popUp-messages' />
                            ) : null} */}

                        </TabList>
                    </Box>

                    <TabPanel value="mu-images">
                        <MuImages allowedManagement={allowedManagement} />
                    </TabPanel>

                    {allowedManagement ? (
                        <TabPanel value="warning-messages">
                            <WarningMessages />
                        </TabPanel>
                    ) : null}

                    {/* {allowedManagement ? (
                        <TabPanel value="popUp-messages-list">
                            <MuListeMessages allowedManagement={allowedManagement}/>
                        </TabPanel>
                    ) : null}

                    {allowedManagement ? (
                        <TabPanel value="popUp-messages">
                            <PopUpMessages />
                        </TabPanel>
                    ) : null} */}

                </TabContext>
            </Box>
        </div>
    );
}