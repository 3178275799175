import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import './DialogShowMEL.scss'
import { getMELByIdTracking, getMELFilesByIdTracking, getMELServicesByIdTracking } from '../httpQuerys';
import dayjs from "dayjs";
import "dayjs/locale/fr"; // Importer la langue française
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";

// Configurer Day.js
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.locale("fr");

// Récupérer le fuseau horaire du client
// const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC";
// const clientTimeZone = "America/Montreal"; // Fuseau horaire local
// const utcDate = dayjs(dataMEL.dateCreation).utc().format("DD MMMM YYYY à HH:mm");  // UTC
// const localDate = dayjs(dataMEL.dateCreation).tz("America/Montreal").format("DD MMMM YYYY à HH:mm");  // Local (Montréal)

export default function DialogShowMEL({ openDetail, setOpenDetail, data }) {

  const [dataMEL, setDataMEL] = useState({});

  // Récupération des données à partir de la BD pour l'affichage des détails d'une mise en ligne
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        // Récupérer les données principales
        const [mainData] = await getMELByIdTracking(data.idTracking);
        const filesData = await getMELFilesByIdTracking(data.idTracking);
        const servicesData = await getMELServicesByIdTracking(data.idTracking);

        // Ajouter le fichier dans filesData si mainData.zonageReglement n'est pas vide et qu'il s'agit d'un fichier
        if (mainData.zonageReglement) {
          if (isFileOrURL(mainData.zonageReglement) === 'Fichier') {
            filesData.push({ nomFichier: mainData.zonageReglement });
          }
        }

        // Ajouter le fichier dans filesData si mainData.zonageGuideInterpretation n'est pas vide et qu'il s'agit d'un fichier
        if (mainData.zonageGuideInterpretation) {
          if (isFileOrURL(mainData.zonageGuideInterpretation) === 'Fichier') {
            filesData.push({ nomFichier: mainData.zonageGuideInterpretation });
          }
        }

        // Récupérer uniquement les noms de fichiers
        const filesName = filesData.map((file) => file.nomFichier);

        // Construire les données mises à jour
        const updatedData = {
          ...mainData,
          nomDemandeur: data.demandeur,
          description: data.description,
          statusName: data.statusName,
          fichiers: filesData,
          filesName,
          profile: servicesData[0]?.nameProfile || '',
          services: servicesData || [],

          ...(mainData.typeMaj === 'Zonage' && {
            PDForLink: filesData.length > 0 ? 'PDF' : 'Link',
            lienWeb:
              filesData.length > 0
                ? ''
                : mainData.zonageGuideInterpretation || mainData.zonageReglement || '',
          }),

          ...(mainData.typeMaj === 'Rôle' && {
            messageMetaData:
              'Les informations sur le triennal, le producteur, l’évaluateur et la date de production seront extraites du rôle transmis.',
          }),

          ...((mainData.typeMaj === 'Matrice' && mainData.subDataDescription === 'GOmatrice') && {
            messageMetaData:
              'Les informations sur le producteur et la date de production seront extraites des fichiers.',
          }),

          ...((mainData.typeMaj === 'Infrastructure' && mainData.subDataDescription === 'GOinfra') && {
            messageMetaData:
              'Les métadonnées seront déterminées par la date de mise en ligne.',
          }),
        };

        // Mise à jour des états
        setDataMEL(updatedData);
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    };

    fetchAllData();
  }, [data.demandeur, data.description, data.idTracking, data.statusName]);


  // Vérifie si la chaîne input est un lien URL ou un nom de fichier
  const isFileOrURL = (input) => {
    // Vérifie si la chaîne input est une URL
    const urlRegex = /^(https?:\/\/)([\w.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/i;

    // Vérifie si la chaîne est un fichier
    const fileRegex = /^(.*[\\/])?([\w,\s-]+\.[A-Za-z]{1,5})$/;

    if (urlRegex.test(input)) {
      return 'URL';
    } else if (fileRegex.test(input)) {
      return 'Fichier';
    }
  };

  const navigate = useNavigate();
  const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));

  const handleClose = () => {
    setOpenDetail(false)
    navigate(`/dashboard/municipalites/${currentCity.cityCode}/mu-mise-en-ligne/mu-mel-list`);
  };


  // Génération dynamique des détails (label, value) des lignes à afficher pour éviter la répétition.
  const renderDetailRow = (label, value) => (
    value && (
      // value !== null && value !== undefined && value !== "" && value.length !== 0 && (
      <div className="details-row">
        <span className="details-label">{label} :</span>
        <span className="details-value">{value}</span>
      </div>
    )
  );

  return (
    <div>
      <Dialog className='dialog-details-container' open={openDetail} onClose={handleClose}  >
        <DialogTitle>Détails de la mise en ligne.</DialogTitle>
        <DialogContent>
          {dataMEL.dateCreation && dataMEL.demandeur && (
            <DialogContentText>
              Cette demande a été déposée le{" "}
              <strong>
                <em>{dayjs(dataMEL.dateCreation).utc().format("DD MMMM YYYY à HH:mm")}</em>
              </strong>{" "}
              par <strong><em>{dataMEL.nomDemandeur}</em></strong>.
              <br />ID de la demande : <strong><em>{data.idTracking}</em></strong>
            </DialogContentText>
          )}
          <div className="dialog-details-grid">
            {/* État actuel */}
            {renderDetailRow("État actuel de cette mise en ligne", <><strong>{dataMEL.statusName}.</strong> <br /> {dataMEL.description}</>)}

            {/* Statut en vigueur */}
            {dataMEL.dateMaj && renderDetailRow("Statut en vigueur depuis", dayjs(dataMEL.dateMaj).utc().format("DD MMMM YYYY"))}

            {/* Type de mise à jour */}
            {renderDetailRow("Type de mise à jour", dataMEL.typeMaj)}

            {/* Type d'informations */}
            {renderDetailRow("Type d'informations", dataMEL.subDataDescription)}

            {/* Message de Meta Data */}
            {renderDetailRow("Message de meta données", dataMEL.messageMetaData)}

            {/* Producteur et date de production */}
            {renderDetailRow("Producteur", dataMEL.producteur)}

            {/* Date de production */}
            {dataMEL.dateProduction && renderDetailRow("Date de production", dayjs(dataMEL.dateProduction).utc().format("DD MMMM YYYY"))}

            {/* Numero de règlement et date d'entrée en vigueur */}
            {renderDetailRow("Numero de règlement", dataMEL.numeroReglement)}

            {/* Date de dateZonageEnVigueur */}
            {dataMEL.dateZonageEnVigueur && renderDetailRow("Date d'entrée en vigueur", dayjs(dataMEL.dateZonageEnVigueur).utc().format("DD MMMM YYYY"))}

            {/* Couche source */}
            {renderDetailRow("Couche source", dataMEL.zonageCoucheSource)}

            {/* Champ zone */}
            {renderDetailRow("Champ zone", dataMEL.zonageChampZone)}

            {/* Note du demandeur */}
            {renderDetailRow("Note du demandeur", dataMEL.noteDemandeur)}


            {/* Liste des fichiers associés */}
            {dataMEL.filesName && dataMEL.filesName.length > 0 && (
              <div className="details-row">
                <span className="details-label">Fichiers associés : </span>
                <ul className="details-value" >
                  {dataMEL.filesName.map((file, index) => (
                    <li key={index}>{file}</li>
                  ))}
                </ul>
              </div>
            )}

            {/* Lien Web */}
            {renderDetailRow("Lien Web", dataMEL.lienWeb)}

          </div>
          {/* </DialogContentText> */}


          {/* <div> */}
          {/* Affichage dynamique des autres champs non vides, sauf les fichiers */}
          {/* {Object.entries(dataMEL)
              .filter(
                ([key, value]) =>
                  value !== null && value !== "" &&
                  !["filesName", "subDataDescription", "producteur", "dateProduction", "messageMetaData", "noteDemandeur", "zonageCoucheSource", "zonageChampZone"].includes(key)
              )
              .map(([key, value]) => (
                <div key={key} className="details-row">
                  <div className="details-label">{key} :</div>
                  <div className="details-value">{value.toString()}</div>
                </div>
              ))} */}
          {/* </div> */}


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
