import React, { createContext, useContext, useState } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router';

const AuthContext = createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, SetCurrentUser] = useState(JSON.parse(localStorage.getItem('currentUserInfo')));
    const location = useLocation();

    // Créer une instance d'Axios pour les requêtes d'authentification
    const authAxios = axios.create({
        baseURL: process.env.REACT_APP_API_URL, // URL du serveur d'authentification
    });


    // Intercepteur pour ajouter le jeton JWT à chaque requête
    authAxios.interceptors.request.use((config) => {
        // Récupérer le jeton JWT depuis le localStorage
        const { access_token } = JSON.parse(localStorage.getItem('portailclient')) || {};
        if (access_token) {
            // Ajouter le jeton JWT à l'en-tête Authorization de la requête
            config.headers.Authorization = `Bearer ${access_token}`;
        }
        return config;
    });

    // Intercepteur pour gérer les erreurs d'authentification
    authAxios.interceptors.response.use(
        (response) => response,
        async (error) => {

            const originalRequest = error.config;
            // Si la requête retourne une erreur 401 (non autorisé) et que ce n'est pas une requête pour obtenir un nouveau jeton JWT
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    // Obtenir un nouveau jeton JWT avec le token de rafraîchissement
                    const refresh_token = JSON.parse(localStorage.getItem('portailclient')).refresh_token;
                    const response = await authAxios.post('/auth/refreshtoken', { refresh_token });

                    // Stocker le nouveau jeton JWT dans le localStorage
                    localStorage.setItem('portailclient', JSON.stringify(
                        {
                            'access_token': response.data.access_token,
                            ...response.data
                        }));

                    // Ajouter le nouveau jeton JWT à l'en-tête Authorization de la requête
                    originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;

                    // Retourner la requête originale avec le nouveau jeton JWT
                    return authAxios(originalRequest);
                }
                catch (error) {
                    console.error("Erreur de rafraîchissement du token :", error);
                    // Si l'obtention d'un nouveau jeton JWT échoue, rediriger vers la page de connexion
                    logout();
                    window.location.replace('/');
                    // window.location.href = '/';
                    //window.location.href = '/login';
                    return false;
                }
            }
            // Si la requête retourne une autre erreur que 401, la renvoyer telle quelle
            return Promise.reject(error);
        }
    );

    // Fonction pour mot de passe oublié
    async function forgotPW({ username, email }) {
        // Si l'identifiant username a été fourni.
        let response;
        if (username) {
            try {
                response = await authAxios.get('/user/check-username', {
                    params: {
                        username: username
                    }
                });
                return response.data; // Renvoie les données récupérées depuis la requête
            } catch (error) {
                console.error("Erreur lors de la requête GET '/user/check-username':", error);
                throw error; // Renvoie l'erreur
            }
        }
        // Le paramètre 'email' a été fourni.
        else if (email) {
            try {
                response = await authAxios.get('/user/check-email', {
                    params: {
                        email: email
                    }
                });
                return response.data; // Renvoie les données récupérées depuis la requête
            } catch (error) {
                console.error("Erreur lors de la requête GET '/user/check-email':", error);
                throw error; // Renvoie l'erreur
            }
        }
    };

    // Fonction pour l'envoie de courriel
    async function sendEmail({ username, fullName, email }) {
        // Si l'identifiant username et le email ont été fournis.
        if (username && email) {
            try {
                const response = await authAxios.post('/user/send-fpw-email', {
                    username: username,
                    fullName: fullName,
                    email: email,
                });
                return response.data; // Renvoie les données récupérées depuis la requête
            } catch (error) {
                console.error("Erreur lors de la requête GET '/user/send-fpw-email':", error);
                throw error; // Renvoie l'erreur
            }
        }
    }

    // Fonction pour valider le token en utilisant une requête POST
    async function validateToken(token, newPassword) {
        // console.log({ token, newPassword });
        try {
            const response = await authAxios.post('/user/reset-password', {
                token,
                newPassword
            });
            return response; // Renvoie la réponse récupérée depuis la requête
        } catch (error) {
            console.error("Erreur lors de la réinitialisation du mot de passe : requête POST '/user/reset-password':", error.response.data.error);
        }
    }

    // Fonction pour se logger au portail
    async function login(username, password) {
        try {
            const response = await authAxios.post('/auth/token', { username, password });

            // Vérification du rôle utilisateur
            if (!response.data.roles || response.data.roles.length === 0 || response.data.roles[0].name === 'ROLE_BANNI') {
                console.warn("Aucun rôle attribué à l'utilisateur.");
                return { success: false, message: "Votre compte ne dispose pas des autorisations nécessaires." };
            }

            try {
                localStorage.setItem('selectedMenuItem', JSON.stringify(
                    {
                        idMenu: 0,
                        menuItemSelected: location.pathname
                    }
                ));

                localStorage.setItem('portailclient', JSON.stringify(
                    {
                        // 'access_token': response.data.access_token,
                        // 'refresh_token': response.data.refresh_token,
                        ...response.data
                    }
                ));

                const res = await authAxios.get('/user', {
                    headers: {
                        'Authorization': `Bearer ${response.data.access_token} `
                    }
                });

                // Regrouper les groupes par utilisateur sans doublant distinctement
                const resultat = res.data.reduce((acc, current) => {
                    // Vérifier si l'objet est vide pour la première entrée
                    if (Object.keys(acc).length === 0) {
                        // Créer l'objet utilisateur avec idGroupTab contenant un tableau de l'idGroup actuel
                        return {
                            ...current,
                            idGroupTab: [current.idGroup],
                        };
                    } else {
                        // Ajouter l'idGroup au tableau existant dans acc.idGroupTab s'il n'est pas déjà présent
                        if (!acc.idGroupTab.includes(current.idGroup)) {
                            acc.idGroupTab.push(current.idGroup);
                        }
                        return acc;
                    }
                }, {}); // On initialise acc avec un objet vide

                localStorage.setItem('currentUserInfo', JSON.stringify(resultat));
                SetCurrentUser(JSON.parse(localStorage.getItem('currentUserInfo')));

                // Retourner un succès explicite
                return { success: true };
            } catch (error) {
                console.error("Erreur lors de la récupération des informations utilisateur : GET '/user' ", error);
                return { success: false, message: "Impossible de récupérer vos informations utilisateur." };
            }
        } catch (error) {
            console.error("Erreur lors de la requête POST '/auth/token':", error);
            return { success: false, message: "Identifiant et/ou mot de passe incorrect(s)." };
        }
    };

    // Fonction pour se déconnecter du portail
    function logout() {
        SetCurrentUser(null);
        localStorage.clear();
    }

    const value = {
        currentUser,
        login,
        logout,
        forgotPW,
        validateToken,
        sendEmail,
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}
