import React, { useState, useMemo, useEffect } from "react";
import { Box, Button, IconButton } from '@mui/material'
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, frFR } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import './GridTable.scss'
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DialogConfirm from './DialogConfirm';

function CustomToolbar({ setFilterButtonRef, isSelectionEmpty, onClickDelete }) {
    const currentCity = JSON.parse(localStorage.getItem("currentMunicipality"));

    return (
        // currentCity &&
        <div className='grid-customToolbar-container'>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton ref={setFilterButtonRef} />
                {/* <GridToolbarDensitySelector /> */}
                <GridToolbarExport
                    csvOptions={{
                        fileName: `Liste-utilisateurs-${currentCity.description}-${currentCity.cityName}`,
                        delimiter: ';',
                        utf8WithBom: true,
                        columnsToExport: ['fullName', 'genre', 'email', 'telephone', 'ext', 'title', 'department', 'Description'],
                    }}
                    printOptions={{
                        hideFooter: true,
                        hideToolbar: true,
                    }}
                />
            </GridToolbarContainer>

            <div className="btn-supprimer">
                {!isSelectionEmpty && (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={onClickDelete}
                    >
                        Supprimer
                    </Button>
                )}
            </div>
        </div>
    );
}

CustomToolbar.propTypes = {
    setFilterButtonRef: PropTypes.func.isRequired,
    isSelectionEmpty: PropTypes.bool.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};


export default function GridTable({ data, setMustReload, allowedManagement }) {

    const [isSelectionEmpty, setIsSelectionEmpty] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [filterButtonRef, setFilterButtonRef] = useState(null);
    const [open, setOpen] = useState(false);

    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows(data)
    }, [data]);

    const domainSortComparator = (emailA, emailB) => {
        if (typeof emailA !== 'string' || typeof emailB !== 'string') {
            return 0; // Retourne 0 si les valeurs ne sont pas des chaînes de caractères
        }
        const domainA = emailA.split("@")[1] ?? '';
        const domainB = emailB.split("@")[1] ?? '';
        return domainA.localeCompare(domainB);
    };

    const columns = useMemo(() => {
        const baseColumns = [
            { field: 'fullName', headerName: 'Nom Complet', width: 200, editable: false, },
            { field: 'email', headerName: 'Courriel', width: 300, editable: false, sortComparator: (a, b) => domainSortComparator(a, b) },
            { field: 'title', headerName: 'Titre', width: 200, editable: false, },
            { field: 'department', headerName: 'Département', width: 150, editable: false, hide: true },
            { field: 'telephone', headerName: 'Téléphone', width: 150, editable: false, },
            { field: 'ext', headerName: 'Poste', width: 80, editable: false, },
            { field: 'Description', headerName: 'Description', width: 200, editable: false, hide: true },
            { field: 'genre', headerName: 'Genre', width: 80, editable: false, hide: true },
        ];
        if (allowedManagement) {
            // .unshift Ajouter la colonne d'édition uniquement pour le rôle 'ROLE_ADMIN, ... ceux qui sont autorisés' au debut du tableau
            baseColumns.unshift({
                field: 'edit',
                headerName: 'Éditer',
                width: 80,
                editable: false,
                sortable: false,
                filterable: false,
                align: 'center',
                renderCell: (params) => {
                    return (
                        <Link
                            to={`/dashboard/municipalites/${params.row.cityCode}/${params.row.idGroup}/mu-utilisateurs/edit-user/${params.row.idUser}`}
                            style={{
                                textDecoration: "none",
                                cursor: "pointer",
                            }}
                        >
                            <IconButton className="edit-user">
                                <EditIcon className="edit-icon" />
                            </IconButton>
                        </Link>
                    )
                }
            });
        }
        return baseColumns;
    }, [allowedManagement]);


    const handleSelectionChange = (selection) => {
        // console.log(rows)
        // console.log(selection)
        setIsSelectionEmpty(selection.length === 0);

        // Utilisation de filter pour récupérer les objets correspondants à la sélection
        const newSelectionRows = rows.filter((row) => selection.includes(row.idUser));
        // console.log(newSelectionRows)

        // Mettre à jour l'état avec les lignes sélectionnées
        setSelectedRows(newSelectionRows);
    };

    const onClickDelete = async () => {
        console.log('Deleting selected rows !');
        setOpen(true)
    };

    return (
        <div className="GridTable">
            {open && (
                <DialogConfirm
                    open={open}
                    setOpen={setOpen}
                    rows={rows}
                    setRows={setRows}
                    selectedRows={selectedRows}
                    setIsSelectionEmpty={setIsSelectionEmpty}
                    setMustReload={setMustReload}
                />
            )}

            {/* currentCity && */}
            <Box className='dataGridBox' sx={{ width: '100%', }} >
                {/* <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div" >
                    Liste des utilisateurs
                </Typography> */}

                <DataGrid
                    slots={{ toolbar: CustomToolbar, }}
                    slotProps={{
                        panel: { anchorEl: filterButtonRef, },
                        toolbar: {
                            setFilterButtonRef,
                            isSelectionEmpty,
                            onClickDelete,
                        }
                    }}

                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 50, 100]}

                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}

                    // getRowSpacing={(params) => ({
                    //     top: params.isFirstVisible ? 0 : 5,
                    //     bottom: params.isLastVisible ? 0 : 5,
                    // })}

                    columns={columns}
                    rows={rows}
                    getRowId={(row) => row.idUser}

                    disableRowSelectionOnClick
                    // checkboxSelection
                    // onRowSelectionModelChange={handleSelectionChange}

                    checkboxSelection={allowedManagement}
                    onRowSelectionModelChange={allowedManagement ? handleSelectionChange : undefined}

                />
            </Box>
        </div>
    )
}
