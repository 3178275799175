import React, { useEffect, useState } from "react";
import './MessagesList.scss';
import GridTable from "../data-grid/GridTable";
import SearchBar from "../../../components/search-bar/SearchBar";
import { getAllMessagesData } from "../httpQuerys";

export default function MessagesList({ idUser, idGroup, allowedManagement }) {

  // const currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [mustReload, setMustReload] = useState();

  useEffect(() => {
    getAllMessagesData(idUser, idGroup).then((data) => {
      setData(data.filter(item => [1, 3].includes(item.idtypeMessage)))
      setMustReload(false)
    });
  }, [idGroup, idUser, mustReload]);

  // search bar fonction
  const tabKeys = ["idCityMessage", "title", "fullName", "typedescription", "dateStart", "dateEnd", "cityCode", "cityName", "mrcRealName", "idtypeMessage"];
  const showList = (data) => {
    const normalizedQuery = query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    return data.filter((row) =>
      tabKeys.some((key) =>
        row[key]?.toString()
          .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(normalizedQuery)
      )
    );
  };

  return (
    <div className='MessagesList'>
      <SearchBar query={query} setQuery={setQuery} />
      <GridTable data={showList(data)} setData={setData} setMustReload={setMustReload} allowedManagement={allowedManagement} />
    </div>
  );
}