import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


export default function EditorWysiwyg(props) {

  const { language, message, setMessage, setMessageError } = props

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  const handleChange = (content, delta, source, editor) => {
    setMessage(content);
    // Accéder à la propriété getText de l'instance de React Quill
    const textContent = editor.getText().trim();
    if (textContent !== '') {
      setMessage(content);
      setMessageError('')
    } else {
      setMessage('');
    }
  };

  return (
    <div className="container">

      <div className="editorLabel">
        Message en {language}
      </div>

      <div className="editor" >
        <ReactQuill
          // readOnly={disabled} importée des props
          // theme="snow"
          modules={modules}
          formats={formats}
          value={message}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

// <div className="preview" hidden
// dangerouslySetInnerHTML={{ __html: value }}
// />

//   return (
//     <div className="container">
//       <div className="row">
//         <div className="editor">
//           <ReactQuill theme="snow" value={value} onChange={handleChange} />
//         </div>
//         <div className="preview" dangerouslySetInnerHTML={{ __html: value }} />
//       </div>
//     </div>
//   );
// }
