import React, { useState, useMemo, useEffect } from "react";
import {
    Box,
    Button,
    IconButton,
} from '@mui/material';
import { Tooltip } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, frFR } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import './GridTable.scss';
import moment from 'moment';
import DialogShowMEL from './DialogShowMEL';
import DialogConfirm from './DialogConfirm';
import EditIcon from '@mui/icons-material/Edit';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Link } from "react-router-dom";


function CustomToolbar({ setFilterButtonRef, isSelectionEmpty, allowCancelling, onClickCancel, onClickDelete }) {

    const currentCity = JSON.parse(localStorage.getItem("currentMunicipality"));
    return (
        <div className='grid-customToolbar-container'>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton ref={setFilterButtonRef} />
                <GridToolbarExport
                    csvOptions={{
                        fileName: `Liste-Mise-en-Ligne-${currentCity.description}-${currentCity.cityName}`,
                        delimiter: ';',
                        utf8WithBom: true,
                        columnsToExport: ['idTracking', 'typeMaj', 'demandeur', 'description', 'statusName', 'dateMaj', 'dateMel'],
                    }}
                    printOptions={{
                        hideFooter: true,
                        hideToolbar: true,
                    }}
                />
            </GridToolbarContainer>

            <div className="btns-container">
                <div className="btn-cancel">
                    {!isSelectionEmpty && allowCancelling && (
                        <Button
                            variant="contained"
                            color="error"
                            onClick={onClickCancel}
                        // style={{ width: '150px' }}
                        >
                            Annuler
                        </Button>
                    )}
                </div>

                <div className="btn-supprimer">
                    {!isSelectionEmpty && (
                        <Button
                            variant="contained"
                            color="error"
                            onClick={onClickDelete}
                        >
                            Supprimer
                        </Button>
                    )}
                </div>
            </div>

        </div>
    );
}
CustomToolbar.propTypes = {
    setFilterButtonRef: PropTypes.func.isRequired,
    isSelectionEmpty: PropTypes.bool.isRequired,
    allowCancelling: PropTypes.bool.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};

export default function GridTable({ data, allowedManagement }) {
    const currentCity = JSON.parse(localStorage.getItem("currentMunicipality"));

    const [isSelectionEmpty, setIsSelectionEmpty] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [filterButtonRef, setFilterButtonRef] = useState(null);
    const [open, setOpen] = useState(false);
    const [openDetail, setOpenDetail] = useState(false);
    const [action, setAction] = useState('');
    const [rows, setRows] = useState([]);
    const [allowCancelling, setAllowCancelling] = useState(true);
    const [selectedRowData, setSelectedRowData] = useState(null);

    //! =================================================================

    useEffect(() => {
        setRows(data)

        if (isSelectionEmpty) {
            setAllowCancelling(false); // Désactive l'annulation si aucune ligne n'est sélectionnée
        } else {
            const selectedCancelledRows = selectedRows
                .map(id => data.find(row => row.idTracking === id)) // Trouve les lignes correspondantes à partir des IDs sélectionnés
                .filter(row => row?.idStatus === 9); // Filtre celles où idStatus === 9

            // Mettre à jour un état
            if (selectedCancelledRows.length === 0 && selectedRows.length > 0) {
                setAllowCancelling(true); // Active l'annulation si des lignes valides sont trouvées
            } else {
                setAllowCancelling(false); // Aucune ligne valide trouvée
            }
        }
    }, [data, isSelectionEmpty, rows, selectedRows]);

    //! =================================================================

    const columns = useMemo(() => {
        const handleOpenDetail = (rowData) => {
            // console.log('Consulter la ligne :', rowData);
            setSelectedRowData(rowData);
            setOpenDetail(true);
        };
        const baseColumns = [
            {
                field: 'show',
                headerName: 'Consulter',
                width: 80,
                editable: false,
                sortable: false,
                filterable: false,
                align: 'center',
                renderCell: (params) => {
                    return (
                        <>
                            <Tooltip title={`Consulter MEL ${params.row.idTracking}`}>
                                <IconButton onClick={() => handleOpenDetail(params.row)}>
                                    <ContentPasteSearchIcon className="edit-icon" />
                                </IconButton>
                            </Tooltip>
                        </>
                    );
                },
            },
            // { field: 'idTracking', headerName: 'ID MAJ', flex: 0.5, editable: false, },
            { field: 'demandeur', headerName: 'Demandeur', flex: 1, editable: false, },
            { field: 'typeMaj', headerName: 'Type MEL', flex: 0.8, editable: false, },
            { field: 'statusName', headerName: 'Statut de la demande', flex: 1.2, editable: false, },
            {
                field: 'dateMaj', headerName: 'Demande de Mise à jour', flex: 1.5, editable: false,
                renderCell: (params) => (
                    <>
                        {/* Utilisation de moment.utc() pour corriger le décalage */}
                        <span>{moment.utc(params.value).format('ddd DD MMM YYYY')}</span>
                        <span>&nbsp;à&nbsp;</span>
                        {/* <span>{moment.utc(params.value).format('HH:mm:ss')}</span> */}
                        <span>{moment.utc(params.value).format('HH:mm')}</span>
                    </>
                )
            },
            {
                field: 'dateMel', headerName: 'Date de Mise en ligne', flex: 1.5, editable: false,
                renderCell: (params) => {
                    const isValidDate = moment.utc(params.value).isValid(); // Vérification de la validité de la date
                    return (
                        <>
                            {isValidDate ? (
                                <>
                                    {/* Utilisation de moment.utc() pour corriger le décalage */}
                                    <span>{moment.utc(params.value).format('ddd DD MMM YYYY')}</span>
                                    <span>&nbsp;à&nbsp;</span>
                                    {/* <span>{moment.utc(params.value).format('HH:mm:ss')}</span> */}
                                    <span>{moment.utc(params.value).format('HH:mm')}</span>
                                </>
                            ) : (
                                <span>Requête en cours</span> // Message affiché en cas de date non valide
                            )}
                        </>
                    );
                }
            },
        ];
        // Edition d'une mise en ligne
        if (allowedManagement) {
            // .unshift Ajouter la colonne d'édition uniquement pour le rôle 'admin, ...' au debut du tableau
            baseColumns.unshift({
                field: 'edit',
                headerName: 'Éditer',
                width: 80,
                editable: false,
                sortable: false,
                filterable: false,
                align: 'center',
                renderCell: (params) => {
                    // Vérifiez si la condition est remplie
                    const isInfoVisible = [1, 2, 6, 7, 8].includes(params.row.idStatus);
                    return (
                        <Tooltip
                            title={
                                isInfoVisible
                                    ? "Modifier cet élément"
                                    // : "Vérifiez le statut de votre demande, aucune opération de suppression, d'annulation ou d'édition n'est possible !"
                                    : "Vérifiez le statut de votre demande, aucune opération d'édition n'est possible !"
                                //`${params.row.description}`
                            }
                            arrow
                        >
                            <span>
                                <Link
                                    to={isInfoVisible
                                        ? `/dashboard/municipalites/${currentCity.cityCode}/mu-mise-en-ligne/edit-mel/${params.row.idTracking}`
                                        : '#'}
                                    state={isInfoVisible ? params.row : null}
                                    style={{
                                        textDecoration: "none",
                                        cursor: isInfoVisible ? "pointer" : "not-allowed",
                                    }}
                                    onClick={(e) => {
                                        if (!isInfoVisible) e.preventDefault(); // Empêche la navigation si désactivé
                                    }}
                                >
                                    <IconButton disabled={!isInfoVisible}>
                                        {isInfoVisible ? (
                                            <EditIcon className="edit-icon" />
                                        ) : (
                                            <ErrorOutlineIcon className="edit-icon disabled" />
                                        )}
                                    </IconButton>
                                </Link>
                            </span>
                        </Tooltip>
                    );
                }

            });
        }
        return baseColumns;
    }, [allowedManagement, currentCity.cityCode]);
    // }, [allowedManagement]);


    const handleSelectionChange = (selection) => {
        setIsSelectionEmpty(selection.length === 0);
        setSelectedRows(selection);
    };

    const onClickCancel = () => {
        setOpen(true)
        setAction('Cancel')
    };

    const onClickDelete = () => {
        setOpen(true)
        setAction('Delete')
    };

    return (
        <div className="GridTable">
            {openDetail && (
                <DialogShowMEL
                    openDetail={openDetail}
                    setOpenDetail={setOpenDetail}
                    data={selectedRowData}
                />
            )}

            {open && (
                <DialogConfirm
                    open={open}
                    setOpen={setOpen}
                    action={action}
                    rows={rows}
                    selectedRows={selectedRows}
                />
            )}

            <Box
                className='dataGridBox'
                sx={{
                    width: '100%',
                }}
            >
                <DataGrid
                    slots={{ toolbar: CustomToolbar, }}

                    slotProps={{
                        panel: {
                            anchorEl: filterButtonRef,
                        },
                        toolbar: {
                            setFilterButtonRef,
                            isSelectionEmpty,
                            allowCancelling,
                            onClickCancel,
                            onClickDelete,
                        },
                    }}

                    initialState={{ pagination: { paginationModel: { pageSize: 10 } }, }}
                    pageSizeOptions={[10, 50, 100]}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}

                    columns={columns}
                    rows={rows}
                    getRowId={(row) => row.idTracking}
                    disableRowSelectionOnClick

                    checkboxSelection={allowedManagement}

                    // Les cases à cocher ne seront actives que pour les lignes où la condition est vrifiée
                    isRowSelectable={(rowParams) => rowParams.row.dateMel === null && [1, 2, 9].includes(rowParams.row.idStatus)}
                    // isRowSelectable={(rowParams) => rowParams.row.dateMel === null && [1, 2, 3, 4, 5, 6, 7, 8, 9].includes(rowParams.row.idStatus)}

                    onRowSelectionModelChange={allowedManagement ? handleSelectionChange : undefined}

                    // Ajout de classes conditionnelles
                    getRowClassName={(params) =>
                        params.row.dateMel === null && params.row.idStatus === 1
                            ? 'row-pointer'
                            : 'row-not-allowed'
                    }
                />
            </Box>
        </div>
    )
}